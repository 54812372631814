/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect, useRef } from 'react';
import { makeStyles, Typography, CircularProgress } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { LanguageContext } from 'services/localization/LanguageContext';
import getPageTitle from 'utility/pageTitle';
import AzureOperation from 'services/common';
import { LANG } from 'utility/languageUtility';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
  },
}));

const Survey = () => {
  const classes = useStyles();
  const { translate, lang } = useContext(LanguageContext);
  const theme = useTheme();
  const WAIT_TIME = 2;
  const timer = useRef();
  const [showLoading, setShowLoading] = useState(true);
  const [time, setTime] = useState(0);
  const surveyEnLink = 'https://mbgov.surveymonkey.com/r/VKXG9BF';
  const surveyFrLink = 'https://mbgov.surveymonkey.com/r/8BS2DNY';
  const { protectedResources, callApiWithAzureToken } = AzureOperation;
  const { baseUrl, method } = protectedResources.survey.endpoints.covid19;
  const params = {
    url: baseUrl,
    method,
  };

  const startTimer = () => {
    timer.current = setInterval(() => {
      setTime((_time) => _time + 1);
    }, 1000);
  };

  useEffect(() => {
    startTimer();
    callApiWithAzureToken(params);
    return () => {
      clearInterval(timer.current);
      timer.current = 0;
      setTime(0);
    };
  }, []);

  useEffect(() => {
    document.title = getPageTitle(translate('layout.nav.survey'), lang);
  }, [translate, lang]);

  useEffect(() => {
    if (time > WAIT_TIME) {
      setShowLoading(false);
    }
  }, [time]);

  if (showLoading) {
    return (
      <div className={classes.root}>
        <div style={{ marginBottom: theme.spacing(2) }}>
          <CircularProgress classes={{ root: classes.progress }} />
        </div>
        <Typography role="alert" aria-live="polite">
          {translate('pages.survey.loading')}
        </Typography>
      </div>
    );
  }

  window.location.href = lang === LANG.EN ? surveyEnLink : surveyFrLink;

  return null;
};

export default Survey;
