import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import { LoginContext } from '../../services/login/LoginContext';
import PageLoading from '../page-loading/PageLoading';

const getRouterJSX = (routerConfig) => {
  return (
    <Switch>
      {routerConfig.map((router) => {
        return (
          <Route
            key={router.path}
            path={router.path}
            exact={router.exact}
            component={router.component}
          />
        );
      })}
      <Route path="*" component={() => <Redirect to="/" />} />
    </Switch>
  );
};
const RouterManage = ({ routerConfig: _routerConfig }) => {
  const loginContext = useContext(LoginContext);
  const isAuth = loginContext.isAuthenticated();
  if (!isAuth) {
    return <PageLoading />;
  }

  // Need to exclude feature flag that is turned off.
  const routerConfig = _routerConfig.filter((router) => {
    return router;
  });

  return getRouterJSX(routerConfig);
};

export default RouterManage;

RouterManage.propTypes = {
  routerConfig: PropTypes.instanceOf(Object),
};
RouterManage.defaultProps = {
  routerConfig: {},
};
