import * as React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Typography,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
  },
  progress: {
    color: theme.palette.primary.main,
  },
  visuallyHidden: {
    position: 'absolute',
    overflow: 'hidden',
    clip: 'rect(0 0 0 0)',
    height: '1px',
    width: '1px',
    margin: '-1px',
    padding: 0,
    border: 0,
  },
}));

const CustomBackdrop = ({ onClick, open, text }) => {
  const theme = useTheme();
  const classes = useStyles();
  const handleClose = () => {
    if (onClick) onClick();
  };

  if (!open) return null;

  return (
    <>
      <Backdrop
        classes={{ root: classes.backdrop }}
        sx={{ color: '#fff' }}
        open={open}
        onClick={handleClose}
      >
        <div style={{ marginBottom: theme.spacing(2) }}>
          <CircularProgress classes={{ root: classes.progress }} />
        </div>
        {text && <Typography>{text}</Typography>}
      </Backdrop>
      <div className={classes.visuallyHidden}>
        <Typography role="alert" aria-live="polite">
          {text}
        </Typography>
      </div>
    </>
  );
};

CustomBackdrop.propTypes = {
  onClick: PropTypes.func,
  open: PropTypes.bool.isRequired,
  text: PropTypes.string,
};

CustomBackdrop.defaultProps = {
  text: undefined,
  onClick: () => {},
};

export default CustomBackdrop;
