/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, TextField, Typography } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import AzureOperation from 'services/common';
import { LanguageContext } from 'services/localization/LanguageContext';
import useConsentFormStore from 'services/store/form-stores/eConsentFormStore';
import { LANG } from 'utility/languageUtility';
// eslint-disable-next-line import/no-cycle
import consentFormFields from './consentFormFields';

const useStyles = makeStyles((theme) => ({
  textfield: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%',
  },
  noOptions: {
    display: 'none',
  },
  clearButton: {
    visibility: 'visible',
  },
  schoolListName: {
    fontSize: 15,
    marginBottom: 4,
  },
  schoolListAddress: {
    fontSize: 12,
  },
}));

const formatSchoolAddress = ({
  schoolAddressLine1,
  schoolAddressLine2,
  schoolCityName,
  schoolPostalCode,
}) =>
  [schoolAddressLine1, schoolAddressLine2, schoolCityName, schoolPostalCode]
    .filter((addressPart) => addressPart)
    .join(', ');

const SchoolsDropdown = ({
  input: { name, label, ariaLabel },
  onChange,
  onBlur,
  required,
  error,
  formState,
  focusEl,
}) => {
  const timer = useRef();
  const classes = useStyles();
  const [schools, setSchools] = useState([]);
  const [dataLoadFailed, setDataLoadFailed] = useState(null);
  const { translate, lang } = useContext(LanguageContext);
  const schoolNameField = lang === LANG.FR ? 'nameFr' : 'name';
  const cityNameField = lang === LANG.FR ? 'cityFr' : 'city';
  const updateSchoolsApiHasError = useConsentFormStore(
    (state) => state.setSchoolsHasError,
  );
  const updateLoadingSchoolsApi = useConsentFormStore(
    (state) => state.setLoadingSchoolsApi,
  );
  const ref = useRef(null);

  const WAIT_TIME = 2;
  const [time, setTime] = useState(0);

  const startTimer = () => {
    timer.current = setInterval(() => {
      setTime((_time) => _time + 1);
    }, 1000);
  };

  useEffect(() => {
    return () => {
      clearInterval(timer.current);
      timer.current = 0;
      setTime(0);
      updateLoadingSchoolsApi(undefined);
    };
  }, []);

  useEffect(() => {
    if (dataLoadFailed !== null) {
      if (time >= WAIT_TIME) {
        // Loading progress must show atleast 2 seconds before it closes.
        clearInterval(timer.current);
        setTime(0);
        setDataLoadFailed(null);
        updateLoadingSchoolsApi(false);
      }
    }
  }, [time, dataLoadFailed]);

  useEffect(() => {
    if (name === focusEl && ref && ref.current) {
      ref.current.focus();
    }
  }, [name, focusEl]);

  useEffect(() => {
    const { protectedResources, callApiWithAzureToken } = AzureOperation;
    startTimer();
    updateLoadingSchoolsApi(true);
    const params = {
      url: protectedResources.schools.endpoints.getSchools.baseUrl,
      method: protectedResources.schools.endpoints.getSchools.method,
    };
    callApiWithAzureToken(params)
      .then((result) => {
        if (result) {
          if (result && result.statusCode !== 0 && result.statusCode !== 200) {
            updateSchoolsApiHasError(true);
            setDataLoadFailed(true);
          }

          if (
            result &&
            (result.statusCode === 0 || result.statusCode === 200)
          ) {
            if (
              result.schools === undefined ||
              result.schools === null ||
              result.schools.length === 0
            ) {
              updateSchoolsApiHasError(true);
              setDataLoadFailed(true);
            } else {
              updateSchoolsApiHasError(false);
              setSchools(result.schools);
              setDataLoadFailed(false);
            }
          }
        }
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error('Error Fetching Schools', e);
        setDataLoadFailed(true);
      });
  }, [updateSchoolsApiHasError, updateLoadingSchoolsApi]);

  const filterOptions = createFilterOptions({
    stringify: (option) =>
      `${option[schoolNameField]} ${formatSchoolAddress(option)}`,
    limit: 10,
  });

  const handleOnChange = (e, val, reason) => {
    const cityInputName = consentFormFields.sectionA.schoolCity.name;
    if (reason === 'select-option') {
      onChange(name, val.name);
      onChange(cityInputName, val.city);
    } else if (reason === 'clear') {
      onChange(name, '');
      onChange(cityInputName, '');
    }
  };

  const handleOnBlur = (e) => {
    const data = {};
    const school = schools.find((a) => a[schoolNameField] === e.target.value);
    data.schoolId = school ? school.schoolId : null;
    onBlur(name, e.target.value, data);
  };

  const getAriaLabel = () => {
    let inputAriaLabel = '';
    if (required)
      inputAriaLabel = `${translate('pages.consentForm.requiredFieldAria')}`;

    inputAriaLabel += (ariaLabel && translate(ariaLabel)) || translate(label);
    return inputAriaLabel;
  };

  return (
    <Autocomplete
      id={name}
      classes={{
        noOptions: classes.noOptions,
        clearIndicator: classes.clearButton,
      }}
      forcePopupIcon={false}
      filterOptions={(options, state) => {
        if (!state.inputValue) return [];
        return filterOptions(options, state);
      }}
      getOptionLabel={(option) => option[schoolNameField] || ''}
      noOptionsText={null}
      onChange={handleOnChange}
      options={schools}
      renderOption={(option) => (
        <div>
          <Typography className={classes.schoolListName}>
            {option[schoolNameField]}
          </Typography>
          <Typography className={classes.schoolListAddress}>
            {option[cityNameField]}
          </Typography>
        </div>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={ref}
          className={classes.textfield}
          label={`${required ? '* ' : ''}${translate(label)}`}
          variant="outlined"
          id={`${name}-textfield`}
          inputProps={{ ...params.inputProps, 'aria-label': getAriaLabel() }}
          error={Boolean(error)}
          helperText={translate(error)}
          onBlur={handleOnBlur}
        />
      )}
    />
  );
};

SchoolsDropdown.defaultProps = {
  required: false,
  error: null,
  onBlur: () => {},
  formState: {},
  focusEl: undefined,
};

SchoolsDropdown.propTypes = {
  input: PropTypes.shape({
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    ariaLabel: PropTypes.string,
  }).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.string,
      PropTypes.shape({ label: PropTypes.string }),
    ),
  ]),
  formState: PropTypes.object,
  focusEl: PropTypes.string,
};

export default SchoolsDropdown;
