import React, { useContext, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  FormControlLabel,
  Checkbox,
  makeStyles,
  Typography,
} from '@material-ui/core';
import links from 'constants/links';
import { useTheme } from '@material-ui/core/styles';
import { LanguageContext } from 'services/localization/LanguageContext';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  errorColor: {
    color: theme.palette.error.main,
  },
  selectedColor: {
    color: `${theme.palette.primary.dark}!important`,
  },
  formControlLabel: {
    alignItems: 'flex-start',
    marginBottom: theme.spacing(2),
    overflowWrap: 'anywhere',
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
  },
  checkbox: {
    marginTop: '-6px',
  },
  focusBorder: {
    outlineStyle: 'auto',
  },
  errorContainer: {
    margin: '-16px',
    padding: '16px',
    paddingBottom: '0',
    marginBottom: '32px',
    border: `1px dashed ${theme.palette.error.main}`,
    display: 'flex',
    flexDirection: 'column',
  },
  visuallyHidden: {
    position: 'absolute',
    overflow: 'hidden',
    clip: 'rect(0 0 0 0)',
    height: '1px',
    width: '1px',
    margin: '-1px',
    padding: 0,
    border: 0,
  },
}));

const CheckboxControl = ({
  label,
  name,
  value,
  error,
  onChange,
  errorAria,
  focusEl,
}) => {
  const [mouseDown, setMouseDown] = useState(false);
  const [focus, setFocus] = useState(false);
  const classes = useStyles();
  const { translate } = useContext(LanguageContext);
  const ref = useRef(null);

  useEffect(() => {
    if (name === focusEl && ref && ref.current) {
      ref.current.focus();
    }
  }, [name, focusEl]);

  const handleOnChange = (e) => {
    onChange(name, e.target.checked);
  };

  return (
    <FormControlLabel
      label={translate(label, links)}
      aria-label={`${translate(label, links)}${
        Boolean(error) === true ? `(${translate(errorAria || error)})` : ''
      }`}
      id={name}
      classes={{
        root: classNames(
          classes.formControlLabel,
          error ? classes.errorColor : null,
          focus ? classes.focusBorder : null,
          value === true || value === 'true' ? classes.selectedColor : null,
        ),
      }}
      onFocus={(event) => {
        if (mouseDown) event.target.blur();
        else setFocus(true);
      }}
      onBlur={() => setFocus(false)}
      // Outline focus is for keyboard-only users
      onMouseUp={() => setMouseDown(false)}
      onMouseDown={() => setMouseDown(true)}
      onClick={() => setFocus(false)}
      control={
        <Checkbox
          inputRef={ref}
          classes={{
            root: classNames(
              classes.checkbox,
              error ? classes.errorColor : null,
              value === true || value === 'true' ? classes.selectedColor : null,
            ),
          }}
          checked={value}
          onChange={handleOnChange}
          name={name}
        />
      }
    />
  );
};

const CustomCheckbox = ({
  label,
  name,
  value,
  error,
  errorAria,
  onChange,
  noBorderOnError,
  focusEl,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [focus, setFocus] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const { translate } = useContext(LanguageContext);
  if (error && !noBorderOnError) {
    return (
      <div className={classes.errorContainer}>
        {error && (
          <Typography
            id={`${name}-helper-text`}
            variant="body1"
            classes={{
              root: classNames(error ? classes.errorColor : null),
            }}
            style={{ textAlign: 'center', marginBottom: theme.spacing(2) }}
          >
            <span aria-hidden="true">{translate(error)}</span>
            <span className={classes.visuallyHidden}>
              {translate(errorAria || error)}
            </span>
          </Typography>
        )}

        <CheckboxControl
          label={label}
          name={name}
          value={value}
          error={error}
          onChange={onChange}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          focusEl={focusEl}
        />
      </div>
    );
  }

  return (
    <CheckboxControl
      label={label}
      name={name}
      value={value}
      error={error}
      onChange={onChange}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      noBorderOnError={noBorderOnError}
      focusEl={focusEl}
    />
  );
};

const propsTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.string,
      PropTypes.shape({ label: PropTypes.string }),
    ),
  ]),
  errorAria: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.string,
      PropTypes.shape({ label: PropTypes.string }),
    ),
  ]),
  noBorderOnError: PropTypes.bool,
};

CheckboxControl.defaultProps = {
  error: '',
  errorAria: '',
  focusEl: '',
  onBlur: () => {},
  onFocus: () => {},
};

CheckboxControl.propTypes = {
  ...propsTypes,
};

CustomCheckbox.defaultProps = {
  error: '',
  errorAria: '',
  focusEl: '',
  noBorderOnError: false,
};

CustomCheckbox.propTypes = {
  ...propsTypes,
};

export default CustomCheckbox;
