import create from 'zustand';

const useStore = create((set) => ({
  parentMenu: {},
  toggleMenu: (menuToUpdate) =>
    set((state) => ({
      parentMenu: {
        ...state.parentMenu,
        [menuToUpdate]: !state.parentMenu[menuToUpdate],
      },
    })),
  activeMenu: null,
  setActiveMenu: (newActiveMenu) =>
    set(() => ({
      activeMenu: newActiveMenu,
    })),
}));

export default useStore;
