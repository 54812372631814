/* eslint-disable max-len */
/* eslint-disable array-callback-return */
/* eslint-disable radix */
/* eslint-disable no-unused-vars */
/* eslint-disable default-case */
import React from 'react';
import { orderBy, sumBy, filter } from 'lodash';

export const formatErrorMessages = (errorMessages, pkData) => {
  return (
    <div>
      {errorMessages.map((errMsg) => {
        return <p key={`${pkData}-errorMsg`}>{errMsg.message}</p>;
      })}
    </div>
  );
};

export const getFieldsToSort = (sorts) => {
  const fieldsToSort = [];
  if (sorts)
    sorts.map((sort) => {
      fieldsToSort.push(sort.fieldName);
    });

  return fieldsToSort;
};

export const getFieldsToSortOrder = (sorts) => {
  const fieldsToSortOrder = [];
  if (sorts)
    sorts.map((sort) => {
      fieldsToSortOrder.push(sort.order === 0 ? 'asc' : 'desc');
    });

  return fieldsToSortOrder;
};

export const checkboxIndeterminate = (totalSelectedRows, totalRows) => {
  if (totalSelectedRows > 0 && totalRows > 0 && totalSelectedRows < totalRows)
    return { indeterminate: true };

  return null;
};

export const applyDataGridProps = (
  data,
  gridData,
  rowIsEditing,
  urlParams,
  config,
  columns,
) => {
  const urlParamsObj = JSON.parse(urlParams);
  const { filters } = urlParamsObj;
  const fieldsToSort = getFieldsToSort(urlParamsObj.sorts);
  const fieldsToSortBy = getFieldsToSortOrder(urlParamsObj.sorts);
  let updatedData;

  if (!rowIsEditing) {
    if (fieldsToSort.length > 0 || fieldsToSortBy.length > 0)
      updatedData = orderBy(
        updatedData,
        fieldsToSort.map((column) => {
          return (row) => {
            const value = row[column];

            return value != null ? value.toString().toLowerCase() : '';
          };
        }),
        fieldsToSortBy,
      );
  }

  return updatedData;
};

export const getColumnsConfiguredWidths = (columns) => {
  const columnWidths = [];
  const cols = columns && columns.length > 0 ? [...columns] : [];
  cols.map((col, i) => {
    if (col.hide === undefined || col.hide === false)
      columnWidths.push({
        index: i,
        name: col.name,
        width: col.minWidth || 100,
        isResized: false,
      });
  });

  return columnWidths;
};

export const getNewColumnWidthsAfterResized = (
  _columnHeaderWidth,
  deltaX,
  colIndex,
) => {
  const columnHeaderWidths =
    _columnHeaderWidth && _columnHeaderWidth.length > 0
      ? [..._columnHeaderWidth]
      : [];
  const newColumnHeaderWidths = columnHeaderWidths.map((colWidth) => {
    const newColWidth = colWidth;
    if (colWidth.index === colIndex) {
      newColWidth.deductWidth = deltaX * -1;
      newColWidth.isResized = true;
    }

    return newColWidth;
  });

  return newColumnHeaderWidths;
};

// export const getNewColumnWidthAfterAdjusment = (columnHeaderWidths, tableWidth, scrollBarSize = 0) => {
//   const _columnHeaderWidths = columnHeaderWidths && columnHeaderWidths.length > 0 ? [...columnHeaderWidths] : [];
//   const lastColIndex = _columnHeaderWidths.length - 1;
//   let newColumnWidth = null;

//   if (scrollBarSize > 0) {
//     _columnHeaderWidths.map((colWidth) => {
//       if (colWidth.index === lastColIndex)
//         newColumnWidth = colWidth.width - scrollBarSize;
//     })
//   }

//   return newColumnWidth;
// }

export const getNewColumnWidthAfterAdjusment = (
  _columnHeaderWidths,
  tableWidth,
  scrollBarSize,
) => {
  const columnHeaderWidths =
    _columnHeaderWidths && _columnHeaderWidths.length > 0
      ? [..._columnHeaderWidths]
      : [];
  const lastColIndex = columnHeaderWidths.length - 1;
  const sumOfColumnWidths = sumBy(columnHeaderWidths, (o) => {
    return o.width;
  });
  let newColumnWidth = null;
  if (tableWidth && tableWidth > sumOfColumnWidths) {
    _columnHeaderWidths.map((colWidth) => {
      if (colWidth.index === lastColIndex) {
        const otherColumns = filter(_columnHeaderWidths, (o) => {
          return o.index !== colWidth.index;
        });
        const sumOfOtherColumnWidths = sumBy(otherColumns, (o) => {
          return o.width;
        });

        newColumnWidth = tableWidth - sumOfOtherColumnWidths - scrollBarSize;
      }
    });
  } else if (scrollBarSize > 0) {
    _columnHeaderWidths.map((colWidth) => {
      if (colWidth.index === lastColIndex)
        newColumnWidth = colWidth.width - scrollBarSize;
    });
  }

  return newColumnWidth;
};

export const getNewColumnWidthAfterAdjusmentTableWidthRestriction = (
  _columnHeaderWidths,
  tableWidth,
  scrollBarSize,
) => {
  // For grid that you want auto-adjust the last column with respect to grid's avalaible width
  // The grid will not show horizontal scroll if you use this.

  const columnHeaderWidths =
    _columnHeaderWidths && _columnHeaderWidths.length > 0
      ? [..._columnHeaderWidths]
      : [];
  const lastColIndex = columnHeaderWidths.length - 1;
  const sumOfColumnWidths = sumBy(columnHeaderWidths, (o) => {
    return o.width;
  });
  let newColumnWidth = null;

  if (tableWidth && scrollBarSize) {
    columnHeaderWidths.map((colWidth) => {
      if (colWidth.index === lastColIndex) {
        const otherColumns = filter(columnHeaderWidths, (o) => {
          return o.index !== colWidth.index;
        });
        const sumOfOtherColumnWidths = sumBy(otherColumns, (o) => {
          return o.width;
        });
        newColumnWidth = tableWidth - sumOfOtherColumnWidths - scrollBarSize;
      }
    });
  }

  return newColumnWidth;
};

export const getNewColumnWidthsAfterLastColumnSizeAdjusment = (
  _columnHeaderWidths,
  tableWidth,
  scrollBarSize,
) => {
  const columnHeaderWidths =
    _columnHeaderWidths && _columnHeaderWidths.length > 0
      ? [..._columnHeaderWidths]
      : [];
  const lastColIndex = columnHeaderWidths.length - 1;
  if (tableWidth && scrollBarSize) {
    const newColumnHeaderWidths = columnHeaderWidths.map((colWidth) => {
      const newColWidth = colWidth;
      if (colWidth.index === lastColIndex) {
        const otherColumns = filter(columnHeaderWidths, (o) => {
          return o.index !== colWidth.index;
        });
        const sumOfOtherColumnWidths = sumBy(otherColumns, (o) => {
          return o.width;
        });
        const newColumnWidth =
          tableWidth - sumOfOtherColumnWidths - scrollBarSize;

        newColWidth.width = newColumnWidth;
        newColWidth.deductWidth = 0;
        newColWidth.isResized = false;
      }

      return newColWidth;
    });

    return newColumnHeaderWidths;
  }

  return _columnHeaderWidths;
};

export const getColumnWidth = (
  colIndex,
  _columns,
  columnHeaderWidths,
  formView,
  tableWidth,
  isLastIndex,
  scrollBarSize,
) => {
  const columns = _columns && _columns.length > 0 ? [..._columns] : [];
  let defaultWidth =
    columns[colIndex] && columns[colIndex].minWidth !== null
      ? columns[colIndex].minWidth
      : 0;
  let measuredWidth =
    columnHeaderWidths[colIndex] && columnHeaderWidths[colIndex].width !== null
      ? columnHeaderWidths[colIndex].width
      : 0;
  const isResized =
    columnHeaderWidths[colIndex] &&
    columnHeaderWidths[colIndex].isResized !== null
      ? columnHeaderWidths[colIndex].isResized
      : 0;
  const deductWidth =
    columnHeaderWidths[colIndex] &&
    columnHeaderWidths[colIndex].deductWidth !== null &&
    columnHeaderWidths[colIndex].deductWidth !== undefined
      ? columnHeaderWidths[colIndex].deductWidth
      : 0;

  if (
    columns[colIndex].hideOnReadOnly !== undefined &&
    columns[colIndex].hideOnReadOnly &&
    columns[colIndex].hideOnReadOnly === true
  ) {
    measuredWidth = 0;
    defaultWidth = 0;
  }

  let newColumnWidth = [];

  if (!isResized) {
    if (defaultWidth > measuredWidth)
      newColumnWidth = defaultWidth - deductWidth;
    else newColumnWidth = measuredWidth - deductWidth;
  } else {
    newColumnWidth = measuredWidth - deductWidth;
  }

  // if (isLastIndex) {
  //   const otherColumns = filter(columns, function(o) { return o.index !== colIndex; });
  //   const sumOfOtherColumnWidths = sumBy(otherColumns, function(o) { return o.minWidth; });

  //   newColumnWidth = (tableWidth - sumOfOtherColumnWidths) - scrollBarSize;
  // }

  return newColumnWidth < 0 ? 0 : newColumnWidth;
};

export const updateColumnWidths = (index, newWidth, _columnHeaderWidths) => {
  const columnHeaderWidths =
    _columnHeaderWidths && _columnHeaderWidths.length > 0
      ? [..._columnHeaderWidths]
      : [];
  const newColumnHeaderWidths = columnHeaderWidths.map((colWidth) => {
    const newColWidth = colWidth;
    if (colWidth.index === index) {
      newColWidth.width = newWidth;
      newColWidth.deductWidth = 0;
    }

    return newColWidth;
  });

  return newColumnHeaderWidths;
};
