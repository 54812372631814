/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  makeStyles,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
} from '@material-ui/core';
import { LanguageContext } from 'services/localization/LanguageContext';

const useStyles = makeStyles((theme) => ({
  select: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%',
  },
  visuallyHidden: {
    position: 'absolute',
    overflow: 'hidden',
    clip: 'rect(0 0 0 0)',
    height: '1px',
    width: '1px',
    margin: '-1px',
    padding: 0,
    border: 0,
  },
}));

const SelectCustom = ({
  label,
  name,
  value,
  options,
  onChange,
  onBlur,
  error,
  errorAria,
  required,
  ariaLabel,
  focusEl,
}) => {
  const classes = useStyles();
  const { translate } = useContext(LanguageContext);
  const ref = useRef(null);
  const hasError = Boolean(error) && value === '';

  useEffect(() => {
    if (name === focusEl && ref && ref.current) {
      ref.current.focus();
    }
  }, [name, focusEl]);

  const selectOptions = options.map((option, i) => (
    <option
      key={option.value}
      value={option.value}
      aria-label={translate(option.ariaLabel) || translate(option.label)}
    >
      {translate(option.label)}
    </option>
  ));

  const handleOnChange = (e) => {
    if (onChange) onChange(name, e.target.value);
  };

  const handleOnBlur = (e) => {
    if (onBlur) onBlur(name, e.target.value);
  };

  return (
    <FormControl
      className={classes.select}
      variant="outlined"
      id={`${name}-form-control`}
      error={hasError}
      tabIndex="-1"
    >
      <InputLabel id={name} error={hasError}>
        <span aria-hidden="true">{`${required ? '* ' : ''}${translate(label)}
        `}</span>
        <span className={classes.visuallyHidden}>
          {`${
            required ? translate('pages.consentForm.requiredFieldAria') : ''
          }${translate(ariaLabel || label)}${
            hasError ? `. ${translate(errorAria || error)}` : ''
          }
          }`}
        </span>
      </InputLabel>
      <Select
        labelId={name}
        id={name}
        label={`${required ? '* ' : ''}${translate(label)}`}
        value={value}
        inputProps={{
          'aria-label': `${translate(ariaLabel || label)}${
            hasError ? `. ${translate(errorAria || error)}` : ''
          }`,
        }}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        inputRef={ref}
        native
      >
        {selectOptions}
      </Select>
      {hasError ? (
        <FormHelperText id={`${name}-helper-text`}>
          {translate(error)}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
};

SelectCustom.defaultProps = {
  error: null,
  errorAria: null,
  required: false,
  onBlur: () => {},
  ariaLabel: undefined,
  focusEl: undefined,
};

SelectCustom.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.string,
      PropTypes.shape({ label: PropTypes.string }),
    ),
  ]),
  errorAria: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.string,
      PropTypes.shape({ label: PropTypes.string }),
    ),
  ]),
  ariaLabel: PropTypes.string,
  focusEl: PropTypes.string,
};

export default SelectCustom;
