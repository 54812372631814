/* eslint-disable import/prefer-default-export */
/* eslint-disable array-callback-return */
const mapData = (data) => {
  const mappedData = [];
  if (!data) return [];

  data.map((item, i) => {
    mappedData.push({
      id: i,
      econsentId: item.econsentId,
      submissionDate: item.submissionDate,
      submittedBy: item.submittedBy,
      consentFor: `${item.client.firstName} ${item.client.lastName}`,
      consentDecision: item.consentDecision,
      schoolNameEn: item.client.schoolName_EN,
      schoolNameFr: item.client.schoolName_FR,
      schoolGrade: item.client.schoolGrade,
      viewPdf: true,
    });
  });
  return mappedData;
};

export { mapData };
