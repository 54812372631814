import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { LoginContext } from 'services/login/LoginContext';
import useStore from 'services/store';
import RouterRole from 'constants/routerRole';
import NavigationBarItem from './NavigationBarItem';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const NavigationBarItemGroup = ({
  Icon,
  name,
  subMenu,
  text,
  onClick,
  role,
}) => {
  /* Since the parent layout component is unmounted and remounted on every route
    change, internal state is lost. Using a global store to help manage this.
    If other sub menus are added the state management will need to be updated
    to allow for this. */
  const classes = useStyles();
  const loginContext = useContext(LoginContext);
  const parentMenu = useStore((state) => state.parentMenu);
  const toggleMenu = useStore((state) => state.toggleMenu);
  const isParentMenuOpen = parentMenu[name];
  const subMenuItems = subMenu.map((item) => (
    <NavigationBarItem
      subMenu
      name={item.name}
      key={item.showName}
      Icon={item.icon}
      text={item.showName}
      linkTo={item.url}
      onClick={onClick}
      newPage={Boolean(item.newPage)}
      groupSelected={isParentMenuOpen}
      role={item.role}
    />
  ));

  if (
    (role === RouterRole.PUBLIC && loginContext) ||
    (role === RouterRole.PROTECTED && !loginContext)
  ) {
    return null;
  }

  return (
    <>
      <NavigationBarItem
        Icon={Icon}
        name={name}
        text={text}
        onClick={toggleMenu}
        endIcon={isParentMenuOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        groupSelected={isParentMenuOpen}
        role={role}
      />
      <Collapse
        in={isParentMenuOpen}
        classes={{ wrapper: classes.wrapper, wrapperInner: classes.wrapper }}
      >
        {subMenuItems}
      </Collapse>
    </>
  );
};

export default NavigationBarItemGroup;

NavigationBarItemGroup.propTypes = {
  text: PropTypes.string.isRequired,
  Icon: PropTypes.element,
  name: PropTypes.string.isRequired,
  subMenu: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      showInNavBar: PropTypes.bool,
      showName: PropTypes.string,
      icon: PropTypes.element,
      newPage: PropTypes.bool,
    }),
  ).isRequired,
  onClick: PropTypes.func,
  role: PropTypes.string.isRequired,
};

NavigationBarItemGroup.defaultProps = {
  onClick: null,
  Icon: null,
};
