/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles, styled } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: (props) => {
      return props.transparentBg
        ? 'transparent'
        : theme.palette.background.default;
    },
    display: 'flex',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const StyledSpan = styled('span')({
  padding: '10px',
  alignItems: 'center',
  textAlign: 'center',
  marginRight: '20px',
});

const PageLoading = ({ transparentBg = false }) => {
  const classes = useStyles({ transparentBg });
  return (
    <div className={classes.root}>
      <StyledSpan>
        <CircularProgress />
      </StyledSpan>
    </div>
  );
};

export default PageLoading;
