/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-cycle
import RadioGroupCustom from './inputs/RadioGroupCustom';
import SelectCustom from './inputs/SelectCustom';
import CheckboxCustom from './inputs/CheckboxCustom';
import TextFieldCustom from './inputs/TextFieldCustom';
import DatePickerCustom from './inputs/DatePickerCustom';

const FormInput = ({ formState = {}, focusEl, input, onChange, onBlur }) => {
  switch (input.type) {
    case 'radio':
      return (
        <RadioGroupCustom
          label={input.label}
          ariaLabel={input.ariaLabel}
          name={input.name}
          required={input.validation && input.validation.isRequired}
          value={formState[input.name] ? formState[input.name].value || '' : ''}
          error={formState[input.name] ? formState[input.name].error : null}
          disabled={
            formState[input.name] &&
            formState[input.name].disabled !== undefined
              ? formState[input.name].disabled
              : input.disabled
          }
          options={input.options}
          onChange={onChange}
          onBlur={onBlur}
          formState={formState}
          fullWidth
          indentOptions={input.indentOptions}
          focusEl={focusEl}
        />
      );
    case 'select':
      return (
        <SelectCustom
          label={input.label}
          name={input.name}
          value={formState[input.name] ? formState[input.name].value || '' : ''}
          error={formState[input.name] ? formState[input.name].error || '' : ''}
          errorAria={
            formState[input.name] ? formState[input.name].errorAria || '' : ''
          }
          options={input.options}
          onChange={onChange}
          onBlur={onBlur}
          ariaLabel={input.ariaLabel}
          focusEl={focusEl}
          required={input.validation && input.validation.isRequired}
        />
      );
    case 'checkbox':
      return (
        <CheckboxCustom
          label={input.label}
          name={input.name}
          value={Boolean(formState[input.name] && formState[input.name].value)}
          onChange={onChange}
          error={formState[input.name] ? formState[input.name].error || '' : ''}
          errorAria={
            formState[input.name] ? formState[input.name].errorAria : undefined
          }
          focusEl={focusEl}
        />
      );
    case 'text':
    case 'number':
      return (
        <TextFieldCustom
          label={input.label}
          name={input.name}
          type={input.type}
          mask={input.mask}
          validate={input.validate}
          value={formState[input.name] ? formState[input.name].value || '' : ''}
          error={formState[input.name] ? formState[input.name].error || '' : ''}
          // disabled={input.disabled}
          disabled={
            formState[input.name] &&
            formState[input.name].disabled !== undefined
              ? formState[input.name].disabled
              : input.disabled
          }
          onChange={onChange}
          onBlur={onBlur}
          required={input.validation && input.validation.isRequired}
          maxLength={input.maxLength}
          multiline={input.multiline}
          rows={input.rows}
          indent={input.indent}
          ariaLabel={input.ariaLabel}
          tooltip={input.tooltip}
          focusEl={focusEl}
        />
      );
    case 'date':
      return (
        <DatePickerCustom
          label={input.label}
          name={input.name}
          disabled={input.disabled}
          value={formState[input.name] ? formState[input.name].value || '' : ''}
          error={formState[input.name] ? formState[input.name].error || '' : ''}
          onChange={onChange}
          onBlur={onBlur}
          required={input.validation && input.validation.isRequired}
          ariaLabel={input.ariaLabel}
          focusEl={focusEl}
        />
      );
    case 'custom': {
      const Component = input.component;
      return (
        <Component
          input={input}
          formState={formState}
          value={formState[input.name] ? formState[input.name].value || '' : ''}
          error={formState[input.name] ? formState[input.name].error || '' : ''}
          onChange={onChange}
          onBlur={onBlur}
          required={input.validation && input.validation.isRequired}
          focusEl={focusEl}
        />
      );
    }
    default:
      return null;
  }
};

FormInput.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  input: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  formState: PropTypes.object,
  focusEl: PropTypes.string,
};

export default FormInput;
