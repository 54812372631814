/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, {
  useState,
  useLayoutEffect,
  useEffect,
  useRef,
  forwardRef,
  useCallback,
} from 'react';
import { Divider } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { isEqual } from 'lodash';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, AutoSizer, ScrollSync } from 'react-virtualized';
import { mergeRefs } from 'use-callback-ref';
import {
  getColumnsConfiguredWidths,
  getNewColumnWidthAfterAdjusment,
  getColumnWidth as _getColumnWidth,
  updateColumnWidths,
} from './helpers';
import './grid.css';

const pageStyles = makeStyles(() => ({
  tableContainer: {
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    padding: '0px',
    width: '100%',
    minHeight: '400px',
    height: '100%',
  },
  linearProgress: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
  },
  hideScroll: {
    overflowX: 'hidden!important',
    overflowY: 'hidden!important',
  },
}));

const CustomGrid = forwardRef((props, ref) => {
  const ROW_HEIGHT = 40;
  const {
    columns,
    columnHeaderWidths = [],
    setColumnHeaderWidths = () => {},
    cellRenderer = () => {},
    headerRenderer = () => {},
    data,
    showLoader = false,
    showLoadProgress = true,
    formView,
    scrollToRow,
    scrollToColumn,
    scrollTop: lastScrollTop,
    scrollLeft: lastScrollLeft,
    onScroll,
    customBody,
    deferredMeasurementCache,
    estimatedRowSize,
    overscanColumnCount,
    overscanRowCount,
    fixedColumnCount = 0,
    noHeader = false,
    bodyHeight = 0,
    onSectionRendered,
    title,
  } = props;

  const mainTheme = useTheme();
  const { divider } = mainTheme.palette;
  // const [hoveredRow, setHoveredRow] = useState(null);
  const [gridWidth, setGridWidth] = useState(0);
  const noData = !!(data && data.length === 0);
  const [scrollBarSize, setScrollBarSize] = useState(0);
  const columnCount = Object.keys(columns).length;
  const gridBody = useRef();
  const gridHeader = useRef();
  const classes = pageStyles();
  useEffect(() => {
    if (gridHeader && gridHeader.current)
      gridHeader.current.recomputeGridSize();

    if (gridBody && gridBody.current) gridBody.current.recomputeGridSize();
  }, [gridWidth, scrollBarSize]);

  const forceUpdate = () => {
    if (gridBody && gridBody.current) gridBody.current.forceUpdate();
    if (gridHeader && gridHeader.current) gridHeader.current.forceUpdate();
  };

  useLayoutEffect(() => {
    if (ref && ref !== null) {
      ref.current = { forceUpdate };
    }
  });

  useEffect(() => {
    if (gridBody && gridBody.current) gridBody.current.forceUpdate();
  }, []);

  const loadInitialColumnWidths = useCallback(() => {
    const initialColumnWidths = getColumnsConfiguredWidths(columns);
    setColumnHeaderWidths(initialColumnWidths);
  }, [columns, setColumnHeaderWidths]);

  useEffect(() => {
    if (columnCount > 0 && columnHeaderWidths.length === 0)
      loadInitialColumnWidths();
  }, [columnCount, columnHeaderWidths, gridWidth, loadInitialColumnWidths]);

  useEffect(() => {
    if (columnCount > 0 && columnHeaderWidths.length === 0)
      loadInitialColumnWidths();

    if (gridHeader && gridHeader.current)
      gridHeader.current.recomputeGridSize();

    if (gridBody && gridBody.current) gridBody.current.recomputeGridSize();
  }, [columnCount, columnHeaderWidths, loadInitialColumnWidths]);

  const recomputeGridSizeAndExpand = async () => {
    if (gridBody && gridBody.current)
      await gridBody.current.recomputeGridSize();
  };

  useEffect(() => {
    recomputeGridSizeAndExpand();
  }, [columns, data]);

  const getColumnWidth = ({ index }) => {
    let newColumnHeaderWidths =
      columnHeaderWidths && columnHeaderWidths.length > 0
        ? [...columnHeaderWidths]
        : [];
    if (newColumnHeaderWidths && newColumnHeaderWidths.length === 0)
      newColumnHeaderWidths = getColumnsConfiguredWidths(columns);

    const isLastColumnIndex = columns.length - 1 === index;
    let newColumnWidth = _getColumnWidth(
      index,
      columns,
      newColumnHeaderWidths,
      formView,
      gridWidth,
      isLastColumnIndex,
      scrollBarSize,
    );

    if (isLastColumnIndex === true) {
      const lastColumnWidthAdj = getNewColumnWidthAfterAdjusment(
        newColumnHeaderWidths,
        gridWidth,
        scrollBarSize,
      );
      if (lastColumnWidthAdj !== null) newColumnWidth = lastColumnWidthAdj;
    }

    const finalNewColumnHeaderWidths = updateColumnWidths(
      index,
      newColumnWidth,
      newColumnHeaderWidths,
    );
    if (!isEqual(newColumnHeaderWidths, finalNewColumnHeaderWidths))
      setColumnHeaderWidths(finalNewColumnHeaderWidths);

    return newColumnWidth;
  };

  const handleOnScroll = (e) => {
    const { scrollLeft } = e;
    const noData = !!(data && data.length === 0);
    if (onScroll) onScroll(e);

    if (!noData) {
      if (gridBody.current && gridBody.current !== null)
        gridBody.current.scrollLeft = scrollLeft;
    } else if (gridHeader.current && gridHeader.current !== null)
      gridHeader.current.scrollLeft = scrollLeft;
  };

  const onScrollPresenceChg = ({ size, vertical }) => {
    setScrollBarSize(vertical ? size : 0);
  };

  return (
    <div
      className="flex-auto"
      role="table"
      aria-label={title || 'Grid'}
      aria-rowcount={data && data.length}
      aria-colcount={columns && columns.length}
    >
      <ScrollSync>
        {() => {
          return (
            <AutoSizer
              style={{
                ...(!noData && { width: '100%', height: '100%' }),
                border: `1px solid ${divider}`,
                overflow: 'hidden',
              }}
              onResize={({ width }) => {
                if (width !== 1200) setGridWidth(width);
              }}
            >
              {({ width, height }) => {
                return (
                  <>
                    <LinearProgress
                      className={classes.linearProgress}
                      style={{
                        display:
                          showLoadProgress && showLoader ? 'flex' : 'none',
                      }}
                      color="secondary"
                    />
                    {!noHeader && (
                      <div
                        style={{
                          position: 'relative',
                          height: !noData ? ROW_HEIGHT : height,
                          width,
                          overflow: 'hidden',
                          borderBottom: !noData
                            ? `1px solid ${divider}`
                            : '0px',
                        }}
                      >
                        {noData && (
                          <Divider
                            style={{
                              width: '100%',
                              position: 'absolute',
                              top: `${ROW_HEIGHT}px`,
                            }}
                          />
                        )}
                        {columnCount > 0 && (
                          <Grid
                            role="presentation"
                            aria-hidden="true"
                            ref={mergeRefs([gridHeader, ref])}
                            {...(!noData && { autoHeight: true })}
                            cellRenderer={headerRenderer}
                            className={classes.hideScroll}
                            hideTopRightGridScrollbar
                            hideBottomLeftGridScrollbar
                            columnWidth={getColumnWidth}
                            columnCount={columnCount}
                            width={width}
                            containerStyle={{
                              overflowX: 'hidden!important',
                              overflowY: 'hidden!important',
                            }}
                            height={!noData ? ROW_HEIGHT : height}
                            rowHeight={ROW_HEIGHT}
                            overscanColumnCount={7}
                            overscanRowCount={8}
                            scrollToColumn={scrollToColumn}
                            rowCount={1}
                            scrollLeft={lastScrollLeft}
                            {...(noData && {
                              onScroll: handleOnScroll,
                              onScrollbarPresenceChange: onScrollPresenceChg,
                            })}
                            fixedColumnCount={fixedColumnCount}
                          />
                        )}
                      </div>
                    )}
                    <div
                      {...(!noData
                        ? {
                            style: {
                              position: 'relative',
                              height: bodyHeight,
                              width,
                              borderTop: '0px',
                            },
                          }
                        : {
                            style: {
                              position: 'absolute',
                              top: `${ROW_HEIGHT}px`,
                              height: bodyHeight,
                              width,
                              borderTop: '0px',
                            },
                          })}
                    >
                      {columnCount > 0 && (
                        <div style={{ display: customBody ? 'none' : 'block' }}>
                          <Grid
                            role="presentation"
                            aria-hidden="true"
                            ref={mergeRefs([gridBody, ref])}
                            cellRenderer={(props) => cellRenderer(props, width)}
                            columnWidth={getColumnWidth}
                            width={width}
                            height={data.length > 0 ? bodyHeight : 0}
                            columnCount={columnCount}
                            rowCount={data.length}
                            rowHeight={60}
                            {...(scrollToRow !== null ? { scrollToRow } : null)}
                            scrollTop={lastScrollTop}
                            scrollLeft={lastScrollLeft}
                            scrollToColumn={scrollToColumn}
                            onScroll={handleOnScroll}
                            // onScrollbarPresenceChange={
                            //   handleOnScrollPresenceChange
                            // }
                            deferredMeasurementCache={deferredMeasurementCache}
                            estimatedRowSize={estimatedRowSize}
                            overscanColumnCount={overscanColumnCount}
                            overscanRowCount={overscanRowCount}
                            fixedColumnCount={fixedColumnCount}
                            onSectionRendered={onSectionRendered}
                          />
                        </div>
                      )}
                      {customBody ? (
                        <CustomBody
                          height={!noData ? bodyHeight : bodyHeight - 20}
                        >
                          {customBody}
                        </CustomBody>
                      ) : null}
                    </div>
                  </>
                );
              }}
            </AutoSizer>
          );
        }}
      </ScrollSync>
    </div>
  );
});

function CustomBody(props) {
  const { children, height } = props;

  return <div style={{ height }}>{children}</div>;
}

export default React.memo(CustomGrid);
