import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography, IconButton } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
    },
    appBar: {
      height: theme.Header.height,
      backgroundColor: theme.palette.primary.main,
      boxShadow: 'none',
      zIndex: theme.zIndex.drawer + 1,
    },
    toolbar: {
      height: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    content: {
      flexGrow: 1,
    },
    backButton: {
      marginRight: 0,
      marginLeft: 0,
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    backIcon: {
      fontSize: '32px',
      fontWeight: 'bold',
    },
    detailHeaderTitle: {
      position: 'absolute',
      left: '0',
      width: '100%',
      textAlign: 'center',
      zIndex: '-1',
    },
  };
});

const DetailNavigationBar = (props) => {
  const {
    children,
    detailHeader,
    alwaysShowLanguageToggle,
    backButtonClicked,
  } = props;
  const classes = useStyles({ alwaysShowLanguageToggle });
  const history = useHistory();

  const handleBackButtonOnClick = () => {
    history.goBack();
  };

  return (
    <>
      <AppBar position="relative" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            color="inherit"
            aria-label="navbar with back button"
            edge="start"
            className={classes.backButton}
            onClick={backButtonClicked || handleBackButtonOnClick}
          >
            <ArrowBackIcon className={classes.backIcon} />
          </IconButton>

          <Typography
            variant="h6"
            component="h2"
            classes={{ root: classes.detailHeaderTitle }}
          >
            {detailHeader}
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.root}>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {children}
        </main>
      </div>
    </>
  );
};

DetailNavigationBar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  detailHeader: PropTypes.string,
  alwaysShowLanguageToggle: PropTypes.bool,
  backButtonClicked: PropTypes.func,
};

DetailNavigationBar.defaultProps = {
  children: null,
  detailHeader: '',
  alwaysShowLanguageToggle: false,
  backButtonClicked: null,
};

export default DetailNavigationBar;
