/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
import SchoolsDropdown from './SchoolsDropdown';
import { getAge } from '../../utility/common';

// const today = new Date();

export default {
  header: {
    completedBy: {
      type: 'radio',
      name: 'completedBy',
      label: 'pages.consentForm.completedBy.consentFormCompletedBy',
      validation: {
        isRequired: true,
      },
      requiredMsg: 'pages.request.information.errors.emptySelection',
      conditionalTextField: 'otherCompletedBy',
      options: [
        {
          name: 'client',
          label: 'Client',
          value: 'client',
          ariaLabel: 'pages.consentForm.completedBy.clientAriaLabel',
        },
        {
          name: 'parent',
          label: 'Parent',
          value: 'parent',
          ariaLabel: 'pages.consentForm.completedBy.parentAriaLabel',
        },
        {
          name: 'legalDecisionMaker',
          label: 'pages.consentForm.completedBy.legalDecisionMaker',
          value: 'legalDecisionMaker',
          ariaLabel:
            'pages.consentForm.completedBy.legalDecisionMakerAriaLabel',
        },
        {
          name: 'other',
          label: 'pages.consentForm.completedBy.other',
          value: 'other',
          ariaLabel: 'pages.consentForm.completedBy.otherAriaLabel',
          render: {
            type: 'text',
            name: 'otherCompletedBy',
            label: 'pages.consentForm.completedBy.otherCompletedBy',
            ariaLabel: 'pages.consentForm.completedBy.otherCompletedBy',
            indent: true,
          },
        },
      ],
    },
  },
  sectionA: {
    surname: {
      type: 'text',
      name: 'surname',
      label: 'pages.consentForm.sectionA.surname',
      ariaLabel: 'pages.consentForm.sectionA.surnameAriaLabel',
      validation: {
        isRequired: true,
      },
      maxLength: 50,
    },
    givenName: {
      type: 'text',
      name: 'givenName',
      label: 'pages.consentForm.sectionA.givenName',
      ariaLabel: 'pages.consentForm.sectionA.givenNameAriaLabel',
      validation: {
        isRequired: true,
      },
      maxLength: 50,
    },
    address: {
      type: 'text',
      name: 'address',
      label: 'pages.consentForm.sectionA.address',
      ariaLabel: 'pages.consentForm.sectionA.addressAriaLabel',
      maxLength: 200,
      validation: {
        isRequired: true,
      },
    },
    city: {
      type: 'text',
      name: 'city',
      label: 'pages.consentForm.sectionA.city',
      ariaLabel: 'pages.consentForm.sectionA.cityAriaLabel',
      maxLength: 50,
      validation: {
        isRequired: true,
      },
    },
    postalCode: {
      type: 'text',
      name: 'postalCode',
      label: 'pages.consentForm.sectionA.postalCode',
      ariaLabel: 'pages.consentForm.sectionA.postalCodeAriaLabel',
      maxLength: 10,
      validation: {
        isRequired: true,
      },
    },
    phoneNumber: {
      type: 'text',
      name: 'phoneNumber',
      label: 'pages.consentForm.sectionA.phoneNumber',
      ariaLabel: 'pages.consentForm.sectionA.phoneNumberAriaLabel',
      mask: [
        '(',
        /[0-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ],
      validation: {
        isRequired: true,
        length: 10,
      },
    },
    email: {
      type: 'text',
      name: 'email',
      label: 'pages.consentForm.sectionA.email',
      ariaLabel: 'pages.consentForm.sectionA.emailAriaLabel',
      maxLength: 50,
      validation: {
        email: true,
        isRequired: true,
      },
    },
    sex: {
      type: 'radio',
      name: 'sex',
      label: 'pages.consentForm.sectionA.sex',
      ariaLabel: 'pages.consentForm.sectionA.sexAriaLabel',
      validation: {
        isRequired: true,
      },
      requiredMsg: 'pages.request.information.errors.emptySelection',
      options: [
        {
          name: 'male',
          label: 'pages.consentForm.sectionA.male',
          value: 'male',
          ariaLabel: 'pages.consentForm.sectionA.maleAriaLabel',
        },
        {
          name: 'female',
          label: 'pages.consentForm.sectionA.female',
          value: 'female',
          ariaLabel: 'pages.consentForm.sectionA.femaleAriaLabel',
        },
        {
          name: 'x',
          label: 'X',
          value: 'x',
          ariaLabel: 'pages.consentForm.sectionA.xGenderLabel',
        },
      ],
    },
    dateOfBirth: {
      type: 'date',
      name: 'dateOfBirth',
      label: 'pages.consentForm.sectionA.dateOfBirth',
      ariaLabel: 'pages.consentForm.sectionA.dateOfBirthAriaLabel',
      disabled: false,
      value: null,
      validation: {
        isRequired: true,
      },
      customValidate: (dateOfBirth) => {
        let error = {};
        if (getAge(dateOfBirth) < 4) {
          error = { error: 'pages.request.information.errors.minAge' };
        }

        return error;
      },
    },
    mbRegistrationNumber: {
      type: 'text',
      name: 'mbRegistrationNumber',
      label: 'pages.consentForm.sectionA.mbRegistrationNumber',
      ariaLabel: 'pages.consentForm.sectionA.mbRegistrationNumberAriaLabel',
      mask: [/[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/],
      requiredMsg:
        'pages.consentForm.validationFields.mbRegistrationNumberReqMsg',
      validation: {
        length: 6,
      },
      tooltip: 'This field is optional',
      customValidate: (mbRegNumber, consentFormState) => {
        let error = {};
        if (
          (!mbRegNumber || mbRegNumber === '') &&
          consentFormState.phin &&
          consentFormState.phin.value !== undefined &&
          consentFormState.phin.value !== '' &&
          consentFormState.phin.value !== null
        ) {
          error = {
            error:
              'pages.consentForm.validationFields.mbRegistrationNumberReqMsg',
          };
        }

        return error;
      },
    },
    phin: {
      type: 'text',
      name: 'phin',
      label: 'pages.consentForm.sectionA.phin',
      ariaLabel: 'pages.consentForm.sectionA.phinAriaLabel',
      mask: [/[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
      requiredMsg: 'pages.consentForm.validationFields.phinReqMsg',
      validation: {
        isRequired: false,
        length: 9,
      },
      tooltip: 'This field is optional',
      customValidate: (phin, consentFormState) => {
        let error = {};
        if (
          (!phin || phin === '') &&
          consentFormState.mbRegistrationNumber &&
          consentFormState.mbRegistrationNumber.value !== undefined &&
          consentFormState.mbRegistrationNumber.value !== '' &&
          consentFormState.mbRegistrationNumber.value !== null
        ) {
          error = { error: 'pages.consentForm.validationFields.phinReqMsg' };
        }

        return error;
      },
    },
    schoolName: {
      type: 'custom',
      component: SchoolsDropdown,
      name: 'schoolName',
      label: 'pages.consentForm.sectionA.schoolName',
      ariaLabel: 'pages.consentForm.sectionA.schoolNameAriaLabel',
      validation: {
        isRequired: true,
      },
    },
    schoolCity: {
      type: 'text',
      name: 'schoolCity',
      label: 'pages.consentForm.sectionA.schoolCity',
      disabled: true,
    },
    grade: {
      type: 'select',
      name: 'grade',
      label: 'pages.consentForm.sectionA.grade',
      ariaLabel: 'pages.consentForm.sectionA.gradeAriaLabel',
      validation: {
        isRequired: true,
      },
      options: [
        { name: '', label: '', value: '' },
        { name: 'kindergarten', label: 'Kindergarten', value: 'K' },
        {
          name: '1',
          label: '1',
          value: '1',
          ariaLabel: 'pages.consentForm.sectionA.gradeAriaLabelRadio.1',
        },
        {
          name: '2',
          label: '2',
          value: '2',
          ariaLabel: 'pages.consentForm.sectionA.gradeAriaLabelRadio.2',
        },
        {
          name: '3',
          label: '3',
          value: '3',
          ariaLabel: 'pages.consentForm.sectionA.gradeAriaLabelRadio.3',
        },
        {
          name: '4',
          label: '4',
          value: '4',
          ariaLabel: 'pages.consentForm.sectionA.gradeAriaLabelRadio.4',
        },
        {
          name: '5',
          label: '5',
          value: '5',
          ariaLabel: 'pages.consentForm.sectionA.gradeAriaLabelRadio.5',
        },
        {
          name: '6',
          label: '6',
          value: '6',
          ariaLabel: 'pages.consentForm.sectionA.gradeAriaLabelRadio.6',
        },
        {
          name: '7',
          label: '7',
          value: '7',
          ariaLabel: 'pages.consentForm.sectionA.gradeAriaLabelRadio.7',
        },
        {
          name: '8',
          label: '8',
          value: '8',
          ariaLabel: 'pages.consentForm.sectionA.gradeAriaLabelRadio.8',
        },
        {
          name: '9',
          label: '9',
          value: '9',
          ariaLabel: 'pages.consentForm.sectionA.gradeAriaLabelRadio.9',
        },
        {
          name: '10',
          label: '10',
          value: '10',
          ariaLabel: 'pages.consentForm.sectionA.gradeAriaLabelRadio.10',
        },
        {
          name: '11',
          label: '11',
          value: '11',
          ariaLabel: 'pages.consentForm.sectionA.gradeAriaLabelRadio.11',
        },
        {
          name: '12',
          label: '12',
          value: '12',
          ariaLabel: 'pages.consentForm.sectionA.gradeAriaLabelRadio.12',
        },
      ],
    },
  },
  sectionB: {
    hasQuestionFeverOrSymptoms: {
      type: 'radio',
      name: 'hasQuestionFeverOrSymptoms',
      label: 'pages.consentForm.sectionB.hasQuestionFeverOrSymptoms',
      ariaLabel:
        'pages.consentForm.sectionB.hasQuestionFeverOrSymptomsAriaLabel',
      requiredMsg: 'pages.request.information.errors.emptySelection',
      validation: {
        isRequired: true,
      },
      conditionalTextField: 'questionFeverOrSymptoms',
      options: [
        {
          name: 'no',
          label: 'pages.consentForm.sectionB.no',
          value: 'false',
          ariaLabel: 'pages.consentForm.sectionB.no',
        },
        {
          name: 'yes',
          label: 'pages.consentForm.sectionB.yes',
          value: 'true',
          ariaLabel: 'pages.consentForm.sectionB.yes',
          render: {
            type: 'text',
            name: 'questionFeverOrSymptoms',
            label: 'pages.consentForm.sectionB.ifYesDescribe',
            maxLength: 100,
            multiline: true,
            rows: 4,
          },
        },
      ],
      indentOptions: true,
    },
    hasQuestionAllergy: {
      type: 'radio',
      name: 'hasQuestionAllergy',
      label: 'pages.consentForm.sectionB.hasQuestionAllergy',
      ariaLabel: 'pages.consentForm.sectionB.hasQuestionAllergyAriaLabel',
      validation: {
        isRequired: true,
      },
      requiredMsg: 'pages.request.information.errors.emptySelection',
      conditionalTextField: 'questionAllergy',
      options: [
        {
          name: 'no',
          label: 'pages.consentForm.sectionB.no',
          value: 'false',
          ariaLabel: 'pages.consentForm.sectionB.no',
        },
        {
          name: 'yes',
          label: 'pages.consentForm.sectionB.yes',
          value: 'true',
          ariaLabel: 'pages.consentForm.sectionB.yes',

          render: {
            type: 'text',
            name: 'questionAllergy',
            label: 'pages.consentForm.sectionB.ifYesDescribe',
            maxLength: 100,
            multiline: true,
            rows: 4,
          },
        },
      ],
      indentOptions: true,
    },
    hasQuestionPEGAllergy: {
      type: 'radio',
      name: 'hasQuestionPEGAllergy',
      label: 'pages.consentForm.sectionB.hasQuestionPEGAllergy',
      ariaLabel: 'pages.consentForm.sectionB.hasQuestionPEGAllergyAriaLabel',
      validation: {
        isRequired: true,
      },
      requiredMsg: 'pages.request.information.errors.emptySelection',
      options: [
        {
          name: 'no',
          label: 'pages.consentForm.sectionB.no',
          value: 'false',
          ariaLabel: 'pages.consentForm.sectionB.no',
        },
        {
          name: 'yes',
          label: 'pages.consentForm.sectionB.yes',
          value: 'true',
          ariaLabel: 'pages.consentForm.sectionB.yes',
        },
      ],
      indentOptions: true,
    },
    hasQuestionVaccineReaction: {
      type: 'radio',
      name: 'hasQuestionVaccineReaction',
      label: 'pages.consentForm.sectionB.hasQuestionVaccineReaction',
      ariaLabel:
        'pages.consentForm.sectionB.hasQuestionVaccineReactionAriaLabel',
      validation: {
        isRequired: true,
      },
      requiredMsg: 'pages.request.information.errors.emptySelection',
      conditionalTextField: 'questionVaccineReaction',
      options: [
        {
          name: 'no',
          label: 'pages.consentForm.sectionB.no',
          value: 'false',
          ariaLabel: 'pages.consentForm.sectionB.no',
        },
        {
          name: 'yes',
          label: 'pages.consentForm.sectionB.yes',
          value: 'true',
          ariaLabel: 'pages.consentForm.sectionB.yes',
          render: {
            type: 'text',
            name: 'questionVaccineReaction',
            label: 'pages.consentForm.sectionB.ifYesDescribe',
            maxLength: 100,
            multiline: true,
            rows: 4,
          },
        },
      ],
      indentOptions: true,
    },
    hasQuestionMedicalConditions: {
      type: 'radio',
      name: 'hasQuestionMedicalConditions',
      label: 'pages.consentForm.sectionB.hasQuestionMedicalConditions',
      ariaLabel:
        'pages.consentForm.sectionB.hasQuestionMedicalConditionsAriaLabel',
      validation: {
        isRequired: true,
      },
      requiredMsg: 'pages.request.information.errors.emptySelection',
      conditionalTextField: 'questionMedicalConditions',
      options: [
        {
          name: 'no',
          label: 'pages.consentForm.sectionB.no',
          value: 'false',
          ariaLabel: 'pages.consentForm.sectionB.no',
        },
        {
          name: 'yes',
          label: 'pages.consentForm.sectionB.yes',
          value: 'true',
          ariaLabel: 'pages.consentForm.sectionB.yes',
          render: {
            type: 'text',
            name: 'questionMedicalConditions',
            label: 'pages.consentForm.sectionB.questionMedicalConditions',
            maxLength: 100,
            multiline: true,
            rows: 4,
          },
        },
      ],
      indentOptions: true,
    },
    hasQuestionReceivedVaccineLast14Days: {
      type: 'radio',
      name: 'hasQuestionReceivedVaccineLast14Days',
      label: 'pages.consentForm.sectionB.hasQuestionReceivedVaccineLast14Days',
      ariaLabel:
        'pages.consentForm.sectionB.hasQuestionReceivedVaccineLast14DaysAriaLabel',
      validation: {
        isRequired: true,
      },
      requiredMsg: 'pages.request.information.errors.emptySelection',
      options: [
        {
          name: 'no',
          label: 'pages.consentForm.sectionB.no',
          value: 'false',
          ariaLabel: 'pages.consentForm.sectionB.no',
        },
        {
          name: 'yes',
          label: 'pages.consentForm.sectionB.yes',
          value: 'true',
          ariaLabel: 'pages.consentForm.sectionB.yes',
        },
      ],
      indentOptions: true,
    },
    hasQuestionBloodClottingMedication: {
      type: 'radio',
      name: 'hasQuestionBloodClottingMedication',
      label: 'pages.consentForm.sectionB.hasQuestionBloodClottingMedication',
      ariaLabel:
        'pages.consentForm.sectionB.hasQuestionBloodClottingMedicationAriaLabel',
      validation: {
        isRequired: true,
      },
      requiredMsg: 'pages.request.information.errors.emptySelection',
      conditionalTextField: 'questionBloodClottingMedication',
      options: [
        {
          name: 'no',
          label: 'pages.consentForm.sectionB.no',
          value: 'false',
          ariaLabel: 'pages.consentForm.sectionB.no',
        },
        {
          name: 'yes',
          label: 'pages.consentForm.sectionB.yes',
          value: 'true',
          ariaLabel: 'pages.consentForm.sectionB.yes',
          render: {
            type: 'text',
            name: 'questionBloodClottingMedication',
            label: 'pages.consentForm.sectionB.questionBloodClottingMedication',
            maxLength: 100,
            multiline: true,
            rows: 4,
          },
        },
      ],
      indentOptions: true,
    },
    hasQuestionPregnant: {
      type: 'radio',
      name: 'hasQuestionPregnant',
      label: 'pages.consentForm.sectionB.hasQuestionPregnant',
      ariaLabel: 'pages.consentForm.sectionB.hasQuestionPregnantAriaLabel',
      validation: {
        isRequired: true,
      },
      requiredMsg: 'pages.request.information.errors.emptySelection',
      options: [
        {
          name: 'no',
          label: 'pages.consentForm.sectionB.no',
          value: 'false',
          ariaLabel: 'pages.consentForm.sectionB.no',
        },
        {
          name: 'yes',
          label: 'pages.consentForm.sectionB.yes',
          value: 'true',
          ariaLabel: 'pages.consentForm.sectionB.yes',
        },
      ],
      indentOptions: true,
    },
    hasQuestionImmuneSystemSuppressed: {
      type: 'radio',
      name: 'hasQuestionImmuneSystemSuppressed',
      label: 'pages.consentForm.sectionB.hasQuestionImmuneSystemSuppressed',
      ariaLabel:
        'pages.consentForm.sectionB.hasQuestionImmuneSystemSuppressedAriaLabel',
      validation: {
        isRequired: true,
      },
      requiredMsg: 'pages.request.information.errors.emptySelection',
      options: [
        {
          name: 'no',
          label: 'pages.consentForm.sectionB.no',
          value: 'false',
          ariaLabel: 'pages.consentForm.sectionB.no',
        },
        {
          name: 'yes',
          label: 'pages.consentForm.sectionB.yes',
          value: 'true',
          ariaLabel: 'pages.consentForm.sectionB.yes',
        },
      ],
      indentOptions: true,
    },
    hasQuestionAutoimmune: {
      type: 'radio',
      name: 'hasQuestionAutoimmune',
      label: 'pages.consentForm.sectionB.hasQuestionAutoimmune',
      ariaLabel: 'pages.consentForm.sectionB.hasQuestionAutoimmuneAriaLabel',
      validation: {
        isRequired: true,
      },
      requiredMsg: 'pages.request.information.errors.emptySelection',
      options: [
        {
          name: 'no',
          label: 'pages.consentForm.sectionB.no',
          value: 'false',
          ariaLabel: 'pages.consentForm.sectionB.no',
        },
        {
          name: 'yes',
          label: 'pages.consentForm.sectionB.yes',
          value: 'true',
          ariaLabel: 'pages.consentForm.sectionB.yes',
        },
      ],
      indentOptions: true,
    },
    hasQuestionVSTOrHIT: {
      type: 'radio',
      name: 'hasQuestionVSTOrHIT',
      label: 'pages.consentForm.sectionB.hasQuestionVSTOrHIT',
      ariaLabel: 'pages.consentForm.sectionB.hasQuestionVSTOrHITAriaLabel',
      validation: {
        isRequired: true,
      },
      requiredMsg: 'pages.request.information.errors.emptySelection',
      options: [
        {
          name: 'no',
          label: 'pages.consentForm.sectionB.no',
          value: 'false',
          ariaLabel: 'pages.consentForm.sectionB.no',
        },
        {
          name: 'yes',
          label: 'pages.consentForm.sectionB.yes',
          value: 'true',
          ariaLabel: 'pages.consentForm.sectionB.yes',
        },
      ],
      indentOptions: true,
    },
    haveReceivedDoseCount: {
      type: 'radio',
      name: 'haveReceivedDoseCount',
      label: 'pages.consentForm.sectionB.haveReceivedDoseCount',
      ariaLabel: 'pages.consentForm.sectionB.haveReceivedDoseCountAriaLabel',
      validation: {
        isRequired: true,
      },
      requiredMsg: 'pages.request.information.errors.emptySelection',
      options: [
        { name: 'zero', label: '0 Doses', value: '0', ariaLabel: '0 Doses' },
        { name: 'one', label: 'Dose 1', value: '1', ariaLabel: 'Dose 1' },
        { name: 'two', label: 'Dose 2', value: '2', ariaLabel: 'Dose 2' },
      ],
      indentOptions: true,
    },
  },
  sectionC: {
    racialEthnicity: {
      type: 'radio',
      name: 'racialEthnicity',
      label: 'pages.consentForm.sectionC.racialEthnicity',
      validation: {
        isRequired: true,
      },
      requiredMsg: 'pages.request.information.errors.emptySelection',
      conditionalTextField: 'otherRacialEthnicity',
      options: [
        {
          name: 'african',
          label: 'pages.consentForm.sectionC.african',
          value: 'african',
          ariaLabel: 'pages.consentForm.sectionC.african',
        },
        {
          name: 'black',
          label: 'pages.consentForm.sectionC.black',
          value: 'black',
          ariaLabel: 'pages.consentForm.sectionC.black',
        },
        {
          name: 'chinese',
          label: 'pages.consentForm.sectionC.chinese',
          value: 'chinese',
          ariaLabel: 'pages.consentForm.sectionC.chinese',
        },
        {
          name: 'filipino',
          label: 'pages.consentForm.sectionC.filipino',
          value: 'filipino',
          ariaLabel: 'pages.consentForm.sectionC.filipino',
        },
        {
          name: 'latinAmerican',
          label: 'pages.consentForm.sectionC.latinAmerican',
          value: 'latin_american',
          ariaLabel: 'pages.consentForm.sectionC.latinAmerican',
        },
        {
          name: 'northAmericanIndigenous',
          label: 'pages.consentForm.sectionC.northAmericanIndigenous',
          value: 'north_american_indigenous',
          ariaLabel: 'pages.consentForm.sectionC.northAmericanIndigenous',
        },
        {
          name: 'southAsian',
          label: 'pages.consentForm.sectionC.southAsian',
          value: 'south_asian',
          ariaLabel: 'pages.consentForm.sectionC.southAsian',
        },
        {
          name: 'southeastAsian',
          label: 'pages.consentForm.sectionC.southeastAsian',
          value: 'southeast_asian',
          ariaLabel: 'pages.consentForm.sectionC.southeastAsian',
        },
        {
          name: 'white',
          label: 'pages.consentForm.sectionC.white',
          value: 'white',
          ariaLabel: 'pages.consentForm.sectionC.white',
        },
        {
          name: 'other',
          label: 'pages.consentForm.sectionC.other',
          value: 'other',
          ariaLabel: 'pages.consentForm.sectionC.other',
          render: {
            type: 'text',
            name: 'otherRacialEthnicity',
            label: '',
            indent: true,
          },
          maxLength: 50,
        },
        {
          name: 'preferNotToAnswer',
          label: 'pages.consentForm.sectionC.preferNotToAnswer',
          value: 'prefer_not_to_answer',
          ariaLabel: 'pages.consentForm.sectionC.preferNotToAnswer',
        },
      ],
    },
    northAmericanIndigenousIdentity: {
      type: 'radio',
      name: 'northAmericanIndigenousIdentity',
      label: 'pages.consentForm.sectionC.naIndigenousIdentity',
      validation: {
        isRequired: 'false',
      },
      options: [
        {
          name: 'firstNations',
          label: 'pages.consentForm.sectionC.firstNations',
          value: 'first_nations',
          ariaLabel: 'pages.consentForm.sectionC.firstNations',
        },
        {
          name: 'metis',
          label: 'pages.consentForm.sectionC.metis',
          value: 'metis',
          ariaLabel: 'pages.consentForm.sectionC.metis',
        },
        {
          name: 'inuit',
          label: 'pages.consentForm.sectionC.inuit',
          value: 'inuit',
          ariaLabel: 'pages.consentForm.sectionC.inuit',
        },
        {
          name: 'notApplicable',
          label: 'pages.consentForm.sectionC.notApplicable',
          value: 'not_applicable',
          ariaLabel: 'pages.consentForm.sectionC.notApplicable',
        },
      ],
    },
  },
  sectionD: 'pages.consentForm.sectionD.info',
  sectionD1Consent: 'pages.consentForm.sectionD.sectionD1.consent',
  sectionDCovidFactSheet: {
    informedConsentLastName: {
      type: 'text',
      name: 'informedConsentLastName',
      label: 'pages.consentForm.sectionD.sectionD1.informedConsentLastName',
      ariaLabel:
        'pages.consentForm.sectionD.sectionD1.informedConsentLastNameAriaLabel',
      maxLength: 100,
      validation: {
        isRequired: true,
      },
    },
    informedConsentFirstName: {
      type: 'text',
      name: 'informedConsentFirstName',
      label: 'pages.consentForm.sectionD.sectionD1.informedConsentFirstName',
      ariaLabel:
        'pages.consentForm.sectionD.sectionD1.informedConsentFirstNameAriaLabel',
      maxLength: 100,
      validation: {
        isRequired: true,
      },
    },
    informedConsentRelationship: {
      type: 'select',
      name: 'informedConsentRelationship',
      label: 'pages.consentForm.sectionD.sectionD1.informedConsentRelationship',
      ariaLabel:
        'pages.consentForm.sectionD.sectionD1.informedConsentRelationshipAriaLabel',
      requiredAriaMsg: 'pages.request.information.errors.emptyWithoutLabel',
      validation: {
        isRequired: true,
      },
      options: [
        { name: 'client', label: 'Client', value: 'CLIENT' },
        {
          name: 'father',
          label: 'pages.consentForm.sectionD.sectionD1.father',
          value: 'FATHER',
        },
        {
          name: 'mother',
          label: 'pages.consentForm.sectionD.sectionD1.mother',
          value: 'MOTHER',
        },
        {
          name: 'legalDecisionMaker',
          label: 'pages.consentForm.sectionD.sectionD1.legalDecisionMaker',
          value: 'LEGAL_DECISION_MAKER',
        },
      ],
    },
    informedConsentPhoneNumber: {
      type: 'text',
      name: 'informedConsentPhoneNumber',
      label: 'pages.consentForm.sectionD.sectionD1.informedConsentPhoneNumber',
      ariaLabel:
        'pages.consentForm.sectionD.sectionD1.informedConsentPhoneNumberAriaLabel',
      mask: [
        '(',
        /[0-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ],
      validation: {
        isRequired: true,
        length: 10,
      },
    },
    informedConsentEmail: {
      type: 'text',
      name: 'informedConsentEmail',
      label: 'pages.consentForm.sectionD.sectionD1.informedConsentEmail',
      ariaLabel:
        'pages.consentForm.sectionD.sectionD1.informedConsentEmailAriaLabel',
      validation: {
        email: true,
        isRequired: true,
      },
    },
  },
  sectionDClauses: {
    hasUnderstandingAndQuestionsAnswered: {
      type: 'checkbox',
      name: 'hasUnderstandingAndQuestionsAnswered',
      value: false,
      label:
        'pages.consentForm.sectionD.clauses.hasUnderstandingAndQuestionsAnswered',
      requiredMsg: 'pages.request.information.errors.mustAgree',
      requiredAriaMsg: 'pages.request.information.errors.mustAgreeAria',
      validation: {
        isRequired: true,
      },
    },
    legalConsent: {
      type: 'checkbox',
      name: 'legalConsent',
      value: false,
      label: 'pages.consentForm.sectionD.clauses.legalConsent',
      requiredMsg: 'pages.request.information.errors.mustAgree',
      requiredAriaMsg: 'pages.request.information.errors.mustAgreeAria',
      validation: {
        isRequired: true,
      },
    },
    isDeclarationCompleteAndTrue: {
      type: 'checkbox',
      name: 'isDeclarationCompleteAndTrue',
      value: false,
      label: 'pages.consentForm.sectionD.clauses.isDeclarationCompleteAndTrue',
      requiredMsg: 'pages.request.information.errors.mustAgree',
      requiredAriaMsg: 'pages.request.information.errors.mustAgreeAria',
      validation: {
        isRequired: true,
      },
    },
  },
  sectionE: {
    thirdPartyDisclosure: {
      type: 'checkbox',
      name: 'thirdPartyDisclosure',
      value: false,
      label: 'pages.consentForm.sectionE.thirdPartyDisclosure',
      requiredMsg: 'pages.request.information.errors.mustAgree',
      requiredAriaMsg: 'pages.request.information.errors.mustAgreeAria',
      validation: {
        isRequired: true,
      },
    },
  },
};
