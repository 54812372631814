import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { HashRouter, Switch, Route } from 'react-router-dom';
import { MsalProvider } from './services/login/MsalProvider';

import { LoginContextProvider } from './services/login/LoginContext';
import { LanguageContextProvider } from './services/localization/LanguageContext';

import RouterManage from './components/router-manage/RouterManage';
import routerConfig, {
  getRouteInformationByRole,
  RouterRole,
} from './RouterConfig';
import LuminaTheme from './styles/theme/theme';

function App() {
  const routPublic = getRouteInformationByRole(RouterRole.PUBLIC);
  return (
    <>
      <HashRouter>
        <ThemeProvider theme={LuminaTheme}>
          <MsalProvider>
            <LanguageContextProvider>
              <Switch>
                {routPublic.map((rt) => {
                  return (
                    <Route
                      key={`${rt.path}_public`}
                      path={rt.path}
                      exact={rt.exact}
                      component={rt.component}
                    />
                  );
                })}

                <Route path="*">
                  <LoginContextProvider>
                    <RouterManage routerConfig={routerConfig} />
                  </LoginContextProvider>
                </Route>
              </Switch>
            </LanguageContextProvider>
          </MsalProvider>
        </ThemeProvider>
      </HashRouter>
    </>
  );
}

export default App;
