import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import PageLayout from 'components/page-layout/PageLayout';
import { LanguageContext } from 'services/localization/LanguageContext';
import { includes } from 'lodash';
import withWidth from '@material-ui/core/withWidth';
import getPageTitle from 'utility/pageTitle';
import PrivateSection from './components/PrivateSection';

const PrivacyNoticAndConsentPage = ({ width }) => {
  const { getTranslationObj, translate, lang } = useContext(LanguageContext);
  const privacyPolicy = getTranslationObj('pages.privacyAndNotice');
  const maxWidth = includes(['xs', 'sm', 'md'], width) ? '100%' : '60%';

  useEffect(() => {
    document.title = getPageTitle(translate('layout.nav.privacyNotice'), lang);
  }, [translate, lang]);

  return (
    <PageLayout
      showNavigation
      showDrawer={false}
      alwaysShowLanguageToggle
      forceShowFooter
      forceShowAppBar
      maxWidth={maxWidth}
    >
      <PrivateSection privacyPolicy={privacyPolicy} />
    </PageLayout>
  );
};

PrivacyNoticAndConsentPage.defaultProps = {
  width: null,
};

PrivacyNoticAndConsentPage.propTypes = {
  width: PropTypes.string,
};

export default withWidth()(PrivacyNoticAndConsentPage);
