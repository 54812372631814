import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PageLayout from 'components/page-layout/PageLayout';
import { makeStyles, Box, Typography } from '@material-ui/core';
import { LanguageContext } from 'services/localization/LanguageContext';
import CustomizedButton from 'components/button/CustomizedButton';
import getPageTitle from 'utility/pageTitle';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bodyContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  button: {
    marginTop: theme.spacing(5),
  },
  title: {
    textAlign: 'center',
  },
}));

const Homepage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { translate, lang } = useContext(LanguageContext);

  useEffect(() => {
    document.title = getPageTitle(
      `${translate('layout.projectTitle')} ${translate('layout.nav.homepage')}`,
      lang,
    );
  }, [translate, lang]);

  return (
    <PageLayout showNavigation showDrawer>
      <Box classes={{ root: classes.container }}>
        <Typography
          variant="h6"
          component="h1"
          tabIndex="0"
          classes={{ root: classes.title }}
        >
          <b>{translate('pages.homepage.title')}</b>
        </Typography>
        <div className={classes.bodyContainer}>
          <Typography tabIndex="0">
            {translate('pages.homepage.body')}
          </Typography>
          <CustomizedButton
            aria-label="Open new consent form"
            variant="contained"
            color="primary"
            type="submit"
            className={classes.button}
            onClick={() => history.push('/consent-form')}
          >
            {translate('pages.homepage.button')}
          </CustomizedButton>
        </div>
      </Box>
    </PageLayout>
  );
};

export default Homepage;
