/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { LanguageContext } from 'services/localization/LanguageContext';
import { LANG } from 'utility/languageUtility';
import enLocale from 'date-fns/locale/en-CA';
import frLocale from 'date-fns/locale/fr';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const DatePickerCustom = ({
  label,
  name,
  value,
  disabled,
  onChange,
  onBlur,
  error,
  required,
  ariaLabel,
  focusEl,
}) => {
  const classes = useStyles();
  const { translate, lang } = useContext(LanguageContext);
  const [loaded, setLoaded] = useState(false);
  const [selectedDate, handleSelectedDate] = useState(value || null);

  useEffect(() => {
    if (name === focusEl && document.getElementById(name)) {
      document.getElementById(name).focus();
    }
  }, [name, focusEl]);

  useEffect(() => {
    if (onChange && typeof onChange === 'function' && loaded)
      onChange(name, selectedDate);
  }, [selectedDate, loaded]);

  const handleOnBlur = () => {
    setLoaded(true);
  };

  const handleOnAccept = (dateValue) => {
    if (onChange && typeof onChange === 'function')
      onChange(name, selectedDate);
  };

  const handleOnError = useCallback(
    (newError, newValue) => {
      handleSelectedDate(newValue);
      if (onChange && typeof onChange === 'function' && selectedDate !== null)
        onChange(name, newValue);
    },
    [selectedDate],
  );

  const getAriaLabel = () => {
    let inputAriaLabel = '';
    if (required)
      inputAriaLabel = `${translate('pages.consentForm.requiredFieldAria')}`;

    inputAriaLabel += (ariaLabel && translate(ariaLabel)) || translate(label);
    return inputAriaLabel;
  };

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={lang === LANG.FR ? frLocale : enLocale}
    >
      <KeyboardDatePicker
        className={classes.root}
        autoOk
        id={name}
        disableFuture
        inputProps={{
          'aria-label': getAriaLabel(),
        }}
        label={`${required ? '* ' : ''}${translate(label)}`}
        value={selectedDate}
        disabled={disabled}
        onChange={handleSelectedDate}
        onAccept={handleOnAccept}
        // onClose={handleOnClose}
        onError={handleOnError}
        onBlur={handleOnBlur}
        name={name}
        variant="inline"
        inputVariant="outlined"
        format="yyyy/MM/dd"
        // required={required}
        error={Boolean(error)}
        helperText={translate(error)}
      />
    </MuiPickersUtilsProvider>
  );
};

DatePickerCustom.defaultProps = {
  onBlur: () => {},
  error: '',
  required: false,
  ariaLabel: undefined,
  focusEl: undefined,
};

DatePickerCustom.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  value: PropTypes.instanceOf(Date),
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.string,
      PropTypes.shape({ label: PropTypes.string }),
    ),
  ]),
  required: PropTypes.bool,
  ariaLabel: PropTypes.string,
  focusEl: PropTypes.string,
};

export default DatePickerCustom;
