/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import { LanguageContext } from 'services/localization/LanguageContext';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import pagesUrl from 'commons/config/PagesUrl';
import FooterContainer from '../footer/PageFooter';
import NavigationDrawer from './NavigationDrawer';
import NavBarLogo from './NavBarLogo';
import { ReactComponent as EditCard } from '../../img/edit-card.svg';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
    },
    appBar: {
      height: theme.Header.height,
      backgroundColor: theme.palette.primary.main,
      boxShadow: 'none',
      // use this property to cover the top of permanent drawer
      zIndex: theme.zIndex.drawer + 1,
    },
    toolbar: {
      height: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    menuButton: {
      marginRight: '0px',
      marginLeft: `-${theme.spacing(1)}px`,
      zIndex: '999',
    },
    content: {
      flexGrow: 1,
    },
    // Bringing tooltip closer to the button
    tooltip: {
      marginTop: theme.spacing(1),
      fontSize: '1em',
      padding: '10px',
      textAlign: 'center',
      color: 'rgba(0, 0, 0, 0.87)',
      backgroundColor: 'white',
      boxShadow: theme.shadows[1],
    },
    arrow: {
      color: 'white',
    },
    linkText: {
      fontSize: '0.85em',
    },
    languageLink: {
      textDecoration: 'underline',
      color: 'white',
      display: (props) =>
        props.alwaysShowLanguageToggle ? 'inline-block' : 'none',

      [theme.breakpoints.up(theme.navBarBreakpoint.hideDrawerName)]: {
        display: () => 'inline-block',
        marginLeft: 'auto',
      },
    },
    logoContainer: {
      width: '100%',
      position: 'absolute',
      left: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };
});

const NavigationBar = (props) => {
  const { children, showIconButton, alwaysShowLanguageToggle, forceShow } =
    props;
  const classes = useStyles({ alwaysShowLanguageToggle });
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const { translate, toggleLang } = useContext(LanguageContext);
  const theme = useTheme();
  const location = useLocation();
  useEffect(() => {
    const closeDrawer = () => setDrawerOpen(false);
    window.addEventListener('resize', closeDrawer);
    return () => window.removeEventListener('resize', closeDrawer);
  }, []);

  const handleDrawerToggle = (isOpen) => {
    setDrawerOpen(isOpen);
  };

  const NavBar = () => {
    return (
      <>
        <AppBar position="sticky" className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            {showIconButton ? (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={() => handleDrawerToggle(!isDrawerOpen)}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
            ) : (
              <div />
            )}
            <div className={classes.logoContainer}>
              <NavBarLogo isWhiteLogo center />
            </div>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              component="button"
              className={classes.languageLink}
              onClick={toggleLang}
            >
              {translate('layout.otherLanguage')}
            </Link>
          </Toolbar>
        </AppBar>
        {isDrawerOpen && (
          <NavigationDrawer
            isOpen={isDrawerOpen}
            onClose={() => handleDrawerToggle(false)}
          />
        )}

        <div className={classes.root}>
          <main className={classes.content}>
            <div className={classes.toolbar} />
            {children}
          </main>
        </div>
      </>
    );
  };

  if (forceShow) return <>{NavBar()}</>;

  return (
    <Hidden {...theme.navBarBreakpoint.hideDrawerButton}>{NavBar()}</Hidden>
  );
};

NavigationBar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  showIconButton: PropTypes.bool,
  alwaysShowLanguageToggle: PropTypes.bool,
  forceShow: PropTypes.bool,
};

NavigationBar.defaultProps = {
  children: null,
  showIconButton: true,
  alwaysShowLanguageToggle: true,
  forceShow: false,
};

export default NavigationBar;
