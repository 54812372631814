import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import mbLogoWhite from 'img/mb-logo-white.png';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.Footer.backgroundColor,
    height: theme.Footer.height,
    minHeight: theme.Footer.height,
    maxHeight: theme.Footer.height,
    width: '100%',
    marginTop: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    height: '50%',
  },
}));

const PageFooter = () => {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <img
        src={mbLogoWhite}
        alt="Manitoba Government"
        height={30}
        className={classes.logo}
      />
    </footer>
  );
};

export default PageFooter;
