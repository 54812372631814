import LogoutPage from './pages/logout/LogoutPage';
import FAQProxyPage from './pages/public-faq/FAQProxyPage';
import ConsentForm from './pages/consent-form';
import ConsentList from './pages/consent-list';
import PrivacyNoticeAndConsent from './pages/privacy-notice-and-consent';
import Homepage from './pages/homepage';
import Survey from './pages/survey';
import { getCommon } from './commons/index';

const { pagesUrl } = getCommon();
export const RouterRole = {
  PUBLIC: 'public',
  PROTECTED: 'protected',
};
const routerConfig = [
  {
    path: pagesUrl.get('covidFaq').url,
    component: FAQProxyPage,
    exact: true,
    role: RouterRole.PROTECTED,
  },
  {
    path: pagesUrl.get('covidFaqPublic').url,
    component: FAQProxyPage,
    exact: true,
    role: RouterRole.PUBLIC,
  },
  {
    path: pagesUrl.get('PrivacyNotice').url,
    component: PrivacyNoticeAndConsent,
    exact: true,
    role: RouterRole.PUBLIC,
  },
  {
    path: pagesUrl.get('covidConsentForm').url,
    component: ConsentForm,
    exact: true,
    role: RouterRole.PROTECTED,
  },
  {
    path: pagesUrl.get('covidConsentList').url,
    component: ConsentList,
    exact: true,
    role: RouterRole.PROTECTED,
  },
  {
    path: pagesUrl.get('survey').url,
    component: Survey,
    exact: true,
    role: RouterRole.PROTECTED,
  },
  {
    path: pagesUrl.get('logout').url,
    component: LogoutPage,
    exact: true,
    role: RouterRole.PROTECTED,
  },
  {
    path: '/',
    component: Homepage,
    exact: true,
    role: RouterRole.PROTECTED,
  },
];
export function getRouteInformationByRole(name) {
  const rout = routerConfig.filter((rt) => {
    return rt.role === name;
  });
  return rout;
}

export default routerConfig;
