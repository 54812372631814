/*
 * @Description:
 * @Author: Shaomin Fei
 * @Date: 2021-03-28 23:04:08
 */
import * as React from 'react';

import { createContext, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { MsaProviderContext } from './MsalProvider';

import AzureOperation from '../common/index';

export const LoginContext = createContext();
export class LoginContextValue {
  constructor() {
    this.account = null;
    this.loginError = null;
    this.login = null;
    this.logout = null;
    this.editProfile = null;
    this.changePwd = null;
  }
}

export const LoginContextProvider = (props) => {
  // when close page, and reopen it, the login will run automatically,
  // and inProgress === 'login',
  const { children } = props;
  const { instance } = AzureOperation;
  const msaProviderContext = React.useContext(MsaProviderContext);
  const { accounts, inProgress, isAuthenticated } = msaProviderContext;
  const { search } = useLocation();

  const login = useCallback((method = 'loginRedirect', urlSearch) => {
    if (method === 'loginRedirect') {
      AzureOperation.redirectToLogin(null, urlSearch);
    }
  }, []);

  const logout = useCallback(
    (reject) => {
      instance.logoutRedirect().catch((error) => {
        if (reject) {
          reject(error);
        }
      });
    },
    [instance],
  );
  const editProfile = () => {
    instance.loginRedirect(AzureOperation.b2cPolicies.authorities.editProfile);
  };

  useEffect(() => {
    //   when login, azure will redirect, which means the whole app will reload,
    // but the progresss is "startup", if we don't check inProgress, we will
    // call login the second time, which cause the Azure report error

    if (accounts.length === 0 && inProgress === 'none') {
      login('loginRedirect', search);
    }
  }, [inProgress, login, accounts.length, search]);

  //  don't call logout when this component is unmounted.
  // we have public page now, if we call logout here, when
  // visit public page, the logout will redirect to login url.
  // useEffect(() => {
  //   return () => logout();
  // }, []);

  const contextValue = new LoginContextValue();
  contextValue.login = login;
  contextValue.logout = logout;
  contextValue.editProfile = editProfile;
  contextValue.account = accounts.length > 0 ? accounts[0] : null;
  contextValue.isAuthenticated = isAuthenticated;
  return (
    <LoginContext.Provider value={contextValue}>
      {children}
    </LoginContext.Provider>
  );
};
LoginContextProvider.defaultProps = {
  children: null,
};
LoginContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
};
