/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { isEqual, find } from 'lodash';
import { Tooltip, Icon, Typography, makeStyles } from '@material-ui/core';
import SortDirection from 'components/react-virtualized-grid/SortDirection';

const useStyles = makeStyles(() => ({
  knob: {
    '&:hover': {
      opacity: '1!important',
    },
  },
  label: {
    fontSize: '0.9rem',
    fontWeight: 'bold',
    lineHeight: '1rem',
  },
}));

function RVHeader(props) {
  const {
    dataGrid,
    label,
    disableSort,
    defaultSort,
    setDataGridState,
    setHasSort,
    dbColumnName,
    onSort,
    hideColumnSortDirection = false,
    columnIndex,
    ariaLabel,
  } = props;

  const classes = useStyles();

  const getNewHasSort = (newSorts) => {
    if (isEqual(newSorts, defaultSort)) return false;
    return true;
  };

  const getNewSortDirection = (currSortDirection) => {
    switch (currSortDirection) {
      case null: {
        return SortDirection.ASC;
      }
      case SortDirection.ASC: {
        return SortDirection.DESC;
      }
      case SortDirection.DESC: {
        return null;
      }
      default: {
        return null;
      }
    }
  };

  const getCurrentSortDirection = (changedColumn) => {
    if (dataGrid && dataGrid.sorts) {
      const columnSort =
        dataGrid &&
        dataGrid.sorts.filter((sort) => {
          if (sort.fieldName.toLowerCase() === changedColumn.toLowerCase())
            return sort;

          return null;
        });
      const currentSort =
        columnSort.length !== 0
          ? columnSort[0].order === 0
            ? SortDirection.ASC
            : SortDirection.DESC
          : null;

      return columnSort.length === 0 ? null : currentSort;
    }
    return null;
  };

  const removeColumnSort = (changedColumn) => {
    const newSorts =
      dataGrid &&
      dataGrid.sorts.filter((sort) => {
        if (sort.fieldName.toLowerCase() !== changedColumn.toLowerCase())
          return sort;
      });

    return newSorts.length !== 0 ? newSorts : defaultSort;
  };

  const updateColumnSort = (changedColumn, newSortDirection) => {
    const currentHasSort = getNewHasSort(dataGrid.sorts);
    const findSort = find(currentHasSort ? dataGrid.sorts || [] : [], (o) => {
      return o.fieldName.toLowerCase() === changedColumn.toLowerCase();
    });
    let newSorts = [];

    const dgSorts =
      dataGrid && dataGrid.sorts && dataGrid.sorts.length > 0
        ? [...dataGrid.sorts]
        : [];
    if (findSort !== undefined) {
      const updatedSorts =
        dgSorts &&
        dgSorts.map((sort) => {
          const newSort = sort;
          if (sort.fieldName.toLowerCase() === changedColumn.toLowerCase()) {
            newSort.order = newSortDirection === SortDirection.ASC ? 0 : 1;
          }
          return newSort;
        });
      newSorts = updatedSorts;
    } else {
      newSorts = currentHasSort ? dataGrid.sorts || [] : [];
      newSorts.push({
        fieldName: changedColumn,
        order: newSortDirection === SortDirection.ASC ? 0 : 1,
      });
    }
    return newSorts;
  };

  const getNewSorts = (changedColumn, newSortDirection) => {
    let newSorts = [];
    if (newSortDirection === null) newSorts = removeColumnSort(changedColumn);
    else newSorts = updateColumnSort(changedColumn, newSortDirection);

    return newSorts;
  };

  // const columnSortDirection = getSortDirection(sortFound);
  const columnSortDirection = 'none';

  const handleColumnSortChange = (sortBy) => {
    const changedColumn = sortBy;
    const currentSortDirection = getCurrentSortDirection(changedColumn);
    const newSortDirection = getNewSortDirection(currentSortDirection);
    const newSorts = getNewSorts(changedColumn, newSortDirection);
    const newHasSort = getNewHasSort(newSorts);
    setDataGridState({ ...dataGrid, sorts: newSorts });
    setHasSort(newHasSort);
    if (onSort) onSort(newSorts);
  };

  return (
    <>
      <div
        className="flex justify-between align-center font-600 text-14 w-full"
        aria-label={ariaLabel}
        id={`col-${columnIndex}-header`}
      >
        <span
          aria-hidden="true"
          className={classNames('p2', !disableSort ? 'cursor-pointer' : null)}
          onClick={
            !disableSort ? () => handleColumnSortChange(dbColumnName) : null
          }
        >
          <b>
            <Typography
              id={`col-${columnIndex}`}
              classes={{ root: classes.label }}
            >
              {label}
            </Typography>
          </b>
          {!hideColumnSortDirection &&
          columnSortDirection !== 'none' &&
          columnSortDirection !== null ? (
            <Tooltip title="Toggle sort">
              <Icon
                id={dbColumnName}
                className={classNames(
                  'text-14 ml-px p2',
                  classes.IconMUiTableHead,
                  columnSortDirection === null ? 'hidden' : null,
                )}
                onClick={() => handleColumnSortChange(dbColumnName)}
              >
                {columnSortDirection === SortDirection.ASC
                  ? 'arrow_upward'
                  : 'arrow_downward'}
              </Icon>
            </Tooltip>
          ) : null}
        </span>
      </div>
    </>
  );
}

RVHeader.defaultProps = {
  disableSort: false,
  disableFilter: true,
  label: '',
  onSort: () => {},
  forSelectGrid: false, // If True, bgColor for Header will be one color only
};

RVHeader.propTypes = {
  defaultSort: PropTypes.array.isRequired,
  setDataGridState: PropTypes.func.isRequired,
  setHasSort: PropTypes.func,
};

export default React.memo(RVHeader);
