const commonColProps = { disableSort: true, resizable: true };
export default [
  {
    index: 0,
    name: 'viewPdf',
    label: 'View',
    width: '90',
    ...commonColProps,
  },
  {
    index: 1,
    name: 'submissionDate',
    label: 'Date Submitted',
    width: '170',
    ...commonColProps,
  },
  {
    index: 2,
    name: 'submittedBy',
    label: 'Submitted By',
    width: '200',
    ...commonColProps,
  },
  {
    index: 3,
    name: 'consentFor',
    label: 'Consent For',
    width: '200',
    ...commonColProps,
  },
  {
    index: 4,
    name: 'consentDecision',
    label: 'Consent Decision',
    width: '220',
    ...commonColProps,
  },
  {
    index: 5,
    name: 'schoolName',
    label: 'School Name',
    width: '150',
    ...commonColProps,
  },
  {
    index: 6,
    name: 'schoolGrade',
    label: 'Grade',
    width: '150',
    ...commonColProps,
  },
];
