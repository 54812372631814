/* eslint-disable import/no-named-as-default */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-undef */
/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';
import { pick, find, includes, toInteger, toUpper } from 'lodash';
import PageLayout from 'components/page-layout/PageLayout';
import { makeStyles, Box, Typography, Link } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import { useTheme } from '@material-ui/core/styles';
import CustomGrid from 'components/react-virtualized-grid/';
import RVHeader from 'components/react-virtualized-grid/components/RVHeader';
import RVHeaderCell from 'components/react-virtualized-grid/components/RVHeaderCell';
import RVBodyCell from 'components/react-virtualized-grid/components/RVBodyCell';
import CustomBackdrop from 'components/backdrop';
import { LanguageContext } from 'services/localization/LanguageContext';
import AzureOperation from 'services/common';
import openPDF from 'utility/fileUtility';
import { LANG } from 'utility/languageUtility';
import CustomDialog from 'components/dialog/CustomDialog';
import MESSAGE_TYPE from 'components/dialog/constants';
import PageLoading from 'components/page-loading/PageLoading';
import getPageTitle from 'utility/pageTitle';
import NoData from './NoData';
import { mapData } from './helpers';
import columns from './columns';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    // maxHeight: '400px',
  },
  link: {
    textDecoration: 'underline',
  },
  visuallyHidden: {
    position: 'absolute',
    overflow: 'hidden',
    clip: 'rect(0 0 0 0)',
    height: '1px',
    width: '1px',
    margin: '-1px',
    padding: 0,
    border: 0,
  },
}));

const ConsentList = (props) => {
  const { width } = props;
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef();
  const pageTitleRef = useRef();
  const containerRef = useRef();
  const WAIT_TIME = 2;
  const [time, setTime] = useState(0);
  const [modal, setModal] = useState({
    open: false,
    message: '',
  });
  const [dataLoadFailed, setDataLoadFailed] = useState(null);
  const [pdfReqFailed, setPdfReqFailed] = useState(null);
  const [pdf, setPdf] = useState(null);
  const { translate, lang } = useContext(LanguageContext);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [data, setData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [lastGridScrollTop, setLastGridScrollTop] = useState(0);
  const [lastGridScrollLeft, setLastGridScrollLeft] = useState(0);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const tempPKColumn = 'id';
  const [columnHeaderWidths, setColumnHeaderWidths] = useState(columns);
  const [mouseDown, setMouseDown] = useState(false);
  const [scrollTo, setScrollTo] = useState({ column: null, row: null });

  useEffect(() => {
    document.title = getPageTitle(translate('layout.nav.consentList'), lang);
  }, [translate, lang]);

  const handleCloseModal = () => {
    setModal({ open: false });
  };

  const appBarHeight = 80;
  const footerHeight = 40;
  const mainPageLayoutTopPadding = !includes(['xs', 'sm'], width) ? 16 : 0;
  const mainPageLayoutBottomPadding = !includes(['xs', 'sm'], width) ? 16 : 0;
  const consentListMainBoxTopPadding = 24;
  const consentListMainBoxBottomPadding = 24;
  const pageTitleBottomPadding = 16;
  const gridHeaderHeight = 40;
  let gridBodyHeight = 0;

  const pageTitleHeight =
    (pageTitleRef &&
      pageTitleRef.current &&
      pageTitleRef.current.clientHeight) ||
    0;

  if (pageTitleHeight) {
    gridBodyHeight =
      window.innerHeight -
      pageTitleHeight -
      gridHeaderHeight -
      mainPageLayoutTopPadding -
      consentListMainBoxTopPadding -
      pageTitleBottomPadding -
      consentListMainBoxBottomPadding -
      mainPageLayoutBottomPadding;

    if (includes(['xs', 'sm', 'md'], width)) {
      gridBodyHeight = gridBodyHeight - appBarHeight - footerHeight;
    }
  }

  const errorModal = {
    open: true,
    messageType: MESSAGE_TYPE.error,
    okText: translate('pages.request.ok'),
    onOkay: () => handleCloseModal(),
    message: translate('pages.request.PDF.error'),
  };

  const startTimer = () => {
    timer.current = setInterval(() => {
      setTime((_time) => _time + 1);
    }, 1000);
  };

  const loadData = useCallback(() => {
    const { protectedResources, callApiWithAzureToken } = AzureOperation;
    const { baseUrl, method } =
      protectedResources.consentList.endpoints.getList;
    const params = {
      url: baseUrl,
      method,
    };

    setShowLoader(true);
    startTimer();
    callApiWithAzureToken(params)
      .then((result) => {
        if (result && (result.statusCode === 0 || result.statusCode === 200)) {
          setData(mapData(result.econsentSummary));
          setDataLoadFailed(false);
        } else {
          setDataLoadFailed(true);
        }
      })
      .catch(() => {
        setDataLoadFailed(true);
      })
      .finally(() => {});
  }, [errorModal, translate]);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    return () => {
      clearInterval(timer.current);
      timer.current = 0;
      setTime(0);
    };
  }, []);

  useEffect(() => {
    if (pdf !== null || pdfReqFailed === true) {
      if (time >= WAIT_TIME) {
        // Close the backdrop if the submission was successful
        // Backdrop must show atleast 3 seconds before it closes.
        clearInterval(timer.current);
        setTime(0);
        setOpenBackdrop(false);
        setPdfReqFailed(null);
        if (pdf !== null) {
          openPDF(pdf);
          setPdf(null);
        } else {
          setModal(errorModal);
        }
      }
    }
  }, [errorModal, time, pdf, pdfReqFailed]);

  useEffect(() => {
    if (dataLoadFailed !== null) {
      if (time >= WAIT_TIME) {
        // Close the backdrop if the submission was successful
        // Backdrop must show atleast 3 seconds before it closes.
        clearInterval(timer.current);
        setTime(0);
        setShowLoader(false);
        setDataLoadFailed(null);
        if (dataLoadFailed) {
          setModal({
            ...errorModal,
            onOkay: () => history.push('/'),
            onClose: () => history.push('/'),
            message: translate('pages.consentList.apiGetError'),
          });
        }
      }
    }
  }, [errorModal, time, dataLoadFailed]);

  const renderHeaderCellContent = (column) => {
    if (column.columnIndex === undefined) return null;
    return (
      <RVHeader
        dataKey={column.name}
        dbColumnName={column.dbColumnNm}
        label={translate(`pages.consentList.fields.${column.name}`)}
        disableFilter={column.disableFilter}
        disableSort={column.disableSort}
        filterType={column.filterType}
        filterOptionsUrl={column.filterOptionsUrl}
        filterOptionsData={column.filterOptionsData}
        filterDefaultValue={column.filterDefaultValue}
        // onSort={this.onSort}
        tempSortColumns={['tempId', 'sn']} // cols to be removed on sorting
        columnIndex={column.columnIndex}
        disableResize={column.disableResize}
        hasFilter={column.hasFilter}
        {...pick(props, [
          'columnHeaderWidths',
          'setColumnHeaderWidths',
          'dataGrid',
          'setDataGridState',
          'setHasSort',
          'defaultSort',
          'columnFilters',
          'setColumnFilters',
        ])}
      />
    );
  };

  const onViewPDF = (rowData) => {
    setOpenBackdrop(true);
    const { protectedResources, callApiWithAzureToken } = AzureOperation;
    const { baseUrl, method } = protectedResources.PDF.endpoints.covid19;
    const params = {
      url: `${baseUrl}/${lang}/${rowData.econsentId}`,
      method,
    };

    startTimer();
    callApiWithAzureToken(params)
      .then((result) => {
        if (result.pdf !== null) setPdf(result.pdf);
        else setPdfReqFailed(true);
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        setPdfReqFailed(true);
      });
  };

  const renderBodyCellContent = (column, rowData) => {
    switch (column.name) {
      case 'schoolName': {
        let schoolNameField = 'schoolNameEn';
        if (lang === LANG.FR) {
          schoolNameField = 'schoolNameFr';
        }

        return (
          <>
            <span aria-hidden="true">{rowData[schoolNameField]}</span>
            <span className={classes.visuallyHidden}>
              {`${column.label}: ${rowData[schoolNameField]}
            }`}
            </span>
          </>
        );
      }
      case 'viewPdf': {
        return (
          <Link
            classes={{ root: classes.link }}
            component="button"
            variant="body2"
            onClick={() => onViewPDF(rowData)}
          >
            <span aria-hidden="true">PDF</span>
            <span className={classes.visuallyHidden}>
              {`Generate PDF button for client: ${rowData.consentFor}`}
            </span>
          </Link>
        );
      }
      case 'schoolGrade': {
        let schoolGradeData = rowData.schoolGrade;
        if (toUpper(rowData.schoolGrade) === 'K')
          schoolGradeData = 'Kindergarten';

        return (
          <>
            <span aria-hidden="true">{schoolGradeData}</span>
            <span className={classes.visuallyHidden}>
              {`${column.label}: ${schoolGradeData}
            }`}
            </span>
          </>
        );
      }
      default: {
        return (
          <>
            <span aria-hidden="true">{rowData[column.name]}</span>
            <span className={classes.visuallyHidden}>
              {`${column.label}: ${rowData[column.name]}
              }`}
            </span>
          </>
        );
      }
    }
  };

  const headerRenderer = ({ columnIndex, key, style }) => {
    const column = columns[columnIndex] ? columns[columnIndex] : null;
    const columnName = columns[columnIndex] ? columns[columnIndex].name : null;
    return (
      <RVHeaderCell
        key={key}
        _key={key}
        style={{
          ...style,
        }}
        role="columnheader"
      >
        {renderHeaderCellContent({
          ...column,
          columnName,
          columnIndex,
        })}
      </RVHeaderCell>
    );
  };
  const handleFocus = (rowIndex, columnIndex) => {
    if (
      toInteger(columnIndex) === 0 ||
      (toInteger(columnIndex) !== 0 &&
        scrollTo &&
        scrollTo.column === columns.length)
    ) {
      setScrollTo({
        ...scrollTo,
        column: 0,
        row:
          rowIndex === null || rowIndex === ''
            ? toInteger(scrollTo.row) + 1
            : rowIndex,
      });
    } else {
      const newScrollToColumn =
        toInteger(columnIndex) + 1 === columns.length
          ? toInteger(columnIndex)
          : toInteger(columnIndex) + 1;

      setScrollTo({
        column:
          toInteger(columnIndex) === columns.length ? null : newScrollToColumn,
        row: rowIndex,
      });
    }
  };

  const onKeyUp = (e) => {
    const { id } = e.target;
    const currentRowIndex = toInteger(id.split('-')[0]);
    const currentColumnIndex = toInteger(id.split('-')[1]);
    switch (toUpper(e.key)) {
      case 'ARROWLEFT': {
        if (currentColumnIndex > 0) {
          setTimeout(() => {
            document
              .getElementById(`${currentRowIndex}-${currentColumnIndex - 1}`)
              .focus();
          }, 100);
        }
        break;
      }
      case 'ARROWRIGHT': {
        if (currentColumnIndex < columns.length - 1) {
          document
            .getElementById(`${currentRowIndex}-${currentColumnIndex + 1}`)
            .focus();
        }
        break;
      }
      case 'ARROWUP': {
        if (currentRowIndex > 0) {
          document
            .getElementById(`${currentRowIndex - 1}-${currentColumnIndex}`)
            .focus();
        }
        break;
      }
      case 'ARROWDOWN': {
        if (currentRowIndex + 1 < data.length) {
          document
            .getElementById(`${currentRowIndex + 1}-${currentColumnIndex}`)
            .focus();
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  const cellRenderer = ({ columnIndex, key, rowIndex, style }) => {
    const rowData = data[rowIndex];
    const findColumn = find(columns, (o) => {
      return o.index === columnIndex;
    });

    if (findColumn !== undefined) {
      const column = findColumn;

      if (rowData === undefined) return null;

      if (column.hideOnReadOnly) return null;

      let isHoveredRow = false;
      if (hoveredRow && hoveredRow[tempPKColumn] === rowData[tempPKColumn])
        isHoveredRow = true;

      const selectedRowProps = {
        isSelected: rowData.isClicked,
        isHovered: isHoveredRow,
        column,
        rowData,
        onMouseEnter: (e, _column, _rowData) => {
          setHoveredRow(_rowData);
        },
      };

      return (
        <RVBodyCell
          key={key}
          tabIndex={columnIndex === 0 ? '-1' : '0'}
          _key={key}
          style={{
            ...style,
          }}
          rowIndex={rowIndex}
          column={column}
          onFocus={(event) => {
            if (mouseDown) event.target.blur();
            else
              handleFocus(
                event.target.id.split('-')[0],
                event.target.id.split('-')[1],
              );
          }}
          // Outline focus is for keyboard-only users
          onMouseUp={() => setMouseDown(false)}
          onMouseDown={() => setMouseDown(true)}
          onKeyUp={onKeyUp}
          {...selectedRowProps}
        >
          {renderBodyCellContent(column, rowData, rowIndex, rowData.isClicked)}
        </RVBodyCell>
      );
    }
  };

  const getRowHeight = () => {
    return 60;
  };

  const onScroll = ({ scrollLeft, scrollTop }) => {
    setLastGridScrollTop(scrollTop);
    setLastGridScrollLeft(scrollLeft);
  };

  return (
    <PageLayout
      showNavigation
      showDrawer
      // translate('layout.nav.consentList'
    >
      <CustomBackdrop
        open={openBackdrop}
        text={translate('pages.request.PDF.wait')}
      />
      <CustomDialog
        title={modal.message}
        open={modal.open || false}
        messageType={modal.messageType}
        content={modal.content}
        okText={modal.okText}
        cancelText={modal.cancelText}
        cancelButton={modal.cancelButton}
        onCancel={handleCloseModal}
        onOkay={modal.onOkay}
        onClose={modal.onClose || handleCloseModal}
      />
      <Box ref={containerRef} classes={{ root: classes.container }}>
        <Typography
          ref={pageTitleRef}
          variant="h6"
          component="h1"
          style={{ marginBottom: theme.spacing(2) }}
        >
          <b>{translate('layout.nav.consentList')}</b>
        </Typography>
        {showLoader && <PageLoading transparentBg />}
        {!showLoader && data && data.length > 0 && (
          <CustomGrid
            title={translate('layout.nav.consentList')}
            data={data}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            columnHeaderWidths={columnHeaderWidths}
            setColumnHeaderWidths={setColumnHeaderWidths}
            headerRenderer={headerRenderer}
            cellRenderer={cellRenderer}
            getRowHeight={getRowHeight}
            scrollTop={lastGridScrollTop}
            scrollLeft={lastGridScrollLeft}
            onScroll={onScroll}
            bodyHeight={gridBodyHeight}
            overscanRowCount={5}
            overscanColumnCount={10}
            scrollToRow={scrollTo.row}
            scrollToColumn={scrollTo.column}
          />
        )}

        {data && data.length === 0 && !showLoader && <NoData />}
      </Box>
    </PageLayout>
  );
};

export default withWidth()(ConsentList);
