import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, CssBaseline, List, Drawer, Link } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { LanguageContext } from 'services/localization/LanguageContext';
import { LoginContext } from 'services/login/LoginContext';
import RouterRole from 'constants/routerRole';
import useStore from 'services/store';
import NavigationBarItem from './NavigationBarItem';
import { getCommon } from '../../commons/index';
import NavBarLogo from './NavBarLogo';
import FooterContainer from '../footer/PageFooter';
import NavigationBarItemGroup from './NavigationBarItemGroup';

const useStyles = makeStyles((theme) => {
  return {
    drawer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    drawerHeader: {
      minHeight: theme.Header.height,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    drawerItems: {
      display: 'flex',
      flexDirection: 'column',
      margin: '0px 3px',
    },
    listContainer: {
      paddingTop: 0,
      display: 'flex',
      flexDirection: 'column',
    },
    infoTextContainer: {
      marginTop: 'auto',
      padding: '16px',
    },
    infoText: {
      fontSize: '0.85em',
    },
    drawerPaper: {
      backgroundColor: theme.palette.primary.main,
      width: '100%',
      [theme.breakpoints.only('xs')]: {
        width: '90%',
      },
      [theme.breakpoints.up(theme.navBarBreakpoint.hideDrawerName)]: {
        position: 'relative',
      },
    },
    navContainer: {
      [theme.breakpoints.only('xs')]: {
        width: '90%',
      },
      [theme.breakpoints.only('sm')]: {
        width: '50%',
      },
      [theme.breakpoints.up('md')]: {
        width: '30%',
      },
      [theme.breakpoints.up('lg')]: {
        width: '25%',
      },
      position: 'sticky',
      top: '0',
      left: '0',
    },
    headerContainer: {
      width: '100%',
      maxHeight: theme.Header.height,
      minHeight: theme.Header.height,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: 0,
      padding: 0,
    },
    languageLink: {
      display: 'block',
      textDecortion: 'underline',
      marginTop: theme.spacing(1),
      color: 'white',
    },
  };
});

const NavigationDrawer = ({ isOpen, onClose, variant }) => {
  const { getGroup } = getCommon();
  const menuSections = [getGroup(1), getGroup(2), getGroup(3), getGroup(4)];
  const loginContext = useContext(LoginContext);
  const { translate, toggleLang } = useContext(LanguageContext);
  const parentMenu = useStore((state) => state.parentMenu);
  const classes = useStyles();
  const theme = useTheme();

  const getMenuSection = (menuItems) =>
    menuItems
      .map((item) => {
        if (item.subMenu) {
          if (
            (item.role === RouterRole.PUBLIC && loginContext) ||
            (item.role === RouterRole.PROTECTED && !loginContext)
          ) {
            return null;
          }
          // if (item.name === 'covidPublic') return null;
          return (
            <NavigationBarItemGroup
              name={item.name}
              key={item.showName}
              Icon={item.icon}
              subMenu={item.subMenu}
              text={item.showName}
              onClick={onClose}
              role={item.role}
            />
          );
        }
        return (
          <NavigationBarItem
            key={item.showName}
            Icon={item.icon}
            text={item.showName}
            linkTo={item.url}
            onClick={onClose}
            newPage={Boolean(item.newPage)}
            role={item.role}
          />
        );
      })
      // Removing nulls
      .filter((item) => item);

  const menuJsx = menuSections.map((menuItems) => {
    const menuSection = getMenuSection(menuItems);
    if (!menuSection || menuSection.length === 0) {
      return null;
    }
    return (
      <Fragment key={menuItems[0].showName}>
        {menuSection !== null && (
          <List
            role="menuitem"
            {...(menuItems[0].subMenu
              ? {
                  'aria-expanded': parentMenu[menuItems[0].name]
                    ? 'true'
                    : 'false',
                }
              : null)}
            classes={{ root: classes.listContainer }}
          >
            {menuSection}
          </List>
        )}
      </Fragment>
    );
  });

  const drawer = (
    <div className={classes.drawer}>
      <div className={classes.drawerHeader}>
        <div className={classes.headerContainer}>
          <NavBarLogo isWhiteLogo center />
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            component="button"
            className={classes.languageLink}
            onClick={toggleLang}
          >
            {translate('layout.otherLanguage')}
          </Link>
        </div>
      </div>
      <div className={classes.drawerItems}>
        <div>{menuJsx}</div>
      </div>

      <FooterContainer />
    </div>
  );

  return (
    <>
      <CssBaseline />
      <Drawer
        variant={variant}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={isOpen}
        onClose={onClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        classes={{
          paper: classes.drawerPaper,
          root: classes.navContainer,
        }}
      >
        {drawer}
      </Drawer>
    </>
  );
};

export default NavigationDrawer;
NavigationDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,

  variant: PropTypes.string,
};
NavigationDrawer.defaultProps = {
  variant: 'temporary',
  onClose: null,
};
