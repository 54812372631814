import React, { useContext } from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import mbLogo from 'img/mb-logo-white.png';
import mbLogoGreen from 'img/mb-logo.png';
import { LanguageContext } from 'services/localization/LanguageContext';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  paper: {
    textAlign: (props) => (props.center ? 'center' : 'left'),
  },
  title: {
    paddingTop: 2,
    fontSize: 14,
    color: 'white',
  },
  imgLogo: {
    cursor: (props) => (props.clickable ? 'pointer' : 'default'),
  },
}));

const NavBarLogo = ({ isWhiteLogo, center, clickable }) => {
  const classes = useStyles({ center, clickable });
  const history = useHistory();
  const { translate } = useContext(LanguageContext);

  const handleOnClick = () => history.push('/');

  return (
    <Box classes={{ root: classes.paper }}>
      {isWhiteLogo ? (
        <img
          src={mbLogo}
          alt="Manitoba Government Logo"
          width={135}
          onClick={handleOnClick}
          aria-hidden="true"
          className={classes.imgLogo}
        />
      ) : (
        <img
          src={mbLogoGreen}
          alt="Manitoba Government Logo"
          width={135}
          onClick={handleOnClick}
          aria-hidden="true"
          className={classes.imgLogo}
        />
      )}

      <Typography className={classes.title} aria-hidden="true">
        {translate('layout.logoText')}
      </Typography>
    </Box>
  );
};

export default NavBarLogo;
NavBarLogo.propTypes = {
  isWhiteLogo: PropTypes.bool,
  center: PropTypes.bool,
  clickable: PropTypes.bool,
};
NavBarLogo.defaultProps = {
  isWhiteLogo: false,
  center: false,
  clickable: true,
};
