import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';
import { LanguageContext } from 'services/localization/LanguageContext';
import PageLayout from 'components/page-layout/PageLayout';
import { includes } from 'lodash';
import getPageTitle from 'utility/pageTitle';
import FAQSections from '../../components/faq/FAQSections';

const FAQProxyPage = ({ width }) => {
  const { getTranslationObj, translate, lang } = useContext(LanguageContext);

  useEffect(() => {
    document.title = getPageTitle(translate('FAQ.title'), lang);
  }, [translate, lang]);

  const questionArray = getTranslationObj('FAQ.faqArray');
  const maxWidth = includes(['xs', 'sm', 'md'], width) ? '100%' : '60%';
  return (
    <>
      <PageLayout
        showNavigation
        showDrawer={false}
        alwaysShowLanguageToggle
        forceShowFooter
        forceShowAppBar
        maxWidth={maxWidth}
      >
        <FAQSections faqArray={questionArray} title={translate('FAQ.title')} />
      </PageLayout>
    </>
  );
};

FAQProxyPage.defaultProps = {
  width: null,
};

FAQProxyPage.propTypes = {
  width: PropTypes.string,
};

export default withWidth()(FAQProxyPage);
