const PROVINCE = {
  MB: 'MB',
  AB: 'AB',
  BC: 'BC',
  NB: 'NB',
  NL: 'NL',
  NT: 'NT',
  NS: 'NS',
  NU: 'NU',
  ON: 'ON',
  PE: 'PE',
  QC: 'QC',
  SK: 'SK',
  YT: 'YT',
  NA: `N/A`,
};

export const CONSENT_PROVIDER = {
  LEGAL_DECISION_MAKER: 'LEGAL_DECISION_MAKER',
  CLIENT: 'CLIENT',
};

export const FORM_MAKER = {
  LEGAL_DECISION_MAKER: 'LEGAL_DECISION_MAKER',
  CLIENT: 'CLIENT',
  PARENT: 'PARENT',
  OTHER: 'OTHER',
};

export default PROVINCE;
