import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, Typography } from '@material-ui/core';
import { LanguageContext } from 'services/localization/LanguageContext';
import CustomizedButton from 'components/button/CustomizedButton';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bodyContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    marginTop: theme.spacing(5),
  },
}));

const NoData = () => {
  const classes = useStyles();
  const history = useHistory();
  const { translate } = useContext(LanguageContext);

  return (
    <div className={classes.bodyContainer}>
      <Typography role="alert" aria-live="polite">
        {translate('pages.consentList.noData')}
      </Typography>
      <CustomizedButton
        aria-label="submit"
        variant="contained"
        color="primary"
        type="submit"
        className={classes.button}
        onClick={() => history.push('/consent-form')}
      >
        {translate('pages.consentList.addConsent')}
      </CustomizedButton>
    </div>
  );
};

export default NoData;
