import { mapKeys, includes } from 'lodash';

const isFormDirty = (consentFormState) => {
  const dirtyFields = [];
  mapKeys(consentFormState, (value, key) => {
    if (
      !includes(
        [
          'date',
          'informedConsentDateD1',
          'informedConsentDateD2',
          'contactInformationConsentDate',
        ],
        key,
      )
    ) {
      if (value.value !== '' && value.value !== undefined)
        dirtyFields.push(key);
    }
  });

  if (dirtyFields.length > 0) return true;

  return false;
};

export default isFormDirty;
