/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';

function RVBodyCell(props) {
  const {
    columnIndex,
    contextMenu,
    _key,
    parent,
    rowIndex,
    style,
    children,
    active,
    customColor, // font color
    isSelected,
    isHovered,
    column,
    rowData,
    onClick,
    onRightClick,
    onDoubleClick,
    onMouseEnter,
    ...rest
  } = props;
  const theme = useTheme();
  const borderColor = theme.palette.divider;
  // const { divider } = theme.palette;
  const defaultBgColor = 'transparent';
  const bodyCell = React.useRef();

  function handleOnClick(e) {
    e.preventDefault();
    if (e.type === 'click') {
      if (onClick) onClick(e, column, rowData);
    }
  }

  function handleOnDoubleClick(e) {
    if (onDoubleClick) onDoubleClick(e, rowIndex, column, rowData);
  }

  const isEven = (_rowIndex) => {
    return _rowIndex % 2 === 0;
  };

  const getBgColor = () => {
    let bgColor = defaultBgColor;
    if (!isEven(rowIndex)) {
      bgColor = 'transparent';
      // bgColor = divider;
    }

    if (isHovered) bgColor = theme.palette.action.hover;

    if (isSelected) bgColor = theme.palette.action.selected;

    return bgColor;
  };

  const getColor = () => {
    if (customColor) return { color: customColor };

    return null;
  };

  return (
    <>
      <div
        {...rest}
        active={active ? 1 : 0}
        id={`${_key}`}
        key={_key}
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          borderBottom: `1px solid ${borderColor}`,
          ...(column &&
          column.dataType === 'number' &&
          (!column.textAlign || column.textAlign !== 'left')
            ? { textAlign: 'right' }
            : null),
          backgroundColor: getBgColor(),
          ...getColor(),
          ...(onClick || onDoubleClick ? { cursor: 'pointer' } : null),
          ...style,
        }}
        {...(onClick ? { onClick: handleOnClick } : null)}
        {...(onDoubleClick ? { onDoubleClick: handleOnDoubleClick } : null)}
        // onMouseEnter={handleMouseEnter}
        // onContextMenu={handlePopoverOpen}
        ref={bodyCell}
      >
        <div
          style={{
            whiteSpace: 'nowrap',
            paddingLeft: '16px',
            paddingRight: '16px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            ...(column &&
            column.dataType === 'number' &&
            (!column.textAlign || column.textAlign !== 'left')
              ? { textAlign: 'right' }
              : null),
            backgroundColor: getBgColor(),
            ...getColor(),
            ...(onClick || onDoubleClick ? { cursor: 'pointer' } : null),
          }}
        >
          {children}
        </div>
      </div>
    </>
  );
}

RVBodyCell.defaultProps = {
  active: true,
  rowData: null,
  column: null,
};

RVBodyCell.propTypes = {
  _key: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
  active: PropTypes.bool,
};

export default React.memo(RVBodyCell);
