/* eslint-disable react/forbid-prop-types */
import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  makeStyles,
  FormHelperText,
} from '@material-ui/core';
import { LanguageContext } from 'services/localization/LanguageContext';
// eslint-disable-next-line import/no-cycle
import FormInput from '../FormInput';

const useStyles = makeStyles((theme) => ({
  formLabel: {
    color: 'unset',
    lineHeight: 1.5,
  },
  selectedRadio: {
    color: `${theme.palette.primary.dark}!important`,
  },
  radioGroup: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%',
  },
  radioGroupItemContainer: {
    marginLeft: (props) =>
      props.indentOptions ? `${theme.spacing(2)}px` : '0px',
  },
  visuallyHidden: {
    position: 'absolute',
    overflow: 'hidden',
    clip: 'rect(0 0 0 0)',
    height: '1px',
    width: '1px',
    margin: '-1px',
    padding: 0,
    border: 0,
  },
}));

const RadioGroupCustom = ({
  label,
  ariaLabel,
  name,
  value,
  required,
  options,
  onChange,
  onBlur,
  error,
  errorAria,
  formState,
  fullWidth,
  disabled,
  indentOptions,
  focusEl,
}) => {
  const classes = useStyles({ indentOptions });
  const { translate } = useContext(LanguageContext);
  const ref = useRef(null);

  useEffect(() => {
    if (ref && ref.current && name === focusEl) {
      ref.current.focus();
    }
  }, [name, focusEl]);

  const radioOptions = options.map((option) => {
    const inputs = [
      <FormControlLabel
        key={option.name}
        value={option.value}
        disabled={disabled}
        control={
          <Radio
            id={`${name}-${option.value}`}
            classes={{
              root: value === option.value ? classes.selectedRadio : null,
            }}
            inputProps={{
              'aria-label': translate(option.ariaLabel) || option.value,
              'aria-labelledby': `${name}-${option.value}`,
            }}
          />
        }
        label={translate(option.label)}
        required={required}
        error={Boolean(error)}
      />,
    ];
    if (option.render) {
      inputs.push(
        <FormInput
          key={option.render.name}
          formState={formState}
          input={{
            ...option.render,
            type: 'text',
            indent: option.render.indent,
          }}
          onChange={(inputName, inputValue) => {
            onChange(inputName, inputValue);
          }}
          onBlur={(inputName, inputValue) => {
            onBlur(inputName, inputValue);
          }}
        />,
      );
    }

    return inputs;
  });

  const handleOnChange = (e) => {
    if (onChange) onChange(name, e.target.value);
  };

  return (
    <FormControl
      className={classes.radioGroup}
      disabled={disabled}
      error={Boolean(error)}
      fullWidth={fullWidth}
      tabIndex="-1"
    >
      <FormLabel
        id={`${name}`}
        color="primary"
        error={Boolean(error)}
        disabled={disabled}
        classes={{ root: classes.formLabel }}
        {...(!disabled && { tabIndex: '0' })}
        ref={ref}
      >
        <span aria-hidden="true">{`${required ? '* ' : ''}${translate(label)}
        `}</span>
        <span className={classes.visuallyHidden}>
          {`${
            required ? translate('pages.consentForm.requiredFieldAria') : ''
          }${translate(ariaLabel || label)}${
            Boolean(error) === true
              ? `. (${translate(errorAria || error)})`
              : ''
          }
          }`}
        </span>
      </FormLabel>
      {error !== null && error !== '' ? (
        <FormHelperText id={`${name}-helper-text`}>
          {translate(error)}
        </FormHelperText>
      ) : null}
      <RadioGroup
        classes={{ root: classes.radioGroupItemContainer }}
        name={name}
        value={value || ''}
        onChange={handleOnChange}
      >
        {radioOptions}
      </RadioGroup>
    </FormControl>
  );
};

RadioGroupCustom.defaultProps = {
  required: false,
  error: null,
  errorAria: null,
  ariaLabel: undefined,
  formState: {},
  fullWidth: false,
  disabled: false,
  onBlur: () => {},
  indentOptions: false,
  focusEl: undefined,
};

RadioGroupCustom.propTypes = {
  label: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.string,
      PropTypes.shape({ label: PropTypes.string }),
    ),
  ]),
  errorAria: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.string,
      PropTypes.shape({ label: PropTypes.string }),
    ),
  ]),
  formState: PropTypes.object,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  indentOptions: PropTypes.bool,
  focusEl: PropTypes.string,
};

export default RadioGroupCustom;
