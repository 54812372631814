import React from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  customButton: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));

const CustomizedButton = (props) => {
  const { children, className } = props;
  const classes = useStyles();
  const buttonStyles = className
    ? [classes.customButton, className].join(' ')
    : classes.customButton;
  return (
    <Button {...props} className={buttonStyles}>
      {children}
    </Button>
  );
};

CustomizedButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

CustomizedButton.defaultProps = {
  className: '',
  children: '',
};

export default CustomizedButton;
