import React from 'react';
import { Link } from '@material-ui/core';

export default {
  wpgPhone: (
    <Link key="wpgPhone" href="tel:204-786-7101">
      204-786-7101
    </Link>
  ),
  tollFreePhone: (
    <Link key="tollFree" href="tel:1-800-392-1207">
      1-800-392-1207
    </Link>
  ),
  deafAccessPhone: (
    <Link key="deafAccess" href="tel:204-774-8618">
      204-774-8618
    </Link>
  ),
  seniorCarePhone: (
    <Link
      style={{ whiteSpace: 'nowrap' }}
      key="seniorCare"
      href="tel:1-431-334-9445"
    >
      1-431-334-9445
    </Link>
  ),
  mbHealthCardLink: (
    <Link
      key="mbHealthCardLink"
      target="_blank"
      href="https://www.gov.mb.ca/health/mhsip/mbcard.html"
    >
      https://www.gov.mb.ca/health/mhsip/mbcard.html
    </Link>
  ),
  mbHealthCardLinkFr: (
    <Link
      key="mbHealthCardLinkFr"
      target="_blank"
      href="https://www.gov.mb.ca/health/mhsip/mbcard.fr.html"
    >
      https://www.gov.mb.ca/health/mhsip/mbcard.fr.html
    </Link>
  ),
  mbHealthEmail: (
    <Link key="mbHealthEmail" href="mailto:insuredben@gov.mb.ca">
      Insuredben@gov.mb.ca
    </Link>
  ),
  contactEmail: (
    <Link
      key="contactEmail"
      href="mailto:ImmunizationCardSupport@gov.mb.ca"
      underline="always"
      style={{ wordWrap: 'break-word' }}
    >
      immunizationcardsupport@gov.mb.ca
    </Link>
  ),
  healthndSeniorsCareWebsite: (
    <Link
      key="healthndSeniorsCareWebsite"
      target="_blank"
      href="https://forms.gov.mb.ca/notice-of-change/index.html"
    >
      https://forms.gov.mb.ca/notice-of-change/index.html
    </Link>
  ),
  consentGuidelinesLink: (
    <Link
      key="consentGuidelinesLink"
      target="_blank"
      href="www.manitoba.ca/health/publichealth/cdc/protocol/consentguidelines.pdf."
    >
      www.manitoba.ca/health/publichealth/cdc/protocol/consentguidelines.pdf.
    </Link>
  ),
  covidVaccineResourcesLink: (
    <Link
      key="covidVaccineResourcesLink"
      target="_blank"
      href="https://www.gov.mb.ca/covid19/vaccine/resources.html"
    >
      www.gov.mb.ca/covid19/vaccine/resources.html
    </Link>
  ),
  covidVaccineResourcesLinkFr: (
    <Link
      key="covidVaccineResourcesLink"
      target="_blank"
      href="https://www.gov.mb.ca/covid19/vaccine/resources.fr.html"
    >
      www.gov.mb.ca/covid19/vaccine/resources.fr.html
    </Link>
  ),
  publicHealthOfficeWebsite: (
    <Link
      key="publicHealthOfficeWebsite"
      target="_blank"
      href="https://www.manitoba.ca/health/publichealth/offices.html"
    >
      www.manitoba.ca/health/publichealth/offices.html
    </Link>
  ),
  publicHealthOfficeWebsiteFr: (
    <Link
      key="publicHealthOfficeWebsite"
      target="_blank"
      href="https://www.manitoba.ca/health/publichealth/offices.fr.html"
    >
      www.manitoba.ca/health/publichealth/offices.fr.html
    </Link>
  ),
  shareHealthLink: (
    <Link
      key="shareHealthLink"
      target="_blank"
      href="https://sharedhealthmb.ca/covid19/test-results/"
    >
      sharedhealthmb.ca/covid19/test-results/
    </Link>
  ),
  healthndSeniorsCareWebsiteFr: (
    <Link
      key="healthndSeniorsCareWebsiteFr"
      target="_blank"
      href="https://forms.gov.mb.ca/notice-of-change/index.fr.html"
    >
      https://forms.gov.mb.ca/notice-of-change/index.fr.html
    </Link>
  ),
  shareHealthLinkFr: (
    <Link
      key="shareHealthLinkFr"
      target="_blank"
      href="https://covid19.soinscommunsmb.ca/covid19/resultats-test/"
    >
      covid19.soinscommunsmb.ca/covid19/resultats-test/
    </Link>
  ),
  recordLinkEn: (
    <Link
      key="shareHealthLinkEn"
      target="_blank"
      href="https://manitoba.ca/covid19/vaccine/immunization-record.html"
    >
      https://manitoba.ca/covid19/vaccine/immunization-record.html
    </Link>
  ),
  recordLinkFr: (
    <Link
      key="shareHealthLinkFr"
      target="_blank"
      href="https://manitoba.ca/covid19/vaccine/immunization-record.fr.html"
    >
      manitoba.ca/covid19/vaccine/immunization-record.fr.html
    </Link>
  ),
  forResidentsEn: (
    <Link
      key="forResidentsEn"
      target="_blank"
      underline="always"
      href="https://manitoba.ca/covid19/vaccine/immunizationrecord/residents.html"
    >
      Province of Manitoba | For Residents
    </Link>
  ),
  forResidentsFr: (
    <Link
      key="forResidentsFr"
      target="_blank"
      underline="always"
      href="https://manitoba.ca/covid19/vaccine/immunizationrecord/residents.html"
    >
      Province du Manitoba | Résidents
    </Link>
  ),
  recordLinkUnderline: (
    <Link
      key="recordLinkUnderline"
      target="_blank"
      underline="always"
      href="https://manitoba.ca/covid19/vaccine/immunization-record.html"
    >
      https://manitoba.ca/covid19/vaccine/immunization-record.html
    </Link>
  ),
};
