/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lonely-if */
/* eslint-disable max-len */
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useStore from 'services/store';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { LanguageContext } from 'services/localization/LanguageContext';
import { LoginContext } from 'services/login/LoginContext';
import CustomDialog from 'components/dialog/CustomDialog';
import MESSAGE_TYPE from 'components/dialog/constants';
import isFormDirty from 'pages/consent-form/helper';
import useConsentFormStore from 'services/store/form-stores/eConsentFormStore';
import RouterRole from 'constants/routerRole';

const useStyles = makeStyles((theme) => ({
  groupSelected: {
    backgroundColor: '#1B2355',
  },
  listItem: {
    color: 'white',
    position: 'relative',
    paddingLeft: (props) =>
      props.subMenu ? `${props.subMenuLeftPadding}px` : theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    alignItems: 'flex-start',
    width: (props) => (props.subMenu ? `calc(100% - 24px)` : '100%'),
  },
  icon: {
    color: '#cccccc',
    minWidth: '0px',
    marginTop: '4px',
    marginRight: theme.spacing(2),
  },
  buttonLink: {
    border: 'none',
    width: '100%',
    backgroundColor: 'transparent',
    padding: 0,
  },
  listItemText: {
    whiteSpace: 'pre-wrap',
  },
  listItemTextSelectedParent: {
    whiteSpace: 'pre-wrap',
    '&::after': {
      content: '""',
      display: 'block',
      width: '100%',
      height: '1px',
      backgroundColor: '#9B9B9B',
      marginTop: theme.spacing(2),
    },
  },
  itemMenuSelectedBorder: {
    border: '1px solid #9B9B9B',
    width: (props) =>
      `calc(100% - ${props.subMenu ? props.subMenuLeftPadding : 16}px)`,
    height: `calc(100% - ${theme.spacing(2)}px)`,
    position: 'absolute',
    marginLeft: `-${theme.spacing(1)}px`,
    marginBottom: `-${theme.spacing(2)}px`,
  },
  divider: {
    backgroundColor: '#9B9B9B',
  },
}));

const NavigationBarItem = (props) => {
  const {
    text,
    name,
    Icon,
    linkTo,
    onClick,
    newPage,
    endIcon,
    subMenu,
    groupSelected,
    role,
  } = props;
  const location = useLocation();
  const loginContext = useContext(LoginContext);
  const activeMenu = useStore((state) => state.activeMenu);
  const setActiveMenu = useStore((state) => state.setActiveMenu);
  const pathname = location ? location.pathname : '';
  const isMenuItemActive = name === activeMenu;
  const { translate, lang } = useContext(LanguageContext);
  const [modal, setModal] = useState({
    open: false,
    content: '',
  });
  const consentFormState = useConsentFormStore((state) => state.formState);
  const listItemRef = useRef();
  const [listItemHeight, setListItemHeight] = useState('unset');
  const subMenuLeftPadding = 50;
  const classes = useStyles({ subMenu, subMenuLeftPadding, groupSelected });

  useEffect(() => {
    const newListItemHeight =
      listItemRef && listItemRef.current
        ? listItemRef.current.clientHeight + 8
        : 'unset';

    setListItemHeight(newListItemHeight);
  }, [lang]);

  useEffect(() => {
    if (linkTo && linkTo === pathname) {
      setActiveMenu(name);
    }
  }, [activeMenu, name, pathname, linkTo, setActiveMenu]);

  useEffect(() => {
    if (linkTo && linkTo !== pathname && activeMenu !== null) {
      setActiveMenu(null);
    }
  }, [pathname]);

  const logout = () => {
    loginContext.logout(() => {
      // logout will report error when following steps:
      // 1. login 2. open another tab and get into the same page
      // 3. go back to the first page, logout
      // 4. go to the second page, logout
      // 5. error occure.
      // the reason is we use localstorage, when logout first time,
      // the b2c enters redirect-login state
      // if you logout at this time, because the first redirect-login request
      // is still in progress, then the error occurs
      setModal({
        open: true,
        content: translate('pages.consentForm.error'),
      });
    });
  };

  const itemClick = (e, redirectTo) => {
    if (translate(text) === translate('layout.nav.logout')) {
      e.preventDefault();
      if (!isFormDirty(consentFormState)) {
        logout();
      } else {
        setModal({
          open: true,
          content: translate('pages.consentForm.logoutWhenFormIsDirty'),
          onClose: () => setModal({ open: false }),
          onClick: () => {
            setModal({ open: false });
            logout();
          },
          okText: translate('pages.request.yes'),
          cancelText: translate('pages.request.no'),
        });
      }
    }

    if (pathname === redirectTo) {
      e.preventDefault();
    }
    if (onClick && translate(text) !== translate('layout.nav.logout')) {
      onClick(name);
    }
  };

  const target = newPage ? '_blank' : '_self';
  const linkWithLanguage = newPage ? `${linkTo}?lang=${lang}` : linkTo;

  let linkContent = null;
  if (
    (role === RouterRole.PUBLIC && !loginContext) ||
    (role === RouterRole.PROTECTED && loginContext)
  ) {
    linkContent = (
      <div className={groupSelected ? classes.groupSelected : null}>
        <ListItem tabIndex="-1" button classes={{ root: classes.listItem }}>
          <div
            className={isMenuItemActive ? classes.itemMenuSelectedBorder : null}
          />
          <ListItemIcon classes={{ root: classes.icon }}>{Icon}</ListItemIcon>
          <ListItemText
            ref={listItemRef}
            classes={{
              root:
                groupSelected && !subMenu
                  ? classes.listItemTextSelectedParent
                  : classes.listItemText,
            }}
            primary={translate(text)}
          />
          <div
            style={{
              height: listItemHeight,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {endIcon}
          </div>
        </ListItem>
      </div>
    );
  }

  if (linkContent === null) {
    return null;
  }
  const menuLink = linkTo ? (
    <Link
      to={linkWithLanguage}
      onClick={(e) => itemClick(e, linkTo)}
      target={target}
      aria-current={isMenuItemActive}
    >
      {linkContent}
    </Link>
  ) : (
    <button
      type="button"
      className={classes.buttonLink}
      onClick={(e) => itemClick(e, linkTo)}
      aria-current={isMenuItemActive}
    >
      {linkContent}
    </button>
  );

  return (
    <>
      <CustomDialog
        title={modal.content}
        open={modal.open}
        messageType={MESSAGE_TYPE.confirmation}
        onClose={() => setModal({ open: false })}
        onCancel={() => setModal({ open: false })}
        onOkay={modal.onClick}
        okText={modal.okText}
        cancelText={modal.cancelText}
      />
      {menuLink}
    </>
  );
};

NavigationBarItem.propTypes = {
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  Icon: PropTypes.element,
  linkTo: PropTypes.string,
  onClick: PropTypes.func,
  newPage: PropTypes.bool,
  endIcon: PropTypes.element,
  subMenu: PropTypes.bool,
  groupSelected: PropTypes.bool,
  role: PropTypes.string,
};

NavigationBarItem.defaultProps = {
  Icon: null,
  linkTo: null,
  onClick: null,
  newPage: false,
  endIcon: null,
  subMenu: false,
  groupSelected: false,
  role: '',
};

export default NavigationBarItem;
