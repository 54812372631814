/* eslint-disable import/no-unresolved */
import moment from 'moment';
/* eslint-disable import/prefer-default-export */
const toBoolean = (value) => {
  let finalValue = value;
  if (value === 'true') {
    finalValue = true;
  } else if (value === 'false') {
    finalValue = false;
  }
  return finalValue;
};

const getAge = (date) => moment().diff(date, 'years');

const formatDate = (date, format) => moment(date).format(format);

export { toBoolean, getAge, formatDate };
