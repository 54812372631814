// @flow
/*
 * @Description:
 * @Author: Shaomin Fei
 * @Date: 2021-03-28 13:19:42
 */

import {
  msalInstance,
  loginRequest,
  b2cPolicies,
  protectedResources,
} from './azure/AuthConfig';
import callApiWithAzureToken, {
  redirectToLogin,
} from './azure/AzureCallHelper';

const AzureOperation = {
  instance: msalInstance,
  callApiWithAzureToken,
  loginRequest,
  b2cPolicies,
  protectedResources,
  redirectToLogin,
};
export default AzureOperation;
