/*
 * @Description:
 * @Author: Shaomin Fei
 * @Date: 2021-03-30 10:18:55
 */

// the createMuiTheme will cause some warnings,
// according to official doc, use unstable_createMuiStrictModeTheme instead
// or remove strickmode
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme();

const LuminaTheme = createMuiTheme({
  Banner: {
    fontWeight: 'bold',
  },
  Header: {
    height: '80px',
    background: '#1a4e89',
  },
  Footer: {
    height: '40px',
    backgroundColor: '#1B2355',
  },
  palette: {
    background: {
      blue: 'blue',
      default: '#EAF0F0',
      paper: '#FFF',
    },
    borders: 'rgba(0, 0, 0, 0.1)',
    primary: {
      main: '#013E77',
      dark: '#1B2355',
    },
    confirmation: {
      main: '#e8e46c',
    },
  },
  Loading: {
    width: '300px',
    height: '100px',
  },
  typography: {
    fontFamily: [
      'Nunito Sans',
      'Arial',
      '-apple-system',
      'BlinkMacSystemFont',
      'Roboto',
      'sans-serif',
    ].join(','),
    subtitle1: {
      fontSize: '0.74em',
    },
  },
  drawer: {
    width: '25%',
  },
  navBarBreakpoint: {
    // when width<960px, hide the nav bar
    // the follow two params have the same meaning,
    //  just adapte to different usage.
    hideDrawer: { mdDown: true },
    hideDrawerButton: { lgUp: true },
    hideDrawerName: 'sm',
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
    MuiStepIcon: {
      root: {
        '&$completed': {
          color: '#4EB028',
        },
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: '20px',
        padding: theme.spacing(2),
      },
    },
    MuiDialogContent: {
      root: {
        padding: theme.spacing(2),
      },
    },
    MuiDialogActions: {
      root: {
        margin: 0,
        padding: theme.spacing(1),
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '0px',
        display: 'flex',
        height: '36px',
        alignItems: 'center',
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: '0.875rem',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '1rem',
      },
    },
  },
});

export default LuminaTheme;
