/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  makeStyles,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PropTypes from 'prop-types';
import { ReactComponent as SuccessIcon } from 'img/success.svg';
import { ReactComponent as ConfirmationIcon } from 'img/confirmation.svg';
import { ReactComponent as ErrorIcon } from 'img/error.svg';
import WarningOutlined from '@material-ui/icons/WarningOutlined';
import InfoIcon from '@material-ui/icons/Info';
import classnames from 'classnames';
import MESSAGE_TYPE from './constants';

export const useStyles = makeStyles((theme) => {
  return {
    closeButton: {
      color: theme.palette.grey[500],
      marginLeft: 'auto',
      marginRight: '8px',
      marginTop: '8px',
      padding: '0px',
      position: 'absolute',
      top: '0',
      right: '0',
    },
    title: {
      textAlign: 'center',
      marginTop: theme.spacing(2),
      font: ' normal normal bold 18px/21px Nunito Sans',
      color: '#222222',
      opacity: 1,
      // retain \r\n in the string, but merge multi white-space
      whiteSpace: 'pre-line',
      fontWeight: 'normal',
    },
    titleSmall: {
      font: ' normal normal bold 14px/20px Nunito Sans',
    },
    content: {
      textAlign: 'center',
      '@media (max-width:380px)': {
        font: 'normal normal normal 12px/20px Nunito Sans',
      },
      '@media (max-width:330px)': {
        font: 'normal normal normal 11px/20px Nunito Sans',
      },
      font: 'normal normal normal 14px/20px Nunito Sans',
      color: '#222222',
      opacity: 1,
    },
    buttonConfirm: {
      minWidth: '136px',
      minHeight: '30px',
      font: 'normal normal 600 15px/21px Nunito Sans',
      background: `${theme.palette.primary.main} 0% 0% no-repeat padding-box`,
      borderRadius: '3px',
      opacity: 1,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      fontWeight: 'normal',
    },
    okConfirm: {
      background: 'transparent',
    },
    dlgPaper: {
      background: ' #FFFFFF 0% 0% no-repeat padding-box',
      boxShadow: '0px 4px 14px #00000029',
      borderRadius: '6px',
      opacity: 1,
      padding: '0px',
      maxWidth: '440px',
    },
    actionRoot: {
      justifyContent: 'center',
    },
    contentPadding: {
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    contentContainer: {
      '&:first-child': {
        textAlign: 'center',
      },
      paddingTop: '0px!important',
    },
    icon: {
      fontSize: '4em',
      color: (props) => {
        switch (props.messageType) {
          case MESSAGE_TYPE.error:
            return theme.palette.error.main;
          case MESSAGE_TYPE.info:
            return theme.palette.info.main;
          case MESSAGE_TYPE.confirmation: {
            return theme.palette.confirmation.main;
          }
          case MESSAGE_TYPE.warning:
            return theme.palette.warning.main;
          case MESSAGE_TYPE.success:
            return theme.palette.success.main;
          default:
            return theme.palette.text.primary;
        }
      },
    },
  };
});

const DialogAction = ({
  okText,
  okButton,
  handleOnOkay,
  cancelText,
  handleOnCancel,
  messageType,
  setFocus,
  mouseDown,
  setMouseDown,
}) => {
  const classes = useStyles();
  return (
    <DialogActions
      className={classes.actionRoot}
      id="customized-dialog-actions"
    >
      {messageType === MESSAGE_TYPE.confirmation && (
        <Button
          tabIndex="0"
          onClick={handleOnCancel}
          color="primary"
          variant="contained"
          classes={{ root: classes.buttonConfirm }}
          onFocus={(event) => {
            if (mouseDown) event.target.blur();
            else setFocus(true);
          }}
          onBlur={() => {}}
          // Outline focus is for keyboard-only users
          onMouseUp={() => setMouseDown(false)}
          onMouseDown={() => setMouseDown(true)}
        >
          {cancelText}
        </Button>
      )}
      {okButton && (
        <Button
          tabIndex="0"
          onClick={handleOnOkay}
          color="primary"
          variant={
            messageType !== MESSAGE_TYPE.confirmation ? 'contained' : 'outlined'
          }
          classes={{
            root:
              messageType !== MESSAGE_TYPE.confirmation
                ? classes.buttonConfirm
                : classnames(classes.buttonConfirm, classes.okConfirm),
          }}
          onFocus={(event) => {
            if (mouseDown) event.target.blur();
            else setFocus(true);
          }}
          onBlur={() => {}}
          // Outline focus is for keyboard-only users
          onMouseUp={() => setMouseDown(false)}
          onMouseDown={() => setMouseDown(true)}
        >
          {okText}
        </Button>
      )}
    </DialogActions>
  );
};

const MessageIcon = ({ messageType }) => {
  const classes = useStyles({ messageType });
  switch (messageType) {
    case MESSAGE_TYPE.confirmation: {
      return <ConfirmationIcon className={classes.icon} aria-hidden="true" />;
    }
    case MESSAGE_TYPE.error: {
      return <ErrorIcon className={classes.icon} aria-hidden="true" />;
    }
    case MESSAGE_TYPE.success: {
      return <SuccessIcon className={classes.icon} aria-hidden="true" />;
    }
    case MESSAGE_TYPE.information: {
      return <ConfirmationIcon className={classes.icon} aria-hidden="true" />;
    }
    case MESSAGE_TYPE.warning: {
      return <WarningOutlined className={classes.icon} aria-hidden="true" />;
    }
    default: {
      return <InfoIcon className={classes.icon} aria-hidden="true" />;
    }
  }
};

const CustomDialog = ({
  title,
  content,
  open,
  titleSize,
  cancelButton,
  cancelText,
  onCancel,
  okButton,
  okText,
  onOkay,
  onClose,
  messageType,
  okAriaLabel,
  cancelAriaLabel,
  footer,
}) => {
  const classes = useStyles();
  const [mouseDown, setMouseDown] = useState(false);
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    if (open) {
      document.getElementById('root').setAttribute('aria-hidden', 'false');
    }
  }, [open]);

  useEffect(() => {
    if (!open) setFocus(false);
  }, [open]);

  const handleOnOkay = () => {
    if (onOkay) {
      onOkay();
    }
  };

  const handleOnCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const handleOnClose = () => {
    if (onClose) {
      onClose();
    }
  };

  if (
    (title === null || title === '') &&
    (content === null || content === '')
  ) {
    return null;
  }

  return (
    <>
      <Dialog
        onClose={handleOnClose}
        {...(!focus
          ? { 'aria-labelledby': 'customized-dialog-title', role: 'dialog' }
          : {
              PaperProps: {
                role: 'presentation',
              },
            })}
        open={open}
        classes={{ paper: classes.dlgPaper }}
        scroll="body"
        disableEscapeKeyDown
        disableBackdropClick
      >
        <div className={classes.contentPadding}>
          <DialogContent classes={{ root: classes.contentContainer }}>
            <MessageIcon messageType={messageType} />
            {title && (
              <Typography
                id="customized-dialog-title"
                variant="h4"
                tabIndex={focus ? '0' : '-1'}
                className={
                  titleSize === 'small'
                    ? [classes.title, classes.titleSmall].join(' ')
                    : classes.title
                }
              >
                {title}
              </Typography>
            )}
            {content && title && <br />}
            {content && (
              <Typography
                id="customized-dialog-content"
                variant="h6"
                className={classes.content}
              >
                {content}
              </Typography>
            )}
          </DialogContent>
          <DialogAction
            messageType={messageType}
            okText={okText}
            okButton={okButton}
            handleOnOkay={handleOnOkay}
            cancelText={cancelText}
            cancelButton={cancelButton}
            handleOnCancel={handleOnCancel}
            focus={focus}
            mouseDown={mouseDown}
            setFocus={setFocus}
            setMouseDown={setMouseDown}
            okAriaLabel={okAriaLabel}
            cancelAriaLabel={cancelAriaLabel}
          />
          {footer}
          {onClose ? (
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onClose}
            >
              <HighlightOffIcon aria-hidden="true" />
            </IconButton>
          ) : null}
        </div>
      </Dialog>
    </>
  );
};

export default React.memo(CustomDialog);

CustomDialog.propTypes = {
  title: PropTypes.node,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  titleSize: PropTypes.string,
  cancelButton: PropTypes.bool,
  cancelText: PropTypes.string,
  onOkay: PropTypes.func,
  okButton: PropTypes.bool,
  okText: PropTypes.string,
  onCancel: PropTypes.func,
  messageType: PropTypes.string,
  footer: PropTypes.element,
};

CustomDialog.defaultProps = {
  title: null,
  content: null,
  titleSize: 'normal',
  cancelButton: true,
  cancelText: 'No',
  onOkay: null,
  okButton: true,
  okText: 'Yes',
  onCancel: null,
  messageType: null,
  footer: null,
};
