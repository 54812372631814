/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';

function RVHeaderCell(props) {
  const theme = useTheme();
  const { className, _key, style, children } = props;
  const borderColor = theme.palette.divider;
  // const defaultBgColor = settings.mainTheme.palette.background.paper;
  const defaultBgColor = '#F2F2F2';

  return (
    <div
      key={_key}
      style={{
        height: 40,
        whiteSpace: 'nowrap',
        backgroundColor: defaultBgColor,
        borderRight: `1px solid ${borderColor}`,
        padding: '12px',
        overflow: 'hidden',
        ...style,
      }}
      className={className}
    >
      {children}
    </div>
  );
}

RVHeaderCell.propTypes = {
  _key: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
};

export default React.memo(RVHeaderCell);
