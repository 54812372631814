import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { Box } from '@material-ui/core';
import links from 'constants/links';
import { useLocation } from 'react-router-dom';
import { LanguageContext } from '../../services/localization/LanguageContext';
import { getLanguage } from '../../utility/languageUtility';

const linkObject = {
  healthndSeniorsCareWebsite: links.healthndSeniorsCareWebsite,
  seniorCarePhone: links.seniorCarePhone,
  shareHealthLink: links.shareHealthLink,
  immunizationManitobaLink: links.immunizationManitobaLink,
  healthndSeniorsCareWebsiteFr: links.healthndSeniorsCareWebsiteFr,
  shareHealthLinkFr: links.shareHealthLinkFr,
  immunizationManitobaLinkFr: links.immunizationManitobaLinkFr,
  publicHealthOfficeWebsite: links.publicHealthOfficeWebsite,
  publicHealthOfficeWebsiteFr: links.publicHealthOfficeWebsiteFr,
};

const getStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  spaceTopBottom: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  title: {
    marginBottom: theme.spacing(3),
    fontWeight: 'bold',
  },
  expandIcon: {
    fontSize: 40,
  },
  expandIconButton: {
    order: -1,
    '&.Mui-expanded': {
      transform: 'rotate(90deg)!important',
    },
  },
  expanded: {
    transform: 'rotate(90deg)',
  },
  expandIconBlue: {
    color: '#004F9A',
  },
  expandIconBlack: {
    color: 'black',
    fontSize: 32,
  },
  accordionroot: {
    '&:before': {
      backgroundColor: 'transparent!important',
    },
    boxShadow: 'none',
  },
  sectionRootSummary: {
    boxShadow: '0px 3px 10px #00000012',
    border: '1px solid #C7D0D8',
    borderRadius: '9px',
    marginBottom: theme.spacing(2),
  },
  questionRootSummary: {
    background: '#F2F8F8C4 0% 0% no-repeat padding-box;',
  },
  questionCatalogTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  questionTitle: {
    fontWeight: 'bold',
  },
  questionDetails: {
    flexDirection: 'column',
  },
  content: {
    fontSize: '16px',
    lineHeight: '1.3em',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
  footer: {
    padding: '8px 16px 16px',
    textAlign: 'center',
  },
  centered: {
    textAlign: 'center',
  },
  list: {
    padding: '0px',
    margin: '0px',
    fontSize: 16,
  },
  container: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
  },
}));
const analyzeAnswer = (answerArray, languageContext, classes) => {
  const { translate, lang } = languageContext;
  if (!answerArray || !(answerArray instanceof Array)) {
    return null;
  }
  const jsx = (
    <>
      {answerArray.map((answer, index) => {
        if (typeof answer === 'string') {
          return (
            <Typography
              key={`answer_${index.toString()}`}
              className={[classes.content, classes.spaceTopBottom].join(' ')}
              tabIndex="0"
            >
              {translate('answer', linkObject, { answer: { [lang]: answer } })}
            </Typography>
          );
        }
        if (typeof answer === 'object') {
          if (answer.list) {
            return (
              <ul key={`answer_${index.toString()}`}>
                {answer.list.map((li, liIndex) => {
                  return (
                    <li key={`answer_list_${liIndex.toString()}`}>
                      <Typography className={classes.list}>{li}</Typography>
                    </li>
                  );
                })}
              </ul>
            );
          }
          return null;
        }
        return null;
      })}
      <br />
      <br />
    </>
  );

  return jsx;
};
const getQuestionsJsx = (questionArray, title, languageContext, classes) => {
  const { translate } = languageContext;
  const jsx = questionArray.map((qa, index) => {
    return translate('question', null, qa) === 'placeholder' ? null : (
      <div key={`panel-${title}-${index.toString()}`}>
        <Accordion classes={{ root: classes.accordionroot }}>
          <AccordionSummary
            classes={{
              expandIcon: classes.expandIconButton,
              root: [
                classes.sectionRootSummary,
                classes.questionRootSummary,
              ].join(' '),
            }}
            expandIcon={
              <ArrowRightIcon
                className={[classes.expandIconBlack, classes.expandIcon].join(
                  ' ',
                )}
              />
            }
            aria-controls="panel1a-content"
          >
            <Typography
              className={[classes.questionTitle, classes.content].join(' ')}
            >
              {translate('question', null, qa)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.questionDetails }}>
            {analyzeAnswer(
              translate('answer', null, qa),
              languageContext,
              classes,
            )}
          </AccordionDetails>
        </Accordion>
      </div>
    );
  });
  return jsx;
};
const getAccordingDetailJsx = (faqArray, languageContext, classes) => {
  const { translate } = languageContext;
  const jsx = faqArray.map((aq, index) => {
    const title = translate('title', null, aq);
    return title === 'placeholder' ? null : (
      <div key={`panel-header-${index.toString()}`}>
        {getQuestionsJsx(aq.questions, title, languageContext, classes)}
      </div>
    );
  });
  return jsx;
};

const FAQSections = ({ faqArray, title }) => {
  const classes = getStyles();
  const languageContext = useContext(LanguageContext);
  const { changeLanguage, translate } = languageContext;
  const { search } = useLocation();
  useEffect(() => {
    // only execute when load
    const lng = getLanguage(search);
    changeLanguage(lng);
  }, [changeLanguage, search]);

  return (
    <div className={classes.root}>
      <Box classes={{ root: classes.container }}>
        <Typography
          variant="h6"
          component="h1"
          className={[classes.title, classes.centered].join(' ')}
          tabIndex="0"
        >
          {title}
        </Typography>
        {getAccordingDetailJsx(faqArray, languageContext, classes)}
        <Typography
          className={[
            classes.content,
            classes.spaceTopBottom,
            classes.footer,
          ].join(' ')}
          tabIndex="0"
        >
          {translate('FAQ.footer', linkObject)}
        </Typography>
      </Box>
    </div>
  );
};

export default FAQSections;

FAQSections.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  faqArray: PropTypes.array,
  title: PropTypes.string.isRequired,
};
FAQSections.defaultProps = {
  faqArray: [],
};
