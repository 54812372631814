import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, TextField, makeStyles } from '@material-ui/core';
import { LanguageContext } from 'services/localization/LanguageContext';
import TextMaskCustom from '../../inputs/TextMaskCustom';

const useStyles = makeStyles((theme) => ({
  textfield: {
    width: (props) => (props.indent ? 'calc(100%) - 30px' : '100%'),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginLeft: (props) => (props.indent ? '30px' : '0px'),
  },
  inputLabel: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '90%',
    '&.shrink': {
      maxWidth: '100%',
      overflow: 'unset',
    },
  },
}));

const TextFieldCustom = ({
  disabled,
  label,
  name,
  type,
  value,
  onChange,
  onBlur,
  mask,
  error,
  required,
  maxLength,
  multiline,
  rows,
  indent,
  tooltip,
  ariaLabel,
  focusEl,
}) => {
  const classes = useStyles({ indent });
  const { translate } = useContext(LanguageContext);
  const InputProps = {};
  const ref = useRef(null);

  useEffect(() => {
    if (name === focusEl && ref && ref.current) {
      ref.current.focus();
    }
  }, [name, focusEl]);

  const getAriaLabel = () => {
    let inputAriaLabel = '';
    if (required)
      inputAriaLabel = `${translate('pages.consentForm.requiredFieldAria')}`;

    inputAriaLabel += (ariaLabel && translate(ariaLabel)) || translate(label);
    return inputAriaLabel;
  };

  const inputProps = {
    'aria-label': getAriaLabel(),
  };

  const handleOnChange = (e) => {
    if (onChange) onChange(name, e.target.value);
  };

  const handleOnBlur = (e) => {
    if (onBlur) onBlur(name, e.target.value);
  };

  const additionalProps = {};
  if (type === 'date') {
    additionalProps.InputLabelProps = { shrink: true };
  }

  if (mask) {
    InputProps.inputComponent = TextMaskCustom;
    inputProps.mask = mask;
  }

  if (maxLength) {
    inputProps.maxLength = maxLength;
  }

  const renderTextField = () => (
    <TextField
      key={name}
      disabled={disabled}
      inputRef={ref}
      // className={classes.textfield}
      variant="outlined"
      inputProps={inputProps}
      InputLabelProps={{
        classes: {
          root: classes.inputLabel,
          shrink: 'shrink',
        },
      }}
      classes={{ root: classes.textfield }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={InputProps}
      id={name}
      name={name}
      value={value}
      label={`${required ? '* ' : ''}${translate(label)}`}
      type={type}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      // required={required}
      error={Boolean(error)}
      helperText={translate(error)}
      rows={rows}
      multiline={multiline}
      {...additionalProps}
    />
  );

  if (tooltip && !disabled)
    return <Tooltip title={tooltip}>{renderTextField()}</Tooltip>;

  return renderTextField();
};

TextFieldCustom.defaultProps = {
  disabled: false,
  mask: null,
  error: '',
  required: false,
  maxLength: null,
  onBlur: () => {},
  multiline: false,
  rows: undefined,
  indent: false,
  tooltip: undefined,
  ariaLabel: undefined,
  focusEl: undefined,
};

TextFieldCustom.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  mask: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(RegExp)]),
  ),
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.string,
      PropTypes.shape({ label: PropTypes.string }),
    ),
  ]),
  required: PropTypes.bool,
  maxLength: PropTypes.number,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  indent: PropTypes.bool,
  tooltip: PropTypes.string,
  ariaLabel: PropTypes.string,
  focusEl: PropTypes.string,
};

export default TextFieldCustom;
