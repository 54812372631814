/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
/* eslint-disable max-len */
import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { useHistory, Prompt } from 'react-router-dom';
import {
  CircularProgress,
  makeStyles,
  Typography,
  Button,
  Divider,
} from '@material-ui/core';
import { ArrowForwardRounded as ArrowForwardIcon } from '@material-ui/icons';
import { LanguageContext } from 'services/localization/LanguageContext';
import CustomizedButton from 'components/button/CustomizedButton';
import PageLayout from 'components/page-layout/PageLayout';
import FormSection from 'components/form/FormSection';
import CustomDialog from 'components/dialog/CustomDialog';
import useConsentFormStore from 'services/store/form-stores/eConsentFormStore';
import { LANG } from 'utility/languageUtility';
import links from 'constants/links';
import classnames from 'classnames';
import { useTheme } from '@material-ui/core/styles';
import { toBoolean, formatDate } from 'utility/common';
import openPDF from 'utility/fileUtility';
import {
  mapKeys,
  startsWith,
  upperFirst,
  findKey,
  keys,
  trim,
  isEmpty,
  isObject,
  has,
  isBoolean,
  toLower,
} from 'lodash';
import MESSAGE_TYPE from 'components/dialog/constants';
import CustomBackdrop from 'components/backdrop';
import getPageTitle from 'utility/pageTitle';
import Loading from './Loading';
import isFormDirty from './helper';
import AzureOperation from '../../services/common';
import { getCommon } from '../../commons/index';
import consentFormFields from './consentFormFields';
import { mapConsentForm } from '../../utility/consentFormMapper';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      padding: '4% 20%',
    },
  },
  sectionTitle: {
    fontSize: '17px',
    fontWeight: 'bold',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  formTitle: {
    marginTop: '0px',
    marginBottom: theme.spacing(2),
  },
  sectionContentContainer: {
    paddingLeft: '0px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  sectionContentP: {
    marginTop: theme.spacing(1),
    overflowWrap: 'anywhere',
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
    '&:first-child': {
      marginTop: theme.spacing(2),
      marginBottom: '0px',
    },
  },
  sectionChildContent: {
    marginLeft: '10px',
  },
  newLine: {
    height: '30px',
  },
  link: {
    textDecoration: 'underline',
    wordWrap: 'break-word',
  },
  buttonWrapper: {
    width: '100%',
    margin: '24px 0',
    textAlign: 'center',
  },
  validationList: {
    textAlign: 'left',
  },
  asteriskLegend: {
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    position: 'absolute',
    overflow: 'hidden',
    clip: 'rect(0 0 0 0)',
    height: '1px',
    width: '1px',
    margin: '-1px',
    padding: 0,
    border: 0,
  },
  successMessageFooter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginBottom: theme.spacing(2),
    position: 'relative',
    paddingTop: theme.spacing(4),
  },
  surveyDivider: {
    width: `calc(100% + ${theme.spacing(4)}px + 1px)`,
    position: 'absolute',
    top: `-${theme.spacing(1)}px`,
    left: `-${theme.spacing(2) + 1}px`,
    marginTop: theme.spacing(2),
  },
  surveyButton: {
    backgroundColor: '#00bf6f',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#00a660',
    },
  },
  surveyCaption: {
    textAlign: 'center',
    fontSize: '0.875rem',
    marginTop: theme.spacing(2),
  },
}));

const ConsentForm = () => {
  const WAIT_TIME = 2;
  const timer = useRef();
  const classes = useStyles();
  const theme = useTheme();
  const { translate, lang } = useContext(LanguageContext);
  const focusElState = useConsentFormStore((state) => state.focusEl);
  const schoolsApiHasError = useConsentFormStore(
    (state) => state.schoolsApiHasError,
  );
  const loadingSchoolsApi = useConsentFormStore(
    (state) => state.loadingSchoolsApi,
  );
  const consentFormState = useConsentFormStore((state) => state.formState);
  const updateForm = useConsentFormStore((state) => state.updateForm);
  const updateWholeForm = useConsentFormStore((state) => state.updateWholeForm);
  const updateFocusEl = useConsentFormStore((state) => state.setFocusEl);
  const clearForm = useConsentFormStore((state) => state.clearForm);
  const [scrollTo, setScrollTo] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [schoolId, setSchoolId] = useState('');
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [time, setTime] = useState(0);
  const [pdfReqFailed, setPdfReqFailed] = useState(null);
  const [pdf, setPdf] = useState(null);
  const history = useHistory();
  const { pagesUrl } = getCommon();
  const [submitModal, setSubmitModal] = useState({
    open: false,
    message: '',
  });

  const goToConsentList = useCallback(() => {
    history.push('/consent-list');
  }, [history]);

  const goToHomePage = useCallback(() => {
    history.push('/');
  }, [history]);

  useEffect(() => {
    if (
      loadingSchoolsApi !== null &&
      loadingSchoolsApi === false &&
      schoolsApiHasError
    ) {
      setSubmitModal({
        open: true,
        messageType: MESSAGE_TYPE.error,
        okText: translate('pages.request.ok'),
        onClose: () => goToHomePage(),
        onOkay: () => goToHomePage(),
        message: translate('pages.consentForm.error'),
      });
    }
  }, [loadingSchoolsApi, schoolsApiHasError, setSubmitModal]);

  useEffect(() => {
    return () => {
      clearInterval(timer.current);
      timer.current = 0;
      setTime(0);
    };
  }, []);

  useEffect(() => {
    document.title = getPageTitle(
      translate('pages.consentForm.formTitle'),
      lang,
    );
  }, [translate, lang]);

  useEffect(() => {
    return () => {
      clearInterval(timer.current);
      timer.current = 0;
      setTime(0);
    };
  }, []);

  useEffect(() => {
    const anchor = document.querySelector(`#${scrollTo}`);
    if (anchor && scrollTo !== null) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setScrollTo(null);
    }
  }, [scrollTo, setScrollTo]);

  useEffect(() => {
    if (pdf !== null || pdfReqFailed === true) {
      if (time >= WAIT_TIME) {
        // Close the backdrop if the submission was successful
        // Backdrop must show atleast 3 seconds before it closes.
        clearInterval(timer.current);
        setTime(0);
        setOpenBackdrop(false);
        setPdfReqFailed(null);
        if (pdf !== null) {
          openPDF(pdf);
          setPdf(null);
          setSubmitModal({ open: false });
          goToConsentList();
        } else {
          setSubmitModal({
            open: true,
            messageType: MESSAGE_TYPE.error,
            okText: translate('pages.request.ok'),
            onOkay: () => goToConsentList(),
            message: translate('pages.request.PDF.error'),
          });
        }
      }
    }
  }, [translate, time, pdf, pdfReqFailed, goToConsentList]);

  const startTimer = () => {
    timer.current = setInterval(() => {
      setTime((_time) => _time + 1);
    }, 1000);
  };

  useEffect(() => {
    return () => clearForm();
  }, [clearForm]);

  const handleCloseModal = () => {
    setSubmitModal({ open: false });
    if (submitSuccess) {
      clearForm();
    }
  };

  const openSubmitModal = (message, messageType) => {
    setSubmitModal({
      open: true,
      message,
      messageType,
      okText:
        messageType === MESSAGE_TYPE.confirmation
          ? translate('pages.request.yes')
          : translate('pages.request.ok'),
      cancelText:
        messageType === MESSAGE_TYPE.confirmation
          ? translate('pages.request.no')
          : '',
      onOkay:
        messageType === MESSAGE_TYPE.error
          ? () => handleCloseModal()
          : () => {},
    });
  };

  const getRadioButtonConditionalText = (name) => {
    // eslint-disable-next-line consistent-return
    let result = null;
    let radioButtonWithConditionalText = {};
    findKey(consentFormFields, (o) => {
      mapKeys(o, (value, key) => {
        if (value.conditionalTextField !== undefined) {
          radioButtonWithConditionalText = {
            ...radioButtonWithConditionalText,
            [key]: o,
          };
        }
      });
    });

    if (radioButtonWithConditionalText[name] !== undefined) {
      const corrTextFieldName = radioButtonWithConditionalText[name][name];
      result = corrTextFieldName.conditionalTextField;
    }

    return result;
  };

  const getFieldsWithConditionalValidation = () => {
    // eslint-disable-next-line consistent-return
    let result = {};
    mapKeys(consentFormState, (fields, fieldKey) => {
      mapKeys(fields, (fieldProp, fieldPropkey) => {
        if (fieldPropkey === 'conditionalField')
          result = {
            ...result,
            [fieldKey]: {
              ...fields,
              ...(fields.name === undefined && { name: fieldKey }),
            },
          };
      });
    });

    return result;
  };

  const isFieldWithConditionalValidation = (name) => {
    // eslint-disable-next-line consistent-return
    // Check whether a TextField is a corresponding TextField of Radio Button
    let result = false;
    const fieldWithConditionalValidation = getFieldsWithConditionalValidation();

    if (
      fieldWithConditionalValidation[name] !== null &&
      fieldWithConditionalValidation[name] !== undefined
    ) {
      result = true;
    }

    return result;
  };

  const getConsentFormStateValuesOnly = () => {
    let result = {};
    mapKeys(consentFormState, (value, key) => {
      result = { ...result, [key]: value.value };
    });

    return result;
  };

  const getInput = (name) => {
    // eslint-disable-next-line consistent-return
    let input = null;
    const fieldWithConditionalValidation = getFieldsWithConditionalValidation();

    findKey(consentFormFields, (o) => {
      mapKeys(o, (value, key) => {
        if (key === name) {
          // eslint-disable-next-line prefer-destructuring
          input = o[name];
        }
      });
    });

    if (
      // input === null &&
      fieldWithConditionalValidation[name] !== null &&
      fieldWithConditionalValidation[name] !== undefined
    ) {
      input = {
        ...(input !== null && input.type !== undefined && { type: input.type }),
        ...fieldWithConditionalValidation[name],
      };
    }

    return input;
  };

  const validateInput = (input, targetValue) => {
    if (!input) return {};

    let error = {};
    let value;
    if (targetValue) {
      value = targetValue;
    } else if (typeof input.value === 'string') {
      value = input.value ? input.value.trim() : input.value;
    } else {
      value = input.value;
    }
    if (toLower(input.name).search('phonenumber') >= 0) {
      // unmask: get numbers only
      value = value && value.replace(new RegExp(/[^\d]/, 'g'), '');
    }

    const {
      validation,
      customValidate,
      labelMsg,
      requiredMsg,
      requiredAriaMsg,
      minLengthMsg,
      maxLengthMsg,
      type,
    } = input;

    if (!validation || isEmpty(validation)) return {};

    // Validation properties/criteria
    const { isRequired, maxLength, minLength, length, min, max, email } =
      validation;

    const label = !labelMsg ? input.label : labelMsg;
    if (isRequired) {
      const emptyErrorText = 'pages.request.information.errors.empty';

      if (!value || (value && trim(value).length === 0)) {
        const errorToDisplay = [
          !requiredMsg ? emptyErrorText : requiredMsg,
          { label },
        ];
        error = {
          error: errorToDisplay,
          errorAria: !requiredAriaMsg
            ? errorToDisplay
            : [requiredAriaMsg, { label }],
        };
      } else if (input.name === 'schoolName') {
        // If schoolCityName is invalid, it means the schoolName value is invalid as well
        // AutoComplete will automatically clear the value to null, thus it falls under cannot be empty error
        const cityInputName = consentFormFields.sectionA.schoolCity.name;
        if (
          !consentFormState[cityInputName] ||
          consentFormState[cityInputName].value === '' ||
          consentFormState[cityInputName].value === null
        ) {
          // error = [!requiredMsg ? emptyErrorText : requiredMsg, { label }];
          error = {
            error: ['pages.request.information.errors.itemNotFound', { label }],
          };
        }
      }

      if (isEmpty(error) && customValidate) {
        error = customValidate(value, consentFormState, consentFormFields);
      }
    }

    if (!isEmpty(error)) return error;

    if (type === 'date' && toLower(value) === 'invalid date') {
      return {
        error: ['pages.request.information.errors.invalid', { label }],
      };
    }

    if (minLength && value.length < minLength) {
      error = {
        error: [
          !minLengthMsg
            ? 'pages.request.information.errors.minLength'
            : minLengthMsg,
          { label, length: minLength },
        ],
      };
    } else if (maxLength && value.length > maxLength) {
      error = {
        error: [
          !maxLengthMsg
            ? 'pages.request.information.errors.maxLength'
            : validation.maxLengthMsg,
          { label, length: maxLength },
        ],
      };
    } else if (length && value && value.toString().length !== length) {
      error = {
        error: [
          'pages.request.information.errors.exactLength',
          { label, length },
        ],
      };
    } else if (min && new Date(value) < new Date(min)) {
      // Min date check
      error = {
        error: ['pages.request.information.errors.minDate', { date: min }],
      };
    } else if (max && new Date(value) > new Date(max)) {
      // Max date check
      error = {
        error: ['pages.request.information.errors.maxDate', { date: max }],
      };
    } else if (email && value !== '' && value !== undefined) {
      // Email Address Check
      const isValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        value,
      );
      if (!isValid)
        error = {
          error: ['pages.request.information.errors.invalid', { label }],
        };
    } else if (customValidate) {
      error = customValidate(value, consentFormState, consentFormFields);
    }

    return error;
  };

  const validateClient = async (phin, dob) => {
    let err = null;
    if (phin && dob) {
      const { protectedResources } = AzureOperation;
      const dateOfBirth = formatDate(dob, 'YYYY-MM-DD');
      const params = {
        url: protectedResources.consentForm.endpoints.validateClient.baseUrl,
        method: protectedResources.consentForm.endpoints.validateClient.method,
        data: { phin, dateOfBirth },
      };

      try {
        const result = await AzureOperation.callApiWithAzureToken(params);
        if (!result || !result.found) {
          err = [
            'pages.request.information.errors.itemNotFound',
            { label: consentFormFields.sectionA.phin.label },
          ];
        }
      } catch (error) {
        err = [
          consentFormFields.sectionA.phin.name,
          { label: translate('pages.consentForm.error') },
        ];
      }
    }

    return err;
  };

  const validatePhinSet = async (phin) => {
    const dobKey = consentFormFields.sectionA.dateOfBirth.name;
    const phinKey = consentFormFields.sectionA.phin.name;
    let err = null;
    if (
      !phin &&
      consentFormState[phinKey] &&
      consentFormState[phinKey].value &&
      consentFormState[phinKey].value !== '' &&
      consentFormState[dobKey] &&
      consentFormState[dobKey].value &&
      consentFormState[dobKey].value !== ''
    ) {
      err = await validateClient(
        consentFormState[phinKey].value,
        consentFormState[dobKey].value,
      );
    }
    return err;
  };

  const getFieldWithErrors = () => {
    let fieldWithErrors = {};
    findKey(consentFormFields, (o) => {
      mapKeys(o, (value, key) => {
        if (
          isObject(o[key]) &&
          has(o[key], 'type') &&
          !isFieldWithConditionalValidation(key)
        ) {
          const fieldValue =
            (consentFormState &&
              consentFormState[key] &&
              consentFormState[key].value) ||
            '';

          const fieldError =
            (consentFormState &&
              consentFormState[key] &&
              consentFormState[key].error) ||
            '';

          const error =
            fieldError !== 'Invalid Date'
              ? validateInput(o[key], fieldValue)
              : null;

          if (!isEmpty(error)) {
            fieldWithErrors = {
              ...fieldWithErrors,
              [key]: { value: fieldValue, ...error },
            };
          }
        }
      });
    });

    const fieldWithConditionalValidation = getFieldsWithConditionalValidation();
    mapKeys(fieldWithConditionalValidation, (value, key) => {
      const fieldValue =
        (consentFormState &&
          consentFormState[key] &&
          consentFormState[key].value) ||
        '';
      const error = validateInput(value, fieldValue);

      if (!isEmpty(error)) {
        fieldWithErrors = {
          ...fieldWithErrors,
          [key]: {
            ...consentFormState[key],
            value: fieldValue,
            error: error.error,
          },
        };
      }
    });

    return fieldWithErrors;
  };

  const errorModal = () => {
    return {
      open: true,
      messageType: MESSAGE_TYPE.error,
      okText: translate('pages.request.ok'),
      onOkay: () => handleCloseModal(),
      message: translate('pages.consentForm.validationError'),
      // content: (
      // <ul className={classes.validationList}>
      //   <li>{translate('pages.consentForm.sectionA.surname')}</li>
      //   <li>{translate('pages.consentForm.sectionA.givenName')}</li>
      //   <li>{translate('pages.consentForm.validationFields.dateOfBirth')}</li>
      //   <li>
      //     {translate(
      //       'pages.consentForm.validationFields.mbRegistrationNumber',
      //     )}
      //   </li>
      //   <li>{translate('pages.consentForm.validationFields.phin')}</li>
      //   <li>{translate('pages.consentForm.sectionA.schoolName')}</li>
      //   <li>{translate('pages.consentForm.sectionA.schoolCity')}</li>
      //   <li>{translate('pages.consentForm.sectionA.grade')}</li>
      // </ul>
      // ),
    };
  };

  const apiErrorModal = () => {
    return {
      open: true,
      messageType: MESSAGE_TYPE.error,
      okText: translate('pages.request.ok'),
      onOkay: () => handleCloseModal(),
      message: translate('pages.consentForm.error'),
    };
  };

  const viewPdf = (_econsentId) => {
    if (_econsentId === null) return;

    setSubmitModal({ open: false });

    setOpenBackdrop(true);
    const { protectedResources, callApiWithAzureToken } = AzureOperation;
    const { baseUrl, method } = protectedResources.PDF.endpoints.covid19;
    const params = {
      url: `${baseUrl}/${lang}/${_econsentId}`,
      method,
    };

    startTimer();
    callApiWithAzureToken(params)
      .then((result) => {
        if (result.pdf !== null) setPdf(result.pdf);
        else setPdfReqFailed(true);
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        setPdfReqFailed(true);
      });
  };

  const submitSuccessModal = () => {
    return {
      open: true,
      messageType: MESSAGE_TYPE.confirmation,
      cancelButton: true,
      okText: translate('pages.request.yes'),
      cancelText: translate('pages.request.no'),
      onClose: () => goToConsentList(),
      onCancel: () => goToConsentList(),
      footer: (
        <div className={classes.successMessageFooter}>
          <Divider classes={{ root: classes.surveyDivider }} />
          <Button
            tabIndex="0"
            color="contained"
            variant="outlined"
            classes={{ root: classes.surveyButton }}
            onClick={() => {
              history.push(`survey?lang=${lang}`);
            }}
          >
            {translate('pages.consentForm.takeSurvey')}
          </Button>
          <Typography
            tabIndex="0"
            variant="body1"
            className={classes.surveyCaption}
          >
            {translate('pages.consentForm.surveyMessage')}
          </Typography>
        </div>
      ),
    };
  };

  const openSuccessMessage = (_econsentId) => {
    setSubmitModal({
      ...submitSuccessModal(),
      onOkay: () => viewPdf(_econsentId),
      message: translate('pages.consentForm.success'),
    });
  };

  const openErrorModal = (_firstFieldWithError) => {
    let anchorId = _firstFieldWithError;
    if (startsWith(_firstFieldWithError, 'question')) {
      anchorId = `has${upperFirst(_firstFieldWithError)}`;
    } else if (_firstFieldWithError === 'otherCompletedBy') {
      anchorId = `completedBy`;
    }

    updateFocusEl(null);
    setSubmitModal({
      ...errorModal(),
      onOkay: () => {
        setScrollTo(anchorId);
        updateFocusEl(_firstFieldWithError);
        handleCloseModal();
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let fieldsWithError = getFieldWithErrors();
    const phinKey = consentFormFields.sectionA.phin.name;
    const error = await validatePhinSet(fieldsWithError.phin);
    if (error) {
      fieldsWithError = {
        ...fieldsWithError,
        phin: { value: consentFormState[phinKey].value, error },
      };
    }

    updateWholeForm(fieldsWithError);
    const totalFieldsWithError = keys(fieldsWithError).length;
    if (totalFieldsWithError > 0) {
      const fieldNamesWithError = keys(fieldsWithError);
      openErrorModal(fieldNamesWithError[0]);
      return false;
    }

    setSubmitting(true);
    setSubmitModal({ open: false });
    let consentFormStateValuesOnly = getConsentFormStateValuesOnly();
    consentFormStateValuesOnly = {
      ...consentFormStateValuesOnly,
      schoolId,
      formLanguage: lang === LANG.FR ? 'FRENCH' : 'ENGLISH',
    };

    const { protectedResources } = AzureOperation;
    const params = {
      url: protectedResources.consentForm.endpoints.submitConsentForm.baseUrl,
      method: protectedResources.consentForm.endpoints.submitConsentForm.method,
      data: mapConsentForm(consentFormStateValuesOnly),
    };

    try {
      const result = await AzureOperation.callApiWithAzureToken(params);
      if (!result || result.statusCode !== 0) {
        setSubmitModal(apiErrorModal);
        return false;
      }
      setSubmitSuccess(true);
      openSuccessMessage(result.econsentId);
      return true;
    } catch (err) {
      openSubmitModal(translate('pages.consentForm.error'), MESSAGE_TYPE.error);
      return false;
    } finally {
      setSubmitting(false);
    }
  };

  const showPrompt = () => {
    if (submitSuccess || submitModal.open) return false;

    return true;
  };

  const handleBlockedNavigation = (nextLocation) => {
    if (
      submitSuccess ||
      (nextLocation &&
        nextLocation.pathname === pagesUrl.get('covidConsentForm').url)
    ) {
      return true;
    }
    if (isFormDirty(consentFormState)) {
      setSubmitModal({
        open: true,
        message: translate('pages.consentForm.navigateWarning'),
        messageType: MESSAGE_TYPE.confirmation,
        okText: translate('pages.request.yes'),
        cancelText: translate('pages.request.no'),
        onClose: () => setSubmitModal({ open: false }),
        onCancel: () => setSubmitModal({ open: false }),
        onOkay: () => {
          setSubmitModal({ open: false });
          clearForm();
          history.push(nextLocation.pathname);
        },
      });

      return false;
    }

    return true;
  };

  const handleOnChange = (name, value) => {
    const input = getInput(name);
    if (!input) return;

    if (
      input.type !== 'checkbox' &&
      input.type !== 'radio' &&
      input.type !== 'date'
    ) {
      const error = validateInput(input, value);

      updateForm(name, {
        value,
        ...(!isEmpty(error) && {
          ...error,
        }),
      });
    } else if (input.type === 'date') {
      let error = validateInput(input, value);
      if (
        value !== null &&
        value !== '' &&
        value.toString() === 'Invalid Date'
      ) {
        error = {
          error: [
            'pages.request.information.errors.invalid',
            { label: input.label },
          ],
        };
      }
      updateForm(name, {
        value,
        ...(!isEmpty(error)
          ? {
              ...error,
            }
          : {
              error: null,
              errorAria: null,
            }),
      });
    } else {
      const error = validateInput(input, value);
      updateForm(name, {
        value,
        ...(!isEmpty(error)
          ? {
              ...error,
            }
          : {
              error: null,
              errorAria: null,
            }),
      });

      const corrTextFieldName = getRadioButtonConditionalText(name);
      if (corrTextFieldName !== null) {
        // This radio control is a question type ('yes' or 'no')
        // or with 'other' as one of options
        // if answer is 'Yes', we need to enable the corresponding TextField.
        // All Radio related to Question starts with 'has', so need to remove it
        // If answer is no, need to remove the corresponding textfield's value
        const isDisabled = isBoolean(toBoolean(value))
          ? value === 'false'
          : value !== 'other';

        const corrTextFieldInput = getInput(corrTextFieldName);
        const corrTextFieldError = validateInput(corrTextFieldInput, value);
        updateForm(corrTextFieldName, {
          disabled: isDisabled,
          ...(isDisabled
            ? { value: '', validation: {} }
            : { validation: { isRequired: true } }),
          error: corrTextFieldError.error,
        });
      }

      if (name === 'racialEthnicity') {
        const northAmericanIndigenousIdentity =
          consentFormFields.sectionC.northAmericanIndigenousIdentity.name;
        const disableNorthAmericanIndigenousIdentity =
          value !== 'north_american_indigenous';

        updateForm(northAmericanIndigenousIdentity, {
          disabled: disableNorthAmericanIndigenousIdentity,
          ...(disableNorthAmericanIndigenousIdentity
            ? { value: '', validation: {} }
            : { validation: { isRequired: true } }),
          error: null,
        });
      }

      updateFocusEl(null);
    }
  };

  const handleOnBlur = (name, value, data) => {
    const input = getInput(name);
    if (!input) return;

    if (name === 'mbRegistrationNumber') {
      const mbRegError = validateInput(input, value);
      const phinName = 'phin';
      const phinInput = getInput(phinName);
      const phinValue =
        consentFormState[phinName] && consentFormState[phinName].value;
      const phinError = validateInput(phinInput, phinValue);
      updateWholeForm({
        mbRegistrationNumber: {
          value,
          error: mbRegError.error,
        },
        phin: {
          value: phinValue,
          error: phinError.error,
        },
      });
    } else if (name === 'phin') {
      const phinError = validateInput(input, value);
      const mbRegName = 'mbRegistrationNumber';
      const mbRegInput = getInput(mbRegName);
      const mbRegValue =
        consentFormState[mbRegName] && consentFormState[mbRegName].value;
      const mbRegError = validateInput(mbRegInput, mbRegValue);
      updateWholeForm({
        phin: {
          value,
          error: phinError.error,
        },
        mbRegistrationNumber: {
          value: mbRegValue,
          error: mbRegError.error,
        },
      });
    } else {
      const error = validateInput(input, value);

      updateForm(name, {
        ...(!isEmpty(error)
          ? {
              ...error,
            }
          : {
              error: null,
              errorAria: null,
            }),
      });
    }

    if (data && data.schoolId) {
      setSchoolId(data.schoolId);
    }

    updateFocusEl(null);
  };

  const renderForm = () => {
    return (
      <div
        style={{
          display:
            loadingSchoolsApi === false && schoolsApiHasError === false
              ? 'block'
              : 'none',
        }}
      >
        <form
          onSubmit={handleSubmit}
          className={classes.root}
          autoComplete="off"
        >
          <Typography
            variant="h2"
            className={classnames(classes.sectionTitle, classes.formTitle)}
          >
            {translate('pages.consentForm.formTitle')}
          </Typography>

          <Typography variant="body2" className={classes.asteriskLegend}>
            {translate('pages.consentForm.asteriskLegend')}
          </Typography>

          <FormSection
            inputs={consentFormFields.header}
            formState={consentFormState}
            focusEl={focusElState}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
          />
          <Typography
            aria-label={`${translate(
              'pages.consentForm.section',
            )} A ${translate('pages.consentForm.sectionA.title')}`}
            tabIndex="0"
            variant="h3"
            className={classes.sectionTitle}
          >
            A. {translate('pages.consentForm.sectionA.title')}
          </Typography>
          <FormSection
            inputs={consentFormFields.sectionA}
            formState={consentFormState}
            focusEl={focusElState}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
          />
          <Typography
            aria-label={`${translate(
              'pages.consentForm.section',
            )} B ${translate('pages.consentForm.sectionB.title')}`}
            tabIndex="0"
            variant="h3"
            className={classes.sectionTitle}
          >
            B. {translate('pages.consentForm.sectionB.title')}
          </Typography>
          <FormSection
            inputs={consentFormFields.sectionB}
            formState={consentFormState}
            focusEl={focusElState}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
          />
          <Typography
            aria-label={`${translate(
              'pages.consentForm.section',
            )} C ${translate('pages.consentForm.sectionC.title')}`}
            tabIndex="0"
            variant="h3"
            className={classes.sectionTitle}
          >
            C. {translate('pages.consentForm.sectionC.title')}
          </Typography>
          <FormSection
            inputs={consentFormFields.sectionC}
            formState={consentFormState}
            focusEl={focusElState}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
          />
          <Typography
            aria-label={`${translate(
              'pages.consentForm.section',
            )} D ${translate('pages.consentForm.sectionD.title')}`}
            tabIndex="0"
            variant="h3"
            className={classes.sectionTitle}
          >
            D. {translate('pages.consentForm.sectionD.title')}
          </Typography>
          <div className={classes.sectionContentContainer}>
            <Typography
              tabIndex="0"
              variant="body1"
              className={classnames(
                classes.sectionContent,
                classes.sectionContentP,
              )}
            >
              <b>{translate('pages.consentForm.sectionD.factSheet.title')}</b>
            </Typography>
            <Typography
              tabIndex="0"
              variant="body1"
              className={classes.sectionContentP}
            >
              {translate('pages.consentForm.sectionD.factSheet.body1', {
                covidVaccineResourcesLink: links.covidVaccineResourcesLink,
                covidVaccineResourcesLinkFr: links.covidVaccineResourcesLinkFr,
              })}
            </Typography>
            <Typography
              tabIndex="0"
              variant="body1"
              className={classes.sectionContentP}
            >
              {translate('pages.consentForm.sectionD.factSheet.body2')}
            </Typography>
            <Typography
              tabIndex="0"
              variant="body1"
              className={classes.sectionContentP}
            >
              {translate('pages.consentForm.sectionD.factSheet.body3', {
                publicHealthOfficeWebsite: links.publicHealthOfficeWebsite,
                publicHealthOfficeWebsiteFr: links.publicHealthOfficeWebsiteFr,
              })}
            </Typography>
            <FormSection
              marginTop={theme.spacing(2)}
              inputs={consentFormFields.sectionDCovidFactSheet}
              formState={consentFormState}
              focusEl={focusElState}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
            />
            <FormSection
              marginTop={theme.spacing(2)}
              inputs={consentFormFields.sectionDClauses}
              formState={consentFormState}
              focusEl={focusElState}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
            />
            <Typography
              tabIndex="0"
              variant="body1"
              className={classes.sectionContentP}
            >
              {translate('pages.consentForm.sectionD.note', {
                publicHealthOfficeWebsite: links.publicHealthOfficeWebsite,
                publicHealthOfficeWebsiteFr: links.publicHealthOfficeWebsiteFr,
              })}
            </Typography>
          </div>
          <Typography
            aria-label={`${translate(
              'pages.consentForm.section',
            )} E ${translate('pages.consentForm.sectionE.title')}`}
            tabIndex="0"
            variant="h3"
            className={classes.sectionTitle}
          >
            E. {translate('pages.consentForm.sectionE.title')}
          </Typography>

          <FormSection
            inputs={consentFormFields.sectionE}
            formState={consentFormState}
            focusEl={focusElState}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
          />

          <Typography
            tabIndex="0"
            variant="body1"
            className={classes.sectionContentP}
          >
            {translate(
              'pages.consentForm.sectionE.thirdPartyDisclosureClosingStatement',
              {
                publicHealthOfficeWebsite: links.publicHealthOfficeWebsite,
                publicHealthOfficeWebsiteFr: links.publicHealthOfficeWebsiteFr,
              },
            )}
          </Typography>

          <Typography
            tabIndex="0"
            variant="h3"
            className={classes.sectionTitle}
          >
            <b>{translate('pages.consentForm.sectionE.privacyNotice.title')}</b>
          </Typography>
          <Typography
            tabIndex="0"
            variant="body1"
            className={classes.sectionContentP}
          >
            {translate('pages.consentForm.sectionE.privacyNotice.body', {
              publicHealthOfficeWebsite: links.publicHealthOfficeWebsite,
              publicHealthOfficeWebsiteFr: links.publicHealthOfficeWebsiteFr,
            })}
          </Typography>

          <div className={classes.buttonWrapper}>
            <CustomizedButton
              aria-label="Submit eConsent Form"
              variant="contained"
              color="primary"
              type="submit"
              endIcon={<ArrowForwardIcon />}
              disabled={submitting}
              startIcon={submitting && <CircularProgress size={16} />}
              onClick={handleSubmit}
            >
              {translate('pages.consentForm.submit')}
            </CustomizedButton>

            {submitting && (
              <Typography
                role="alert"
                aria-live="polite"
                className={classes.visuallyHidden}
              >
                {translate('pages.consentForm.submittingForm')}
              </Typography>
            )}
          </div>
        </form>
      </div>
    );
  };

  return (
    <PageLayout
      showNavigation
      showDrawer
      detailHeader={translate('pages.consentForm.detailHeader')}
      alwaysShowLanguageToggle
    >
      <Prompt when={showPrompt()} message={handleBlockedNavigation} />
      {renderForm()}
      {loadingSchoolsApi === true && (
        <Loading text={translate('pages.consentForm.loadingFormData')} />
      )}
      <CustomDialog
        title={submitModal.message}
        open={submitModal.open}
        messageType={submitModal.messageType}
        content={submitModal.content}
        okText={submitModal.okText}
        cancelText={submitModal.cancelText}
        cancelButton={submitModal.cancelButton}
        onCancel={submitModal.onCancel || handleCloseModal}
        onOkay={submitModal.onOkay}
        onClose={submitModal.onClose || handleCloseModal}
        footer={submitModal.footer}
      />
      <CustomBackdrop
        open={openBackdrop}
        text={translate('pages.request.PDF.wait')}
      />
    </PageLayout>
  );
};

export default ConsentForm;
