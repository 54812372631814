/* eslint-disable react/no-array-index-key */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import links from 'constants/links';
import { LanguageContext } from 'services/localization/LanguageContext';
import { getLanguage, LANG } from '../../../utility/languageUtility';
import './PrivateSection.css';

const linkObject = {
  healthndSeniorsCareWebsite: links.healthndSeniorsCareWebsite,
  contactEmail: links.contactEmail,
  shareHealthLink: links.shareHealthLink,
  immunizationManitobaLink: links.immunizationManitobaLink,
  healthndSeniorsCareWebsiteFr: links.healthndSeniorsCareWebsiteFr,
  shareHealthLinkFr: links.shareHealthLinkFr,
  immunizationManitobaLinkFr: links.immunizationManitobaLinkFr,
  recordLinkEn: links.recordLinkEn,
  recordLinkFr: links.recordLinkFr,
  forResidentsEn: links.forResidentsEn,
  forResidentsFr: links.forResidentsFr,
  recordLinkUnderline: links.recordLinkUnderline,
  publicHealthOfficeWebsite: links.publicHealthOfficeWebsite,
  publicHealthOfficeWebsiteFr: links.publicHealthOfficeWebsiteFr,
};

const getStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  spaceTopBottom: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  title: {
    marginBottom: theme.spacing(3),
    fontWeight: 'bold',
  },
  content: {
    fontSize: '16px',
    lineHeight: '1.5em',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    marginBottom: '20px',
  },
  centered: {
    textAlign: 'center',
  },
  container: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
  },
  listNoSpace: {
    marginTop: '-16px',
  },
  email: {
    textTransform: 'lowercase',
  },
  link: {
    textDecoration: 'underline',
  },
  mbLogo: {
    display: 'flex',
    borderBottom: '25px solid',
    borderBottomColor: theme.palette.primary.dark,
  },
}));

const PrivateSection = ({ privacyPolicy }) => {
  const classes = getStyles();
  const privacyPolicySections = privacyPolicy.sections;
  const [lang, setLang] = useState(LANG.EN);
  const { translate, changeLanguage } = useContext(LanguageContext);
  const { search } = useLocation();

  useEffect(() => {
    const lng = getLanguage(search);
    changeLanguage(lng);
    setLang(lng);
  }, [changeLanguage, search]);

  const renderSection = (sections, mainSectionIndex) => {
    const jsx = (
      <>
        {sections.map((sectionItem, index) => {
          return Object.keys(sectionItem).map((sectionName) => {
            if (sectionName === 'contents') {
              const translatedContents = sectionItem[sectionName][lang];
              return translatedContents.map(
                (translatedContent, translatedContentIndex) => {
                  if (typeof translatedContent === 'string') {
                    return (
                      <Typography
                        key={`contents_${mainSectionIndex.toString()}-${index.toString()}-${translatedContentIndex.toString()}`}
                        className={classes.content}
                        tabIndex="0"
                      >
                        {translate('content', linkObject, {
                          content: { [lang]: translatedContent },
                        })}
                      </Typography>
                    );
                  }

                  if (typeof translatedContent === 'object') {
                    if (translatedContent.list) {
                      return (
                        <ul
                          key={`list_${mainSectionIndex.toString()}-${index.toString()}-${translatedContentIndex.toString()}`}
                          className={classes.listNoSpace}
                        >
                          {translatedContent.list.map((li, liIndex) => {
                            return (
                              <li
                                key={`list_item_${mainSectionIndex.toString()}-${index.toString()}-${translatedContentIndex.toString()}-${liIndex.toString()}`}
                              >
                                <Typography
                                  className={classes.list}
                                  key={`list_item_p_${mainSectionIndex.toString()}-${index.toString()}-${translatedContentIndex.toString()}-${liIndex.toString()}`}
                                  tabIndex="0"
                                >
                                  {translate('li', linkObject, {
                                    li: { [lang]: li },
                                  })}
                                </Typography>
                              </li>
                            );
                          })}
                        </ul>
                      );
                    }
                  }
                },
              );
            }

            if (sectionName === 'title') {
              return (
                <Typography
                  key={`title_${mainSectionIndex.toString()}-${index.toString()}`}
                  variant="h6"
                  component="h1"
                  className={[classes.title].join(' ')}
                  tabIndex="0"
                >
                  {translate('title', null, sectionItem[sectionName])}
                </Typography>
              );
            }

            return null;
          });
        })}
      </>
    );

    return jsx;
  };

  const renderPrivacyPolicy = (mainSection, mainSectionIndex) => {
    const jsx = Object.keys(mainSection).map((mainSectionObjName) => {
      if (mainSectionObjName === 'title') {
        return (
          <Typography
            variant="h6"
            component="h1"
            className={[
              classes.title,
              mainSectionIndex === 0 ? classes.centered : null,
            ].join(' ')}
            key={`mainSectionTitle_${mainSectionIndex.toString()}`}
            tabIndex="0"
          >
            {translate(
              mainSectionObjName,
              null,
              mainSection[mainSectionObjName],
            )}
          </Typography>
        );
      }

      if (mainSectionObjName === 'section') {
        return renderSection(mainSection[mainSectionObjName], mainSectionIndex);
      }
    });

    return jsx;
  };

  return (
    <div className={classes.root} role="alert" aria-live="polite">
      <Box classes={{ root: classes.container }}>
        {privacyPolicySections.map((mainSection, index) =>
          renderPrivacyPolicy(mainSection, index),
        )}
      </Box>
    </div>
  );
};

PrivateSection.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  privacyPolicy: PropTypes.object,
};

PrivateSection.defaultProps = {
  privacyPolicy: {},
};

export default PrivateSection;
