import * as React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography, CircularProgress } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
    alignItems: 'center',
  },
  progress: {
    color: theme.palette.primary.main,
  },
}));

const Loading = ({ text }) => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div style={{ marginBottom: theme.spacing(2) }}>
        <CircularProgress classes={{ root: classes.progress }} />
      </div>
      {text && (
        <Typography role="alert" aria-live="polite">
          {text}
        </Typography>
      )}
    </div>
  );
};

Loading.propTypes = {
  text: PropTypes.string,
};

Loading.defaultProps = {
  text: undefined,
};

export default Loading;
