/* eslint-disable no-console */
import create from 'zustand';
import { devtools } from 'zustand/middleware';

const initialFormState = {
  mbRegistrationNumber: {
    value: '',
  },
  phin: {
    value: '',
  },
  otherCompletedBy: {
    conditionalField: true,
    disabled: true,
    validation: {},
    requiredMsg: 'pages.consentForm.completedBy.otherCompletedByEmpty',
  },
  questionFeverOrSymptoms: {
    conditionalField: true,
    disabled: true,
    validation: {},
    requiredMsg:
      'pages.consentForm.sectionB.questionFeverOrSymptomsCommentEmptySelection',
  },
  questionAllergy: {
    conditionalField: true,
    disabled: true,
    validation: {},
    requiredMsg:
      'pages.consentForm.sectionB.questionAllergyCommentEmptySelection',
  },
  questionVaccineReaction: {
    conditionalField: true,
    disabled: true,
    validation: {},
    requiredMsg:
      'pages.consentForm.sectionB.questionVaccineReactionCommentEmptySelection',
  },
  questionMedicalConditions: {
    conditionalField: true,
    disabled: true,
    validation: {},
    requiredMsg:
      'pages.consentForm.sectionB.questionMedicalConditionsCommentEmptySelection',
  },
  questionBloodClottingMedication: {
    conditionalField: true,
    disabled: true,
    validation: {},
    requiredMsg:
      'pages.consentForm.sectionB.questionBloodClottingMedicationCommentEmptySelection',
  },
  otherRacialEthnicity: {
    conditionalField: true,
    disabled: true,
    validation: {},
    requiredMsg: 'pages.request.information.errors.emptyWithoutLabel',
  },
  northAmericanIndigenousIdentity: {
    conditionalField: true,
    disabled: true,
    validation: {},
    requiredMsg: 'pages.request.information.errors.emptySelection',
  },
};

const useConsentFormStore = create(
  devtools((set) => ({
    formState: initialFormState,
    focusEl: '',
    updateForm: (name, data) =>
      set((state) => ({
        formState: {
          ...state.formState,
          [name]: { ...state.formState[name], ...data },
        },
      })),
    updateWholeForm: (data) =>
      set((state) => ({
        formState: {
          ...state.formState,
          ...data,
        },
      })),
    clearForm: () => set(() => ({ formState: initialFormState })),
    setFocusEl: (value) => {
      set(() => ({
        focusEl: value,
      }));
    },
    schoolsApiHasError: false,
    setSchoolsHasError: (value) =>
      set(() => ({
        schoolsApiHasError: value,
      })),
    loadingSchoolsApi: undefined,
    setLoadingSchoolsApi: (value) =>
      set(() => ({
        loadingSchoolsApi: value,
      })),
  })),
);

export default useConsentFormStore;
