import { formatDate } from 'utility/common';
import { trim, toLower, toUpper, toInteger } from 'lodash';
import PROVINCE, { CONSENT_PROVIDER, FORM_MAKER } from './constant';

const checkAndConvertBooleanValues = (value) => {
  let finalValue = value;
  if (value === 'true') {
    finalValue = true;
  } else if (value === 'false') {
    finalValue = false;
  }
  return finalValue;
};

const getFormMaker = (maker) => {
  switch (maker) {
    case 'client':
      return FORM_MAKER.CLIENT;

    case 'parent':
      return FORM_MAKER.PARENT;

    case 'legalDecisionMaker':
      return FORM_MAKER.LEGAL_DECISION_MAKER;

    case 'other':
      return FORM_MAKER.OTHER;
    default:
      break;
  }
  return '';
};

const isNotNullAndUndefined = (value) => {
  let result = true;

  if (value === null || !value) {
    result = false;
  }

  if (value && trim(value) === '') {
    result = false;
  }

  return result;
};

const mapNewObject = (consentForm) => {
  const result = {
    consent: {
      consentProvidedBy:
        toUpper(consentForm.completedBy) === 'CLIENT'
          ? CONSENT_PROVIDER.CLIENT
          : CONSENT_PROVIDER.LEGAL_DECISION_MAKER,
      consentDecision: 'GIVEN',
      decisionMakerFirstName: consentForm.informedConsentFirstName,
      decisionMakerLastName: consentForm.informedConsentLastName,
      decisionMakerRelationship: toUpper(
        consentForm.informedConsentRelationship,
      ),
      decisionMakerPhoneNumber: consentForm.informedConsentPhoneNumber,
      decisionMakerEmail: consentForm.informedConsentEmail,
      hasUnderstandingAndQuestionsAnswered:
        consentForm.hasUnderstandingAndQuestionsAnswered,
      legalConsent: consentForm.legalConsent,
      isDeclarationCompleteAndTrue: consentForm.isDeclarationCompleteAndTrue,
      thirdPartyDisclosure: consentForm.thirdPartyDisclosure,
    },
    client: {
      firstName: consentForm.givenName,
      lastName: consentForm.surname,
      phoneNumber: consentForm.phoneNumber,
      schoolGrade: consentForm.grade,
      schoolId: consentForm.schoolId,
      email: toLower(consentForm.email),
      sex: toUpper(consentForm.sex),
      dateOfBirth: formatDate(consentForm.dateOfBirth, 'YYYY-MM-DD'),
      ...(isNotNullAndUndefined(consentForm.mbRegistrationNumber)
        ? { mbHealthNumber: consentForm.mbRegistrationNumber }
        : null),
      ...(isNotNullAndUndefined(consentForm.phin)
        ? { phin: consentForm.phin }
        : null),
      identity: {
        identityCode: toUpper(consentForm.racialEthnicity),
        ...(isNotNullAndUndefined(consentForm.otherRacialEthnicity)
          ? { identityOther: consentForm.otherRacialEthnicity }
          : null),
        ...(isNotNullAndUndefined(consentForm.northAmericanIndigenousIdentity)
          ? {
              indigenousIdentity: toUpper(
                consentForm.northAmericanIndigenousIdentity,
              ),
            }
          : null),
      },
      address: {
        street: consentForm.address,
        city: consentForm.city,
        province: PROVINCE.MB,
        postalCode: consentForm.postalCode,
      },
    },
    questions: {
      hasSymptoms: checkAndConvertBooleanValues(
        consentForm.hasQuestionFeverOrSymptoms,
      ),
      ...(isNotNullAndUndefined(consentForm.questionFeverOrSymptoms)
        ? { hasSymptomsComment: consentForm.questionFeverOrSymptoms }
        : null),
      hasAllergies: checkAndConvertBooleanValues(
        consentForm.hasQuestionAllergy,
      ),
      ...(isNotNullAndUndefined(consentForm.questionAllergy)
        ? { hasAllergiesComment: consentForm.questionAllergy }
        : null),
      hasPegAllergy: checkAndConvertBooleanValues(
        consentForm.hasQuestionPEGAllergy,
      ),
      hadReactionFollowingVaccine: checkAndConvertBooleanValues(
        consentForm.hasQuestionVaccineReaction,
      ),
      ...(isNotNullAndUndefined(consentForm.questionVaccineReaction)
        ? {
            hadReactionFollowingVaccineComment:
              consentForm.questionVaccineReaction,
          }
        : null),
      hasMedicalCondition: checkAndConvertBooleanValues(
        consentForm.hasQuestionMedicalConditions,
      ),
      ...(isNotNullAndUndefined(consentForm.questionMedicalConditions)
        ? { hasMedicalConditionComment: consentForm.questionMedicalConditions }
        : null),
      vaccineInLast14Days: checkAndConvertBooleanValues(
        consentForm.hasQuestionReceivedVaccineLast14Days,
      ),
      takingBloodClottingMeds: checkAndConvertBooleanValues(
        consentForm.hasQuestionBloodClottingMedication,
      ),
      ...(isNotNullAndUndefined(consentForm.questionBloodClottingMedication)
        ? {
            takingBloodClottingMedsComment:
              consentForm.questionBloodClottingMedication,
          }
        : null),
      pregnantOrBreastfeeding: checkAndConvertBooleanValues(
        consentForm.hasQuestionPregnant,
      ),
      immuneSystemSuppressed: checkAndConvertBooleanValues(
        consentForm.hasQuestionImmuneSystemSuppressed,
      ),
      autoimmuneCondition: checkAndConvertBooleanValues(
        consentForm.hasQuestionAutoimmune,
      ),
      historyOfBrainThrombosisOrThromboytopenia: checkAndConvertBooleanValues(
        consentForm.hasQuestionVSTOrHIT,
      ),
      haveReceivedDoseCount: toInteger(consentForm.haveReceivedDoseCount),
    },
    formCompletedBy: getFormMaker(consentForm.completedBy),
    formLanguage: consentForm.formLanguage,
    ...(isNotNullAndUndefined(consentForm.otherCompletedBy)
      ? {
          formCompletedByOther: consentForm.otherCompletedBy,
        }
      : null),
  };
  return result;
};

const mapConsentForm = (consentForm) => {
  // don't change the value of the orginal object.
  // In section B, radioGroup uses string as its value to determine which
  // radio button should be selected. If we convert the hasQuestionXXX to bool,
  // then after submit, those fields whose value is false will become empty.
  const consentFormRequest = mapNewObject({ ...consentForm });
  // Object.keys(consentForm).forEach((propName) => {
  //   consentFormRequest[propName] = checkAndConvertBooleanValues(
  //     consentForm[propName],
  //   );
  // });
  return consentFormRequest;
};

export { mapConsentForm, getFormMaker };
