import React from 'react';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PollOutlined from '@material-ui/icons/PollOutlined';
import RouterRole from 'constants/routerRole';
import { ReactComponent as PrivacyTip } from '../../img/privacy_tip.svg';

const pagesUrl = new Map();

const consentForm = {
  url: '/consent-form',
  showInNavBar: false,
  showName: 'layout.nav.newConsentForm',
  group: 1,
  name: 'covidConsentForm',
  icon: <ListAltIcon />,
  role: RouterRole.PROTECTED,
};

const consentList = {
  url: '/consent-list',
  showInNavBar: false,
  showName: 'layout.nav.consentList',
  group: 1,
  name: 'covidConsentList',
  icon: <ListAltIcon />,
  role: RouterRole.PROTECTED,
};

pagesUrl.set('covidConsentForm', consentForm);
pagesUrl.set('covidConsentList', consentList);

pagesUrl.set('ConsentFormMenu', {
  showInNavBar: true,
  showName: 'layout.nav.consentMenuTitle',
  group: 1,
  icon: <ListAltIcon />,
  subMenu: [consentForm, consentList],
  name: 'covid',
  role: RouterRole.PROTECTED,
});

// it's privat,only used to redirect to faq when the user clicks this button.
// not included in the router

// it's public and will be accessed by unlogin user,will redirect to faq
pagesUrl.set('covidFaq', {
  url: '/faq',
  showInNavBar: true,
  showName: 'layout.nav.help',
  group: 2,
  icon: <HelpOutlineIcon />,
  newPage: true,
  name: 'covidFaq',
  role: RouterRole.PROTECTED,
});

pagesUrl.set('covidFaqPublic', {
  url: '/faq-public',
  showInNavBar: true,
  showName: 'layout.nav.help',
  group: 2,
  icon: <HelpOutlineIcon />,
  newPage: true,
  name: 'covidFaqPublic',
  role: RouterRole.PUBLIC,
});

pagesUrl.set('PrivacyNotice', {
  url: '/privacy-notice',
  showInNavBar: true,
  newPage: true,
  showName: 'layout.nav.privacyNotice',
  group: 3,
  icon: <PrivacyTip aria-hidden="true" fill="#A5A5A5" />,
  role: RouterRole.PROTECTED,
});

pagesUrl.set('survey', {
  url: '/survey',
  showInNavBar: true,
  newPage: true,
  showName: 'layout.nav.survey',
  group: 3,
  icon: <PollOutlined aria-hidden="true" fill="#A5A5A5" />,
  role: RouterRole.PROTECTED,
});

pagesUrl.set('logout', {
  url: '/logout',
  showInNavBar: true,
  showName: 'layout.nav.logout',
  group: 4,
  icon: <ExitToAppIcon />,
  name: 'logout',
  role: RouterRole.PROTECTED,
});

export function getGroup(groupId) {
  const result = [];
  pagesUrl.forEach((value) => {
    if (value.group === groupId && value.showInNavBar) {
      result.push(value);
    }
  });

  return result;
}
export default pagesUrl;
