/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import FormInput from './FormInput';

const FormSection = ({
  formState = {},
  focusEl,
  inputs,
  marginTop,
  onChange,
  onBlur,
}) => {
  const inputCollection = Object.keys(inputs).map((inputName) => {
    const input = inputs[inputName];
    return (
      <FormInput
        key={inputName}
        input={input}
        formState={formState}
        onChange={onChange}
        onBlur={onBlur}
        focusEl={focusEl}
      />
    );
  });

  return <div style={{ marginTop }}>{inputCollection}</div>;
};

FormSection.defaultProps = {
  onChange: () => {},
  onBlur: () => {},
  formState: null,
  marginTop: undefined,
  focusEl: undefined,
};

FormSection.propTypes = {
  inputs: PropTypes.object.isRequired,
  formState: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  marginTop: PropTypes.string,
  focusEl: PropTypes.string,
};

export default FormSection;
