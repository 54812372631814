import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import Hidden from '@material-ui/core/Hidden';
import { includes } from 'lodash';
import NavigationBar from '../navigation-bar/NavigationBar';
import DetailNavigationBar from '../navigation-bar/DetailNavigationBar';
import FooterContainer from '../footer/PageFooter';
import NavigationDrawer from '../navigation-bar/NavigationDrawer';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 'auto',
    marginRight: 'auto',
    minHeight: '100%',
    width: '100%',
    maxWidth: (props) => props.maxWidth,
    padding: '0px',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2),
    },
    // change some styles when nav bar is shown
    // [theme.breakpoints.down(theme.navBarBreakpoint.hideDrawerName)]: {
    //   maxWidth: '100%',
    //   paddingBottom: theme.spacing(2),
    // },
  },

  // Moves scrollbar to edge of screen, for aesthetics and so tablets
  // users can use the background/margin to scroll content
  scroll: {
    flexGrow: 1,
    overflow: 'auto',
  },
  drawerAndContentContainer: {
    display: 'flex',
    overflow: 'hidden',
    justifyContent: 'center',
    height: '100%',
  },
  // Safari won't always take 100% and we can't have dupe keys in the same class
  safariDrawerAndContentContainer: {
    height: 'fill-available',
  },
}));

const PageLayout = (props) => {
  const {
    children,
    showNavigation,
    // eslint-disable-next-line no-unused-vars
    showDrawer,
    alwaysShowLanguageToggle,
    detailHeader,
    backButtonClicked,
    width,
    forceShowFooter,
    forceShowAppBar,
    maxWidth,
  } = props;
  const classes = useStyles({ maxWidth });
  const theme = useTheme();
  const showFooter = !forceShowFooter
    ? includes(['xs', 'sm', 'md'], width)
    : true;
  return (
    <div className={classes.root}>
      {showNavigation && (
        <NavigationBar
          showAddButton={showDrawer}
          showIconButton={showDrawer}
          alwaysShowLanguageToggle={alwaysShowLanguageToggle}
          forceShow={forceShowAppBar}
        />
      )}
      {!showNavigation && (
        <DetailNavigationBar
          backButtonClicked={backButtonClicked}
          detailHeader={detailHeader}
        />
      )}
      <div
        className={[
          classes.drawerAndContentContainer,
          classes.safariDrawerAndContentContainer,
        ].join(' ')}
      >
        <Hidden {...theme.navBarBreakpoint.hideDrawer}>
          {showDrawer && (
            <NavigationDrawer
              isOpen
              showCloseButton={false}
              variant="permanent"
            />
          )}
        </Hidden>
        <div className={classes.scroll}>
          <Container
            disableGutters
            className={classes.container}
            id="root-container"
          >
            {children}
          </Container>
        </div>
      </div>

      {showFooter && <FooterContainer />}
    </div>
  );
};

PageLayout.defaultProps = {
  children: null,
  showNavigation: true,
  showDrawer: true,
  alwaysShowLanguageToggle: false,
  detailHeader: '',
  backButtonClicked: null,
  width: null,
  forceShowFooter: false,
  forceShowAppBar: false,
  maxWidth: '100%',
};

PageLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
  showNavigation: PropTypes.bool,
  showDrawer: PropTypes.bool,
  alwaysShowLanguageToggle: PropTypes.bool,
  detailHeader: PropTypes.string,
  backButtonClicked: PropTypes.func,
  width: PropTypes.string,
  forceShowFooter: PropTypes.bool,
  forceShowAppBar: PropTypes.bool,
  maxWidth: PropTypes.string,
};

export default withWidth()(PageLayout);
